import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild
} from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import disableScroll from 'disable-scroll';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthService } from '../../../core/auth/auth.service';
import { VideoShareService } from '../../../core/services/video-share.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user.service';
import { BrowserService } from '../../../core/services/browser.service';

declare var $: any;
@Component({
  selector: 'app-kids-detail',
  templateUrl: './kids-detail.component.html',
  styleUrls: ['./kids-detail.component.scss']
})
export class KidsDetailComponent implements OnInit, OnDestroy, AfterContentInit {
  trending: any = [];
  new: any = [];
  webseries: any = [];
  banner: any = [];
  kidsbanner: any = [];
  section: any = [];
  genre: any = [];
  home: any = [];
  routeSub: any;
  load: Boolean;
  defaultOwlCarousel: any = [];
  scrollEvent = true;
  viewPortEnable: Boolean = false;
  moved: any = 7;
  page = 1;
  newNextPage: Boolean = true;
  trendingNextPage: Boolean = true;
  previousPage: Boolean = false;
  sliderBox: any;
  nextMove: any;
  activeItem: any;
  ItemNumber = 7;
  trendingPage = 1;
  loadingSlider: any = [];
  sectionPage: any = [];
  sectionNextPage: any = [];
  categoriesVideos: any = [];
  search: any = false;
  homeSection: Boolean = true;
  site_link: any = [];
  getKidsCategory;
  getRecommendedVideos;
  currentDecryptUser: any;
  sliderMovedData: any;
  sliderRowItem: any;
  error: any = {};
  webseriesNextPage: Boolean = true;
  webseriesPage = 1;
  seeallclick: any;
  kidsBannerLoader: Boolean = true;


  @HostListener('window:scroll', ['$event'])
  onscroll() {
    if (this.homeSection === false) {
      this.homeSection = true;
      if (!this.getKidsCategory) {
        this.getHome();
      }
    }
  }
  constructor(
    private apiService: ApiService,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthService,
    private videoShareService: VideoShareService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private userService: UserService,
    private browserService: BrowserService
  ) {
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // this.router.navigated = false;
      }
    });
  }
  ngOnInit() {
    this.browserService.scrollToTop();
    this.route.params.subscribe((params) => {
      if (params && params['token']) {
        this.activateUser(params['token']);
      }
    });
    this.currentDecryptUser = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    // this.getRecommendedVideosMethod();
    this.getKidsCategory = this.headerService.getKidsCategory();
    this.genre.data = [];
    this.new.data = [];
    this.webseries.data = [];
    this.categoriesVideos = [];
    this.sectionPage = [];
    this.sectionNextPage = [];
    this.trending.data = [];
    this.loadingSlider.data = [];
    for (let i = 0; i <= 6; i++) {
      this.loadingSlider.data.push({
        thumbnail_image: environment.defaultImage
      });
    }
    this.getSiteLinks();

    // this.headerService.kidsbannerData$.subscribe((data) => {
    //   console.log(data);
    //   this.kidsbanner = data;
    //   if (this.kidsbanner === null) {
    //     this.headerService.fetchKidsBanner();
    //   }
    // });
    // this.headerService.kidsNewData$.subscribe((data) => {
    //   this.new = data;
    //   if (this.new === null) {
    //     this.headerService.fetchBanner();
    //   }
    // });
    // this.headerService.kidswebseriesData$.subscribe((data) => {
    //   this.webseries = data;
    //   // if (this.webseries === null) {
    //   //   this.headerService.fetchBanner();
    //   // }
    // });
    this.homeSection = false;
    if (this.getKidsCategory) {
      this.getKidsAfterData(this.getKidsCategory);
    }
    this.fetchKidsData();
  }
  ngAfterContentInit() {
    this.videoShareService.sliderMovedItems.subscribe((data) => {
      this.sliderMovedData = data;
    });
    this.videoShareService.sliderRowItemsCount.subscribe((data) => {
      this.sliderRowItem = data;
    });
  }

  fetchKidsData() {
    this.apiService.callGetAPI('v2/kids_page_banner').subscribe(
      (data) => {
        // console.log(data);
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.kidsbanner = data['response'].kidsbanner;
          this.new = data['response'].kidsnew;
          this.webseries = data['response'].kids_webseries;
          this.kidsBannerLoader = false;
        }
      },
      (error) => {
        this.kidsBannerLoader = false;
        this.apiService.failureResponse(error, true);
      }
    );
  }

  getHome() {
    this.load = false;
    this.apiService.callGetAPI('v2/kids_page').subscribe(
      (data) => {
        this.headerService.setKidsCategory(data);
        this.getKidsAfterData(data);
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }

  getKidsAfterData(data) {
    if (data['statusCode'] === 200 && data['error'] === false) {
      let index = 0;
      for (const datas of data['response'].home_content) {
        this.categoriesVideos.push(datas);
        this.sectionPage.push(1);
        if (datas.total === this.categoriesVideos[index].data.length) {
          this.sectionNextPage[index] = false;
        } else {
          this.sectionNextPage[index] = true;
        }
        index++;
      }
      this.load = true;
    }
  }

  next(url) {
    if (url === 'kidsnew') {
      this.nextObj();
    } else if (url === 'kids_webseries') {
      this.nextWebseries();
    } else if (url === 'trending') {
      this.nextObjTrending();
    } 
  }
  nextObj() {
    this.page++;
    if (!(this.new.data.length === this.new.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=kidsnew&page=' + this.page)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.new.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.new.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  nextWebseries() {
    this.webseriesPage++;
    if (!(this.webseries.data.length === this.webseries.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=kids_webseries&page=' + this.webseriesPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.webseries.data.length) {
                this.webseriesNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.webseries.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  nextObjTrending() {
    this.trendingPage++;
    if (this.trendingNextPage) {
      this.apiService
        .callGetAPI('v2/home_more?type=trending&page=' + this.trendingPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.trending.data.length) {
                this.trendingNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.trending.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }
  sectionNext(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_more?type=' + section + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.categoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.categoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }

  getSiteLinks() {
    this.headerService.apiData$.subscribe((data) => {
      if (data !== null) {
        this.site_link = data['response'].site_link;
      }
    });
    if (this.site_link === null) {
      setTimeout(() => {
        this.getSiteLinks();
      }, 1000);
    }
  }


  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link
        ? this.site_link.android_app_link
        : undefined;
      a.click();
    }
  }
  activateUser(key) {
    this.apiService
      .callPostAPI(
        'v2/auth/check_activation_code',
        {
          login_type: 'normal',
          acesstype: 'web',
          key: key
        },
        environment.userApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.userService.displayPopup('sign-in');
            this.toasterService.success('', data['message']);
            disableScroll.off();
            this.router.navigate(['/home']);
          }
        },
        (error) => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error('', this.error);
            this.router.navigate(['/home']);
          } else {
            this.error = {};
            this.toasterService.error(
              '',
              error.error.message ? error.error.message : error.message
            );
            this.router.navigate(['/home']);
          }
        }
      );
  }

  seeAll(type, slug) {
    window.scrollTo(0, 0);

    // this.seeallclick = setInterval(() => {
    this.router.navigate(['/seeall/'+ type + '/' + slug]);
  // }, 10);

  }

  seeAllPopular(popular) {
    window.scrollTo(0, 0);

    // this.seeallclick = setInterval(() => {
    this.router.navigate(['/seeall/kidspopular/' + popular.category_slug]);
    // }, 10);
  }

  ngOnDestroy() {
    disableScroll.off();
    this.routeSub.unsubscribe();
    if (this.seeallclick) {
      clearInterval(this.seeallclick);
    }
  }

}
