import { Component, OnInit, AfterViewInit, AfterContentInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/auth/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { TranslateService } from '@ngx-translate/core';
import { RadioPlayComponent } from '../../../modules/radio/radio-play/radio-play.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit, AfterContentInit {

  date = new Date();
  year = this.date.getFullYear();
  categories: any = [];
  userValues: string;
  hideFooter: string;
  is_subscribed: any;
  contact: any;
  cmsLink: any = [];
  routeSub: any;
  footerBanner: any;
  isShow: Boolean = false;



  site_link: any = [];
  language: any;
  footerFlag: any = false;
  @ViewChild(RadioPlayComponent) radioRef:  RadioPlayComponent;

  
  constructor(private apiService: ApiService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private headerService: HeaderService) {
  }

  ngOnInit() {
    if (localStorage.getItem('currentLanguage')) {
      this.language = localStorage.getItem('currentLanguage');
    } else {
      this.language = 'en';
    }
    // const footerHeight =  window.getComputedStyle(document.getElementById('footer'));
    // const fheight = footerHeight.getPropertyValue('height');
    // document.body.style.paddingBottom = fheight;
  }
  ngAfterViewInit() {
    this.getCategories();
    if (window.location.pathname === '/landingpage' || this.router.url.includes('/reset-password/') || this.router.url.includes('/act/') || this.router.url.includes('/reset-parental/') || this.router.url.includes('/radio/') || this.router.url.includes('pages/') || this.router.url.includes('/live-watch/') ) {
      const footer = document.querySelector('.footer');
      footer.classList.add('landingpage-footer');
    } else {
      const footer = document.querySelector('.footer');
      footer.classList.remove('landingpage-footer');
    }
    this.routeSub = this.router.events.subscribe((evt) => {
      if (window.location.pathname === '/landingpage' || this.router.url.includes('/reset-password/') || this.router.url.includes('/act/') || this.router.url.includes('/reset-parental/') || this.router.url.includes('/radio/') || this.router.url.includes('/live-watch/') || this.router.url.includes('pages/') ) {
        const footer = document.querySelector('.footer');
        footer.classList.add('landingpage-footer');
      } else {
        const footer = document.querySelector('.footer');
        footer.classList.remove('landingpage-footer');
      }
    });

    // console.log(this.radioRef);

  }
  ngAfterContentInit(): void {
    this.footerFlag = true;
    this.getBanner();
  }
  getCategories() {
    this.headerService.apiData$.subscribe(data => {
      if (data !== null) {
        this.categories = data['category'];
        this.contact = data['response'].address;
        this.cmsLink = data['response'].static_contents;
        this.site_link = data['response'].site_link;
      }
    });
    if (this.categories === null) {
      setTimeout(() => {
        this.getCategories();
      }, 1000);
    }
  }
  /**
   * Category Navigation
   */
  categoryNavigation(cat) {
    // Navigation based on the web series
    if (cat.is_web_series) {
      this.router.navigate(['series/' + cat.slug]);
    } else {
      this.router.navigate(['category/' + cat.slug]);
    }
  }

  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link ? this.site_link.android_app_link : undefined;
      a.click();
    }
  }

  getBanner() {
    this.apiService.callGetAPI('v2/footerbanner').subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          if(data && data['response'].length !== 0) {
            this.isShow = true;
            this.footerBanner = data && data['response'][0].kids_url;
          }
        }
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }
  hideFooterLink() {
    localStorage.setItem('ishidefooter', '1');
  }
}
