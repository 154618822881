import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
import {LandingpageComponent} from './modules/landingpage/landingpage.component';
import { AuthGuard } from './core/guards/auth.guard';
import {KidsDetailComponent} from './modules/kids/kids-detail/kids-detail.component';
import {RadioDetailComponent} from './modules/radio/radio-detail/radio-detail.component';
import {RadioPlayComponent} from './modules/radio/radio-play/radio-play.component';
import { PrivacyComponent } from './modules/landingpage/pages/privacy/privacy.component';
import { HelpComponent } from './modules/landingpage/pages/help/help.component';
import { FeedbackComponent } from './modules/landingpage/pages/feedback/feedback.component';
import { NoticeComponent } from './modules/landingpage/pages/notice/notice.component';
import {TermsofuseComponent} from './modules/landingpage/pages/termsofuse/termsofuse.component';
import {SeeallComponent} from './modules/seeall/seeall/seeall.component';
import {SeeallgenreComponent} from './modules/seeall/seeallgenre/seeallgenre.component';
import { LiveeventComponent } from './modules/liveevent/liveevent.component';
import { WebseriesvideosComponent } from './modules/webseries/webseriesvideos/webseriesvideos.component';
import { LiveplayerComponent } from './modules/liveplayer/liveplayer/liveplayer.component';


import { from } from 'rxjs';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]

  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'landingpage',
    component: LandingpageComponent
  },
  // {
  //   path: 'pages/terms-and-conditions',
  //   component: TermsofuseComponent
  // },
  // {
  //   path: 'pages/privacy-policy',
  //   component: PrivacyComponent
  // },
  // {
  //   path: 'pages/help',
  //   component: HelpComponent
  // },
  {
    path: 'pages/feedback',
    component: FeedbackComponent
  },
  // {
  //   path: 'pages/notice',
  //   component: NoticeComponent
  // },
  {
    path: 'kids',
    component: KidsDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'radio',
    component: RadioDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'liveevents',
    component: LiveeventComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'radio/:slug',
    component: RadioPlayComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'web-series/:slug',
    component: WebseriesvideosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'live-watch/:slug',
    component: LiveplayerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reset-parental/:id',
    loadChildren: './modules/profile/reset-parental/reset-parental.module#ResetParentalModule'
  },
  {
    path: 'act/:token',
    component: LandingpageComponent
  },
  {
    path: 'watch/:video_id',
    component: WatchVideoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'embed/:video_id',
    component: EmbedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'watch/:video_id/:playlist_id/:name',
    component: WatchVideoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'series/:slug',
    component: WebseriesDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: './modules/profile/profile.module#ProfileModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'playlist',
    loadChildren: './modules/playlist/playlist.module#PlaylistModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'category',
    loadChildren: './modules/category/category.module#CategoryModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'seeall/:type/:slug',
    component: SeeallComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'seeall/:parent/:type/:slug',
    component: SeeallgenreComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'genre',
    loadChildren: '../app/modules/genre/genre.module#GenreModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'video/:slug',
    loadChildren: './modules/video-detail/video-detail.module#VideoDetailModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'webseries/:seriesslug',
    loadChildren: './modules/series-detail/series-detail.module#SeriesDetailModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'video/:slug/:playlist',
    loadChildren: './modules/video-detail/video-detail.module#VideoDetailModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'content/:slug',
    loadChildren: './modules/cms/cms.module#CmsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'pages/:slug',
    loadChildren: './modules/cms/cms.module#CmsModule',
    // canActivate: [AuthGuard]
  },
  {
    path: 'contact-us',
    loadChildren: './modules/contact-us/contact-us.module#ContactUsModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'new-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'trending-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'reset-password/:id',
    loadChildren: './modules/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'save-playlist',
    loadChildren: './modules/profile/save-playlist/save-playlist.module#SavePlaylistModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'subscription',
    loadChildren: './modules/subscription/subscription.module#SubscriptionModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'live',
    loadChildren: './modules/live-vidoes/live-vidoes.module#LiveVidoesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'card-payment/:plan',
    loadChildren: './modules/card-payment/card-payment.module#CardPaymentModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'video-not-found',
    loadChildren: './modules/video-not-found/video-not-found.module#VideoNotFoundModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'checkout/:slug',
    loadChildren: './modules/checkout/checkout.module#CheckoutModule',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadChildren: './modules/page-not-found/page-not-found.module#PageNotFoundModule',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
