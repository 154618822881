import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { VideoShareService } from '../../../core/services/video-share.service';
import { PlaylistAddService } from '../../../core/services/playlist-add.service';
import disableScroll from 'disable-scroll';
import { VideoModel } from '../../../modules/video-detail/video.model';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-seeallgenre',
  templateUrl: './seeallgenre.component.html',
  styleUrls: ['./seeallgenre.component.scss']
})
export class SeeallgenreComponent implements OnInit, OnDestroy {
  tostar: any = {};
  loggedIn: any = {};
  allVideos = [];
  noRadio = false;
  page = 0;
  livecategorypage = 0;
  livallfavouritepage = 0;
  newandpopularpage = 1;
  livepage = false;
  radiopage = false;
  specialCategoryPage = false;
  loading = false;
  preloading = false;
  final = false;
  flag = false;
  notFound = false;
  noVideo = 'Sorry! No Video are available';
  currentSlug: any = "";
  currentType: any = "";
  currentParent: any = "";
  isWebseries = 0;
  currentTitle:any = "";
  isDefaultLoader = 0;
  seeallclick: any;


  constructor(private apiService: ApiService, private authService: AuthService,  private router: Router, private videoShareService: VideoShareService, private toasterService: ToastrService, private playlistAddService: PlaylistAddService, private route: ActivatedRoute) { 
    window.scrollTo(0, 0);
    this.seeallclick = setInterval(() => {
      this.isDefaultLoader = 1;
    }, 10);
      this.route.params.subscribe((params) => {
        this.currentType = params.type;
        this.currentSlug = params.slug;
        this.currentParent = params.parent;
      });
   }

  ngOnInit() {
      if (this.currentType === 'category' || this.currentType === 'genre') {
        this.categoryVideos();
      }
      if (this.currentType === 'livecategory' || this.currentType === 'livefavourite') {
        this.liveCategoryVideos();
      }
      if (this.currentType === 'specialcategory') {
        this.specialCategoryVideos();
      }
      if (this.currentType === 'radiocategory' || this.currentType === 'radiofavourite') {
        this.radioCategoryVideos();
      }
      if (this.currentType === 'alllivefavourite') {
        this.allLiveFavouriteVideos();
      }
      if (this.currentType === 'allradiofavourite') {
        this.allRadioFavouriteVideos();
      }
      if (this.currentType === 'all' && this.currentParent === 'webseries') {
        this.allWebseries();
      }
      if (this.currentType === 'webseries_genre') {
        this.webseriesGenre();
      }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
      if ($(window).scrollTop() + $(window).height() > $('footer').offset().top && this.final === false && this.flag === false && this.isDefaultLoader === 1) {
        this.loading = true;
        this.flag = true;
        // setInterval(() => {
          if (this.currentType === 'category') {
            this.categoryMoreVideo();
          }
  
          if (this.currentType === 'livecategory' || this.currentType === 'livefavourite') {
              this.liveCategoryVideos();
          }

          if (this.currentType === 'specialcategory') {
            this.specialCategoryVideos();
          }

          if (this.currentType === 'radiocategory' || this.currentType === 'radiofavourite') {
            this.radioCategoryVideos();
          }

          if (this.currentType === 'alllivefavourite') {
            this.allLiveFavouriteVideos();
          }

          if (this.currentType === 'allradiofavourite') {
            this.allRadioFavouriteVideos();
          }
  
          if (this.currentType === 'genre') {
            this.genreMoreVideo();
          }
          if (this.currentType === 'all' && this.currentParent === 'webseries') {
            this.allWebseries();
          }
          if (this.currentType === 'webseries_genre') {
            this.moreChildWebseries();
          }
        // }, 5000);

      }

  }

  

  categoryVideos() {
    this.preloading = true;
    this.apiService.callGetAPI('v2/home_category_videos?section=2&is_web_series=' +
    0 +
    '&category=' +
    this.currentParent, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          var i;
          if(this.currentType === 'category'){
            for (i = 0; i < data['response'].category_videos.length; i++) {
              if (data['response'].category_videos[i].slug === this.currentSlug) {
                this.allVideos = this.allVideos.concat(data['response'].category_videos[i].video_list.data);
                this.currentTitle = data['response'].category_videos[i].title;
                this.final = (data['response'].category_videos[i].video_list.next_page_url === null) ? true : false;
                this.loading = false;
                this.preloading = false;
                this.flag = false;
                this.notFound = (this.allVideos.length > 0) ? false : true;
              }
            }
          }

          if(this.currentType === 'genre'){
            for (i = 0; i < data['response'].genre_videos.length; i++) {
              if (data['response'].genre_videos[i].slug === this.currentSlug) {
                this.allVideos = this.allVideos.concat(data['response'].genre_videos[i].video_list.data);
                this.currentTitle = data['response'].genre_videos[i].name;
                this.final = (data['response'].genre_videos[i].video_list.next_page_url === null) ? true : false;
                this.loading = false;
                this.preloading = false;
                this.flag = false;
                this.notFound = (this.allVideos.length > 0) ? false : true;
              }
            }
          }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }


  liveCategoryVideos() {
    this.preloading = true;
    this.livepage = true;
    this.livecategorypage = this.livecategorypage + 1;
    this.apiService.callGetAPI('v2/getLiveCategory_web/'+'category'+'/'+this.currentSlug+'?page='+ this.livecategorypage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          var i;
          if(this.currentType === 'livecategory'){
            this.currentTitle = data['response'].liveCategoryVideo.category_name;
            this.allVideos = this.allVideos.concat(data['response'].liveCategoryVideo.data);
            this.final = (data['response'].liveCategoryVideo.next_page_url === null) ? true : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = (this.allVideos.length > 0) ? false : true;
          }

          if(this.currentType === 'livefavourite'){
            this.currentTitle = data['response'].liveFavouriteVideo.category_name;
            this.allVideos = this.allVideos.concat(data['response'].liveFavouriteVideo.data);
            this.final = (data['response'].liveFavouriteVideo.next_page_url === null) ? true : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = (this.allVideos.length > 0) ? false : true;
          }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }

  specialCategoryVideos() {
    this.preloading = true;
    this.livepage = false;
    this.radiopage = false;
    this.specialCategoryPage = true;
    this.livecategorypage = this.livecategorypage + 1;
    this.apiService.callGetAPI('v2/getspecialCategory'+'/'+this.currentSlug+'?page='+ this.livecategorypage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          var i;
            this.currentTitle = data['response'].category_name;
            console.log(this.currentTitle);
            this.allVideos = this.allVideos.concat(data['response'].specialCategoryVideo.data);
            this.final = (data['response'].specialCategoryVideo.next_page_url === null) ? true : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = (this.allVideos.length > 0) ? false : true;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }


  radioCategoryVideos() {
    this.preloading = true;
    this.radiopage = true;
    this.livecategorypage = this.livecategorypage + 1;
    this.apiService.callGetAPI('v2/getRadioCategory/'+this.currentSlug+'?page='+ this.livecategorypage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          var i;
          if(this.currentType === 'radiocategory'){
            this.currentTitle = data['response'].liveRadioChannel.category_name;
            this.allVideos = this.allVideos.concat(data['response'].liveRadioChannel.data);
            this.final = (data['response'].liveRadioChannel.next_page_url === null) ? true : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = (this.allVideos.length > 0) ? false : true;
          }

          if(this.currentType === 'radiofavourite'){
            this.currentTitle = data['response'].RadioFavouriteChannel.category_name;
            this.allVideos = this.allVideos.concat(data['response'].RadioFavouriteChannel.data);
            this.final = (data['response'].RadioFavouriteChannel.next_page_url === null) ? true : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = (this.allVideos.length > 0) ? false : true;
          }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }

  allLiveFavouriteVideos() {
    this.preloading = true;
    this.livepage = true;
    this.livallfavouritepage = this.livallfavouritepage + 1;
    this.apiService.callGetAPI('v2/allLiveFavourite_web?page='+ this.livallfavouritepage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          var i;
          if(this.currentType === 'alllivefavourite'){
            this.currentTitle = data['response'].allLiveFavouriteVideo.category_name;
            this.allVideos = this.allVideos.concat(data['response'].allLiveFavouriteVideo.data);
            this.final = (data['response'].allLiveFavouriteVideo.next_page_url === null) ? true : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = (this.allVideos.length > 0) ? false : true;
          }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }

  allRadioFavouriteVideos() {
    this.preloading = true;
    this.radiopage = true;
    this.livallfavouritepage = this.livallfavouritepage + 1;
    this.apiService.callGetAPI('v2/allRadioFavouriteForWeb?page='+ this.livallfavouritepage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          // console.log(this.currentType);
          var i;
          if(this.currentType === 'allradiofavourite'){
            this.currentTitle = data['response'].allRadioFavourite.category_name;
            this.allVideos = this.allVideos.concat(data['response'].allRadioFavourite.data);
            this.final = (data['response'].allRadioFavourite.next_page_url === null) ? true : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = (this.allVideos.length > 0) ? false : true;
          }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }
  categoryMoreVideo() {
    this.newandpopularpage = this.newandpopularpage + 1;
    this.preloading = true;
    this.apiService.callGetAPI('v2/more_category_videos?is_web_series=0&type=category'+'&category='+ this.currentSlug+'&page='+ this.newandpopularpage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.allVideos = this.allVideos.concat(data['response'].more_category_videos.video_list.data);
          this.final = (data['response'].more_category_videos.video_list.next_page_url === null) ? true : false;
          this.loading = false;
          this.preloading = false;
          this.flag = false;
          this.notFound = (this.allVideos.length > 0) ? false : true;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }


  genreMoreVideo() {
    this.newandpopularpage = this.newandpopularpage + 1;
    this.preloading = true;
    this.apiService.callGetAPI('v2/more_category_videos?is_web_series=0&type='+ this.currentType +'&category='+ this.currentParent+ '&genre='+ this.currentSlug +'&page='+ this.newandpopularpage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {

          this.allVideos = this.allVideos.concat(data['response'].more_category_videos.video_list.data);
          this.final = (data['response'].more_category_videos.video_list.next_page_url === null) ? true : false;
          this.loading = false;
          this.preloading = false;
          this.flag = false;
          this.notFound = (this.allVideos.length > 0) ? false : true;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }

  allWebseries() {
    this.preloading = true;
    this.page = this.page + 1;
    this.loading = true;
    this.apiService.callGetAPI('v2/childWebseries/'+this.currentSlug+'?page='+ this.page, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          var i;
            for (i = 0; i < data['response'].main_webseries.length; i++) {
              if (data['response'].main_webseries[i].category_slug === this.currentSlug) {
                this.allVideos = this.allVideos.concat(data['response'].main_webseries[i].data)
                this.isWebseries = 1;
                this.currentTitle = data['response'].main_webseries[i].category_name;
                this.final = (data['response'].main_webseries[i].next_page_url === null) ? true : false;
                this.loading = false;
                this.preloading = false;
                this.flag = false;
                this.notFound = (this.allVideos.length > 0) ? false : true;
              }
            }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }

  webseriesGenre() {
    this.preloading = true;
    this.page = this.page + 1;
    this.loading = true;
    this.apiService.callGetAPI('v2/childWebseries/'+this.currentParent+'?section=2'+'?page='+ this.page, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          var i;
            for (i = 0; i < data['response'].genre_webseries.length; i++) {
              if (data['response'].genre_webseries[i].slug === this.currentSlug) {
                this.allVideos = this.allVideos.concat(data['response'].genre_webseries[i].series_list.data)
                this.isWebseries = 1;
                this.currentTitle = data['response'].genre_webseries[i].name;
                this.final = (data['response'].genre_webseries[i].series_list.next_page_url === null) ? true : false;
                this.loading = false;
                this.preloading = false;
                this.flag = false;
                this.notFound = (this.allVideos.length > 0) ? false : true;
              }
            }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }

  moreChildWebseries() {
    this.newandpopularpage = this.newandpopularpage + 1;
    this.preloading = true;
    this.apiService.callGetAPI('v2/more_child_webseries?&type=genre'+ '&category='+ this.currentParent+ '&genre='+ this.currentSlug +'&page='+ this.newandpopularpage, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {

          this.allVideos = this.allVideos.concat(data['response'].more_webseries.series_list.data);
          this.isWebseries = 1;
          this.final = (data['response'].more_webseries.series_list.next_page_url === null) ? true : false;
          this.loading = false;
          this.preloading = false;
          this.flag = false;
          this.notFound = (this.allVideos.length > 0) ? false : true;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }


  clickRadio(data) {
    const userValues = localStorage.getItem('currentUser');
          if (userValues) {
            const userData = this.authService.decryption(userValues);
            if(userData['response'].is_subscribed === 0) {
              this.router.navigate(['/subscription']);
            } else {
              if (data.is_webseries === 0) {
                this.router.navigate(['/video/'+data.slug]);
              } else {
                this.router.navigate(['/webseries/'+data.slug]);
              }
              
            }
          }
  }

  clickSpecialPage(data) {
    this.router.navigate(['/video/'+data.slug]);
  }

  clickLive(data) {

    // this.router.navigate(['/watch/'+data.slug]);
    this.router.navigate(['/live-watch/'+data.slug]);

  }


  clickRadioData(data) {
    const userValues = localStorage.getItem('currentUser');
          if (userValues) {
            const userData = this.authService.decryption(userValues);
            if(userData['response'].is_subscribed === 0) {
              this.router.navigate(['/subscription']);
            } else {
              if (data.is_live === 2) {
                this.router.navigate(['/radio/'+data.slug]);
              } else {
                this.router.navigate(['/radio/'+data.slug]);
              }
              
            }
          }
  }

  openShareVideo(data) {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    // this.videoShareService.displayPopup(this.video);
    this.videoShareService.displayPopup(data);

  }

  favourite(video: VideoModel, e) {
    if (sessionStorage.getItem('tostar')) {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': JSON.parse(sessionStorage.getItem('tostar')).tostarTime
      };
    } else {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': 0
      };
    }
    sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      const favourite = !video.is_favourite;
      if (favourite) {
        this.apiService.callPostAPI('v2/favourite', { video_slug: video.slug }, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success('', 'Radio added to favourite');
                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            });
      } else {
        this.apiService.callDeleteAPI('v2/favourite?video_slug=' + video.slug, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success('', 'Radio removed from favourite');

                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            });
      }

    } else {
      // this.userService.redirectUrl(this.router.url);
      // this.userService.displayPopup('sign-in');
    }
  }

  openPlalistPopup(data) {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.playlistAddService.displayPopup(data);
  }

  ngOnDestroy() {
    if (this.seeallclick) {
      clearInterval(this.seeallclick);
    }
  }

}

