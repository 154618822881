import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostListener,
  ElementRef,
} from "@angular/core";
import { ApiService } from "../../../../core/services/api.service";
import { VideoModel } from "../../../../modules/video-detail/video.model";
import { Router, RoutesRecognized } from "@angular/router";
import { UserService } from "../../../../core/services/user.service";
import { PlayerService } from "../../../../core/services/player.service";
import { VideoShareService } from "../../../../core/services/video-share.service";
import { PlaylistAddService } from "../../../../core/services/playlist-add.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Meta } from "@angular/platform-browser";
import disableScroll from "disable-scroll";
import { trigger, transition, style, animate } from "@angular/animations";
import videojs from "../../../../../assets/js/play.es.js";
import * as CryptoJS from "crypto-js";
import { environment } from "../../../../../environments/environment";
import { StringRemoveWhitespace } from "src/app/shared/stringremovewhitespace";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/pairwise";
import { HeaderService } from "src/app/core/services/header.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

declare var $: any;
@Component({
  selector: "app-trailer-card",
  templateUrl: "./trailer-card.component.html",
  styleUrls: ["./trailer-card.component.scss"],
  animations: [
    trigger("videoCardAnimation", [
      transition(":enter", [
        style({ opacity: 0.2 }),
        animate("700ms", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("300ms", style({ opacity: 0 })),
      ]),
    ]),
  ],
  providers: [StringRemoveWhitespace],
})
export class TrailerCardComponent implements OnInit {
  @Input() video: any = {};
  @Input() history: any;
  @Input() live: boolean;
  @Input() type: string;
  @Input() category: string;
  @Input() playlist_id: any;
  @Input() playlist_name: any;
  @Input() currentlyPlaying: any;
  @Output() favouriteChange = new EventEmitter();
  @Output() historyChange = new EventEmitter();
  @Output() playlistChange = new EventEmitter();
  @Output() dragEvent = new EventEmitter<string>();
  @Output() videoCardClicked = new EventEmitter();
  @Input() blockType: any;
  @Output() messageToEmit = new EventEmitter<any>();
  @Output() urlToEmit = new EventEmitter<any>();

  loggedIn: any = {};
  tostar: any = {};
  loggedTrue: Boolean = false;
  showOption: any = false;
  showFlag: Boolean = false;
  shareDrop: any;
  videoDetails: any;
  playlist: any = [];
  loading: boolean;
  shareFlag: boolean;
  flag: boolean;
  final: boolean;
  submitted: boolean;
  result: boolean;
  errors: any;
  success: any;
  utilityService: any;
  buttonText: string;
  playlistForm: any;
  playlistFlag: boolean;
  addZindex = false;
  slidItemClass = false;
  player: any;
  trailerHoverTimer;
  videoPlayHoverState = true;
  timeouts = [];
  cipherText: any;
  encrypted: any;
  decrypted: any;
  interVal: any;
  plainText: any;
  pre_url: any;
  playerInterval = [];
  premium: any;
  isFav = 0;
  geoFencingMessage: string;
  @HostListener("document:click", ["$event"])
  clickOut() {
    if (this.shareDrop !== undefined) {
      if (!this.eRef.nativeElement.contains(event.target)) {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
      }
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private videoShareService: VideoShareService,
    private playerService: PlayerService,
    private eRef: ElementRef,
    private playlistAddService: PlaylistAddService,
    private meta: Meta,
    private pipe: StringRemoveWhitespace,
    private headerService: HeaderService,
    private translate: TranslateService,
    private toasterService: ToastrService
  ) {
    this.translate.get("PLAYER.NOT_AVILABLE").subscribe((res) => {
      this.geoFencingMessage = res;
    });
    $(function () {
      $(".slider-img img").on("error", function () {
        $(this).attr({
          src: "/assets/images/blank_new.png",
        });
      });
    });
    $(function () {
      $(".live-img-placeholder img").on("error", function () {
        $(this).attr({
          src: "/assets/images/blank_new1.png",
        });
      });
    });
    $(function () {
      $(".radio-img-placeholder img").on("error", function () {
        $(this).attr({
          src: "/assets/images/radio_thumb.png",
        });
      });
    });
  }
  ngOnInit() {
    // console.log(this.type);
    const imgUrl = "";
    if (this.type === "livepageslider") {
      const imgUrl = environment.domain + "assets/images/blank_new1.png";
    } else if (this.type === "radiocategory") {
      const imgUrl = environment.domain + "assets/images/radio_thumb.png";
    } else {
      const imgUrl = environment.domain + "assets/images/blank_new.png";
    }

    this.slidItemClass = this.type === "live";
    this.videoDetails = this.video;
    this.playlistForm = new FormGroup({
      playlist_name: new FormControl("", [
        Validators.required,
        Validators.pattern("[a-zA-Z0-9s]*$"),
      ]),
    });
    this.video.thumbnail_image =
      this.video.thumbnail_image.length > 0
        ? this.video.thumbnail_image
        : imgUrl;
    this.live = this.live ? true : false;
    if (localStorage.getItem("currentUser")) {
      this.loggedTrue = true;
    }
  }
  ngAfterViewInit() {
    this.getPremiumName();
  }

  getPremiumName() {
    this.headerService.apiData$.subscribe((data) => {
      if (data !== null) {
        this.premium = data["response"].address.premium_name;
      }
    });
  }

  showOptions(event) {
    if (this.showOption === false) {
      disableScroll.on(undefined, {
        disableWheel: true,
        disableScroll: false,
        disableKeys: false,
      });
    }
    this.addZindex = true;
    this.shareDrop = event.target;
    this.showOption = !this.showOption;
  }

  openShareVideo() {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false,
    });
    this.videoShareService.displayPopup(this.video);
  }
  openPlalistPopup() {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false,
    });
    this.playlistAddService.displayPopup(this.video);
  }
  favourite(video: VideoModel, e) {
    // console.log(video);
    if (sessionStorage.getItem("tostar")) {
      this.tostar = {
        tostarActive: true,
        tostarTime: JSON.parse(sessionStorage.getItem("tostar")).tostarTime,
      };
    } else {
      this.tostar = {
        tostarActive: true,
        tostarTime: 0,
      };
    }
    sessionStorage.setItem("tostar", JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem("currentUser");
    if (this.loggedIn) {
      const favourite = !video.is_favourite;
      if (favourite) {
        this.apiService
          .callPostAPI(
            "v2/favourite",
            { video_slug: video.slug },
            environment.userActionApiUrl
          )
          .subscribe(
            (data) => {
              if (data["statusCode"] === 200 && data["error"] === false) {
                if (
                  this.tostar.tostarTime === 0 ||
                  (this.tostar.tostarActive &&
                    (Date.now() - this.tostar.tostarTime) / 1000 >= 5)
                ) {
                  this.tostar = {
                    tostarActive: false,
                    tostarTime: Date.now(),
                  };
                  sessionStorage.setItem("tostar", JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, "");
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            (error) => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            }
          );
      } else {
        this.apiService
          .callDeleteAPI(
            "v2/favourite?video_slug=" + video.slug,
            environment.userActionApiUrl
          )
          .subscribe(
            (data) => {
              if (data["statusCode"] === 200 && data["error"] === false) {
                if (
                  this.tostar.tostarTime === 0 ||
                  (this.tostar.tostarActive &&
                    (Date.now() - this.tostar.tostarTime) / 1000 >= 5)
                ) {
                  this.tostar = {
                    tostarActive: false,
                    tostarTime: Date.now(),
                  };
                  sessionStorage.setItem("tostar", JSON.stringify(this.tostar));
                  this.apiService.successResponse(data, "");
                }
                this.video.is_favourite = favourite;
                this.favouriteChange.emit(this.video);
              }
            },
            (error) => {
              this.apiService.failureResponse(error, false);
              this.favouriteChange.emit(this.video);
            }
          );
      }
    } else {
      this.userService.redirectUrl(this.router.url);
      this.userService.displayPopup("sign-in");
    }
  }

  removeHistory(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService
      .callPostAPI("v2/clear_recent_view", { video_id: video.slug })
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.apiService.successResponse(data, "");
          }
        },
        (error) => {
          this.apiService.failureResponse(error, false);
        }
      );
    this.historyChange.emit(this.video);
  }

  deleteWatchHistroy(video: VideoModel, e) {
    e.stopPropagation();
    let currentElement = e.target;
    while (currentElement) {
      if (currentElement.classList.contains("slider-item")) {
        currentElement.remove();
        break;
      }
      currentElement = currentElement.parentElement;
    }
    
    this.apiService
      .callPostAPI("v2/delete_from_countinue_watching/" + this.video.id, {})
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.apiService.successResponse(data, "");
          }
        },
        (error) => {
          this.apiService.failureResponse(error, false);
        }
      );
  }

  playerConfiguration(videoElem) {
    this.player = videojs(videoElem, {
      autoplay: true,
      controls: false,
      preload: true,
      bigPlayButton: false,
      muted: true,
    });
  }
  playTrailer(video, e) {
    if (this.videoPlayHoverState) {
      const blockType = this.pipe.transform(this.blockType);
      const videoslug = this.pipe.transform(video.slug);
      const videoSelector = "trailer-video-" + blockType + "-" + videoslug;
      const getVideo = document.getElementById(videoSelector);
      if (getVideo != null) {
        if (
          video.trailer_hls_url !== null &&
          video.trailer_hls_url !== "" &&
          video.trailer_status === "Complete"
        ) {
          this.playerConfiguration(getVideo);
          this.trailerHoverTimer = setTimeout(
            function () {
              this.player.src({
                src: video.trailer_hls_url,
                type: "application/x-mpegURL",
              });
              getVideo.parentElement.style.display = "block";
              this.player.load();
              this.player.on(
                "play",
                function (e) {
                  this.videoPlayHoverState = false;
                }.bind(this)
              );
              this.player.on(
                "ended",
                function (e) {
                  // if (this.player.currentTime() >= 15) {
                  this.videoPlayHoverState = true;
                  this.player.pause();
                  getVideo.parentElement.style.display = "none";
                  // }
                }.bind(this)
              );
            }.bind(this),
            1000,
            getVideo,
            video
          );
          this.timeouts.push(this.trailerHoverTimer);
        }
      }
    }
  }
  resetTrailer(video) {
    const blockType = this.pipe.transform(this.blockType);
    const videoslug = this.pipe.transform(video.slug);
    const videoSelector = "trailer-video-" + blockType + "-" + videoslug;
    // const videoSelector = 'trailer-video-' + this.blockType.replace(" ","-") + '-' + video.slug.replace(" ","-");
    const getVideo = document.getElementById(videoSelector);
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
    for (let i = 0; i < this.playerInterval.length; i++) {
      clearInterval(this.playerInterval[i]);
    }
    this.playerInterval = [];
    this.plainText = null;
    this.timeouts = [];
    if (
      video.trailer_hls_url !== null &&
      video.trailer_hls_url !== "" &&
      video.trailer_status === "Complete"
    ) {
      this.playerConfiguration(getVideo);
      this.videoPlayHoverState = true;
      this.player.reset();
    }
  }
  decrypt() {
    if (typeof this.video.passphrase !== "undefined") {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.video.passphrase),
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC,
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.incrementInterVal();
    }
  }
  incrementInterVal() {
    this.interVal = setInterval(
      function () {
        this.plainText += 1;
      }.bind(this),
      1000
    );
    this.playerInterval.push(this.interVal);
  }

  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse("vplayed/" + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC,
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  binaryToHex(num) {
    let str = "",
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
  }
  d2h(d) {
    return d.toString(16);
  }
  videoClick(video, imageClick) {
    if (video) {
      this.router.events
        .filter((e) => e instanceof RoutesRecognized)
        .pairwise()
        .subscribe((event: any[]) => {
          this.pre_url = event[0].urlAfterRedirects;
          localStorage.setItem("pre_url", this.pre_url);
        });
    }
    if (video.is_restricted == 1) {
      this.toasterService.error("", this.geoFencingMessage);
    } else {
      if (imageClick === "image" || imageClick === undefined) {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
        this.playerService.videoData(video);
        if (video.hasOwnProperty("is_live") && video.is_live === 0) {
          if (video.is_premium === 1) {
            if (video.is_subscribed === 1) {
              if (video.slug) {
                if (this.type === "playlist") {
                  // this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                  this.router.navigate(["video/" + video.slug]);
                } else {
                  // this.router.navigate(['watch/' + video.slug]);
                  if (video.is_subscribed === 1 && video.is_parental === 0) {
                    this.router.navigate(["video/" + video.slug]);
                  } else if (
                    video.is_subscribed === 1 &&
                    video.is_parental === 1
                  ) {
                    this.router.navigate(["video/" + video.slug]);
                  } else {
                    this.router.navigate(["video/" + video.slug]);
                  }
                }
              }
            } else {
              if (video.slug) {
                if (this.type === "playlist") {
                  this.router.navigate([
                    "video/" +
                      video.slug +
                      "/" +
                      this.playlist_id +
                      "/" +
                      this.playlist_name,
                  ]);
                } else {
                  this.router.navigate(["video/" + video.slug]);
                }
              }
            }
          } else {
            if (video.slug) {
              if (this.type === "playlist") {
                // this.router.navigate(['watch/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                this.router.navigate(["video/" + video.slug]);
              } else {
                if (video.is_subscribed === 1 && video.is_parental === 0) {
                  this.router.navigate(["video/" + video.slug]);
                } else if (
                  video.is_subscribed === 1 &&
                  video.is_parental === 1
                ) {
                  this.router.navigate(["video/" + video.slug]);
                } else {
                  this.router.navigate(["video/" + video.slug]);
                }
              }
            }
          }
        } else if (video.is_live === 1) {
          if (video.is_subscribed === 1) {
            this.router.navigate(["video/" + video.slug]);
          } else {
            this.router.navigate(["subscription"]);
          }
        } else if (video.is_live === 2) {
          if (video.is_subscribed === 1) {
            this.router.navigate(["radio/" + video.slug]);
          } else {
            this.router.navigate(["subscription"]);
          }
        } else {
          if (video.is_premium === 1) {
            if (this.type === "playlist") {
              // this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
              if (video.hasOwnProperty("webseries_order")) {
                this.router.navigate(["webseries/" + video.slug]);
              } else {
                this.router.navigate(["video/" + video.slug]);
              }
            } else {
              this.router.navigate(["video/" + video.slug]);
            }
          } else {
            if (video.is_subscribed === 1 && video.is_parental === 0) {
              if (this.type === "playlist") {
                // this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                if (video.hasOwnProperty("webseries_order")) {
                  this.router.navigate(["webseries/" + video.slug]);
                } else {
                  this.router.navigate(["video/" + video.slug]);
                }
              } else {
                this.router.navigate(["video/" + video.slug]);
              }
            } else if (video.is_subscribed === 1 && video.is_parental === 1) {
              if (this.type === "playlist") {
                // this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                if (video.hasOwnProperty("webseries_order")) {
                  this.router.navigate(["webseries/" + video.slug]);
                } else {
                  this.router.navigate(["video/" + video.slug]);
                }
              } else {
                this.router.navigate(["video/" + video.slug]);
              }
            } else {
              if (this.type === "playlist") {
                // this.router.navigate(['video/' + video.slug + '/' + this.playlist_id + '/' + this.playlist_name]);
                if (video.hasOwnProperty("webseries_order")) {
                  this.router.navigate(["webseries/" + video.slug]);
                } else {
                  this.router.navigate(["video/" + video.slug]);
                }
              } else {
                this.router.navigate(["video/" + video.slug]);
              }
            }
          }
        }
      } else {
        disableScroll.off();
        this.showOption = false;
        this.shareDrop = undefined;
        this.addZindex = false;
        this.playerService.videoData(video);
        if (video.slug) {
          if (this.type === "playlist") {
            this.router.navigate([
              "video/" +
                video.slug +
                "/" +
                this.playlist_id +
                "/" +
                this.playlist_name,
            ]);
          } else {
            this.router.navigate(["video/" + video.slug]);
          }
        }
      }
    }
  }

  webseriesClick(video, currentType) {
    // this.router.navigate(['web-series/'+ video.slug]);
    this.router.navigate(["webseries/" + video.slug]);
  }

  removeFromPlaylist(video: VideoModel, e) {
    e.stopPropagation();
    this.apiService
      .callDeleteAPI(
        "v2/create_playlist_videos?playlist_id=" +
          this.playlist_id +
          "&video_id=" +
          this.video.slug
      )
      .subscribe(
        (data) => {
          this.apiService.successResponse(data);
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
    this.playlistChange.emit(video);
  }

  videoCardFullClick(video) {
    this.videoCardClicked.emit(video);
  }

  radioclick(video) {
    if (video.is_subscribed === 1) {
      this.router.navigate(["radio/" + video.slug]);
    } else {
      this.router.navigate(["subscription"]);
    }
  }

  liveClick(video) {
    this.videoCardClicked.emit(video);
  }
}
