import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Router } from '@angular/router';
import disableScroll from 'disable-scroll';
import { environment } from '../../../environments/environment';
import { VideoModel } from '../../modules/video-detail/video.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/auth/auth.service';


@Component({
  selector: 'app-liveevent',
  templateUrl: './liveevent.component.html',
  styleUrls: ['./liveevent.component.scss']
})
export class LiveeventComponent implements OnInit {
  allDatas: any = {};
  page = 1;
  newNextPage: Boolean = true;
  countdown: any;
  x: any;
  currentVideo: any;
  noVideo = '';
  notFound: Boolean = false;
  loader: Boolean = true;
  isLink: Boolean = false;
  currentvVideoIndex = 0;
  removeId: any;

  constructor(private apiService: ApiService, private router: Router, private toasterService: ToastrService, private auth: AuthService) {
    window.scrollTo(0, 0);
   }


  sliderOptions = {
    stagePadding: 200,
      loop:false,
      margin:10,
      navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
      items:1,
      lazyLoad: true,
      nav:true,
      dots: false,
    responsive: { 
      '0': {items:1,
        stagePadding: 0}, 
      '600': {   items:1,
        stagePadding: 100},
      '1000': {  items:1,
        stagePadding: 300},
      '1500': { items:1,
        stagePadding: 400},
      '1700': { items:1,
          stagePadding: 450}
    },
    onChange: (e) => {
      // console.log("change");
      // console.log(typeof e.property.value);
      if (typeof e.property.value === "number"){
        this.currentvVideoIndex = e.property.value;
        // console.log(this.currentvVideoIndex);
          clearInterval(this.x);
          this.countDown(this.currentvVideoIndex);
        }

    }
  }

  getData(data) {
    console.log(data);
}

  ngOnInit() {
    this.getAllEvent();
    
  }


  next(url) {
      this.nextEvent();
    
  }

  getAllEvent() {
    // clearInterval(this.x);
    this.apiService.callGetAPI('v2/allEventWeb', environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.allDatas = data && data['response'].event_live_videos;
          if (this.allDatas.length === 0) {
            if (localStorage.getItem('currentLanguage') === 'en') {
              this.noVideo = 'No live events have been scheduled yet';
            } else {
              this.noVideo = 'لم يتم إضافة أي أحداث تلفزيونية حاليا';
            }
            this.notFound = true;
          }
          this.loader = false;
          setTimeout(() => {
            clearInterval(this.x);
            this.countDown(this.currentvVideoIndex);
          }, 1);
        }
      },
      error => {
        this.loader = false;
        this.apiService.failureResponse(error, true);
      });
  }

  nextEvent() {
    this.page++;
      this.apiService
        .callGetAPI('v2/allEventWeb?type=liveevent&page=' + this.page)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].event_live_videos.total === this.allDatas.data.length) {
                this.newNextPage = false;
              }
              data['response'].event_live_videos.data.forEach((value) => {
                this.allDatas.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
  }

  countDown(index) {
    this.countdown = document.querySelector('.countdown');
    // Update every second
    const div = document.getElementById('countdown');
    // div.style.zIndex = '5';
    this.x = setInterval(function () {
      // Set Launch Date (ms)
      const date = new Date(this.allDatas[index].scheduledStartTime);
      const launchDate = new Date(date).getTime();
      // Get todays date and time (ms)
      const now = new Date().getTime();

      const today = new Date()
      const israelDate = today.toLocaleString('he-IL', {timeZone: "Asia/Jerusalem"});
      var d = israelDate,
      dArr = d.split('.'),
      israelTime = new Date(dArr[1] + "," + dArr[0] + "," + dArr[2]).getTime(); 

      this.removeData(this.allDatas);
      // clearInterval(this.x);

      // Distance from now and the launch date (ms)
      const distance = launchDate - israelTime;
      if (distance > 0) {
        // Time calculation
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);


        // if(days === 0 && hours === 0 && mins === 0 && seconds === 0){

        // }

        // Display result
        document.querySelector('.countdown').innerHTML = `
                  <div><span>Day</span><strong>${days}</strong></div>
                  <div><span>Hour</span><strong>${hours}</strong></div>
                  <div><span>Minute</span><strong>${mins}</strong></div>
                  <div><span>Second</span><strong>${seconds}</strong></div>
                `;
        this.isLink = false;
        this.countdown.style.color = '#ffffff';
      }
      // If launch date is reached
      if (distance < 0) {
        // Stop countdown
        clearInterval(this.x);
        // Style and output text
        this.countdown.style.color = '#121212';
        this.countdown.innerHTML = 'Launched!';
        if (this.countdown.innerHTML === 'Launched!') {
          this.isLink = true;
          clearInterval(this.x);
        }
      }

    }.bind(this), 10);
  } 

  listClick(i, data) {
    if (i === this.currentvVideoIndex) {
      // this.router.navigate(['/watch/'+data.slug]);
      this.router.navigate(['/live-watch/'+data.slug]);
    }
  }

  removeData(data) {
    this.removeId = setInterval(() =>{
      data.forEach((item, index, object)=> {

        // const date = new Date();
        // var dateStr = date.getFullYear() + "-" +
        //   ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
        //   ("00" + date.getDate()).slice(-2) + " " +
        //   ("00" + date.getHours()).slice(-2) + ":" +
        //   ("00" + date.getMinutes()).slice(-2) + ":" +
        //   ("00" + date.getSeconds()).slice(-2);

          const today = new Date()
          const israelDate = today.toLocaleString('he-IL', {timeZone: "Asia/Jerusalem"});
          const d = israelDate,
          dArr = d.split('.'),
          israelTime = new Date(dArr[1] + "," + dArr[0] + "," + dArr[2]); 
          const dateConvert = israelTime.getFullYear() + "-" +
          ("00" + (israelTime.getMonth() + 1)).slice(-2) + "-" +
          ("00" + israelTime.getDate()).slice(-2) + " " +
          ("00" + israelTime.getHours()).slice(-2) + ":" +
          ("00" + israelTime.getMinutes()).slice(-2) + ":" +
          ("00" + israelTime.getSeconds()).slice(-2);

        if (dateConvert > item.scheduledEndTime) {
          object.splice(index, 1);
            this.apiService.callGetAPI('v2/event_status/'+item.id, environment.apiUrl)
            .subscribe(
              data => {
                if (data['statusCode'] === 200 && data['error'] === false) {
                  clearInterval(this.x);
                  this.getAllEvent();
                  setInterval(function(){ 
                    location.reload();
                  }, 500);
                }
              },
              error => {
                this.apiService.failureResponse(error, true);
              });
        }

      });
    }, 1000);

  }

  ngOnDestroy() {
    clearInterval(this.x);
    if (this.removeId) {
      clearInterval(this.removeId);
    }
  }
  

}
