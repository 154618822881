import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { AuthService } from '../../../core/auth/auth.service';
import { ApiService } from '../../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { VideoShareService } from '../../../core/services/video-share.service';
import { PlaylistAddService } from '../../../core/services/playlist-add.service';
import { UtilityService } from '../../../core/services/utility.service';
import { environment } from '../../../../environments/environment';
import { Meta } from '@angular/platform-browser';
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective
} from '@angular/forms';
import disableScroll from 'disable-scroll';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from 'src/app/core/services/header.service';
import { CostExplorer } from 'aws-sdk';
import { interval, Subscription} from 'rxjs';


declare var $: any;
declare var FB: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  theme: any;
  user: any = [];
  search: any = [];
  searchText: any;
  signIn = false;
  signUp = false;
  forget = false;
  category: any = [];
  categoryLength: any = [];
  searchData: any = [];
  searchString: string;
  dropdown: any;
  searchDropdown: any;
  menuClick = false;
  notificationDropdown: any;
  notificationClick = false;
  searchClick = false;
  profileClick = false;
  profileDropdown: any;
  addOpenClass = false;
  addOpenProfile = false;
  addOpenSearch = false;
  shareFlag: any = false;
  searchFlag: any = 0;
  videoDetails: any = {};
  playlist: any = [];
  j: any = 0;
  playlist_name: any;
  result: any;
  success: any;
  submitted: boolean;
  buttonText: String;
  errors: any;
  resultCount: any = 0;
  index: any = -1;
  page: any = 1;
  final: Boolean = false;
  flag: Boolean;
  loading: Boolean;
  searchBox: any;
  searchBoxClick = false;
  playlistForm: FormGroup;
  sharePopupTarget: any;
  shareTeenPopupTarget: any;
  firstTimeUser = localStorage.getItem('firstTimeUser');
  routeSub: any;
  userValues: any;
  is_subscribed: any;
  searchContnet = new FormControl();
  formCtrlSub: any;
  searchValue: any;
  playlistSubmitted: any = false;
  link: string;
  login_type: Boolean = true;
  bodyVar: any;
  routerUrlSlug;
  shareUrlSlug: any = 'video/';
  reset: any = '';
  myDeviceLogout: Subscription;
  accessRestricted = false;
  geoStatus = false;
  currentRoute;

  public languageList = [
    { lang: 'en', name: 'English - EN', alis: 'EN' },
    { lang: 'ar', name: 'العربية - AR', alis: 'AR' }
  ];
  
  public selectedLang = [];
  public showLangDrop = false;
  showLangDropdown: any;
  public liveroute: Boolean = false;
  banner = [];
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      this.notificationDropdown !== undefined &&
      this.notificationClick &&
      !document.getElementById('notification-dropdown').contains(event.target)
    ) {
      const element = document.getElementById('notify');
      if (!element.contains(event.target)) {
        this.notificationClick = false;
      }
    }

    if (
      this.showLangDropdown !== undefined &&
      this.showLangDrop &&
      !document.getElementById('lang-dropdown').contains(event.target)
    ) {
      const element = document.getElementById('lang-toggle');
      if (!element.contains(event.target)) {
        this.showLangDrop = false;
      }
    }
    // $('.min-content,.searchboxEvent').click(function(this) {
    //   if (this.id !== 'video-search') {
    //     $('.search-blk').removeClass('open');
    //   }
    // });
    if (this.searchBox !== undefined && this.searchBoxClick) {
      const element = document.getElementById('search');
      if (element.contains(event.target)) {
        $('.search-blk').addClass('open');
        setTimeout(() => {
          document.getElementById('video-search').focus();
        }, 700);
      } else if (
        !element.contains(event.target) &&
        !this.router.url.startsWith('/search')
      ) {
        $('.search-blk').removeClass('open');
        this.searchBoxClick = false;
      }
    }

    if (this.searchDropdown !== undefined && this.searchClick) {
      if (
        (this.eRef.nativeElement.contains(event.target) &&
          !this.searchDropdown.contains(event.target)) ||
        !this.eRef.nativeElement.contains(event.target)
      ) {
        this.search = [];
        this.searchData = [1, 2, 3];
        this.searchText = 1;
      } else if (this.searchDropdown.contains(event.target)) {
        this.videoSearch(this.searchString);
      }
    }

    if (this.profileDropdown !== undefined && this.profileClick) {
      if (
        (this.eRef.nativeElement.contains(event.target) &&
          !this.profileDropdown.contains(event.target)) ||
        !this.eRef.nativeElement.contains(event.target)
      ) {
        $('.dropdown-profile').removeClass('open');
      } else if (this.profileDropdown.contains(event.target)) {
        $('.dropdown-profile').toggleClass('open');
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosY = window.pageYOffset || document.body.scrollTop;
    const navBar = document.querySelector('.f-head');
    const wholeHeader = document.querySelector('.whole-header');
    const searchBox = document.querySelector('.search-results');
    if (scrollPosY > 40) {
      navBar.classList.add('fixed');
      wholeHeader.classList.add('header-fixed');
      if (searchBox) {
        searchBox.classList.add('shadow');
      }
    } else {
      wholeHeader.classList.remove('header-fixed');
      navBar.classList.remove('fixed');
      if (searchBox) {
        searchBox.classList.remove('shadow');
      }
    }
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private apiService: ApiService,
    private eRef: ElementRef,
    private videoShareService: VideoShareService,
    private playlistAddSearvuce: PlaylistAddService,
    private utilityService: UtilityService,
    private meta: Meta,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private headerService: HeaderService
  ) {
    if (localStorage.getItem('currentLanguage')) {
      this.translate.use(localStorage.getItem('currentLanguage'));
    }
    this.translate.get('HEADER.ADD_PLAYLIST').subscribe((res) => {
      this.buttonText = res;
    });
    this.formCtrlSub = this.searchContnet.valueChanges
      .debounceTime(500)
      .distinctUntilChanged()
      .subscribe((newValue) => {
        this.serachChange();
      });
    if (
      localStorage.getItem('currentUser') !== null ||
      localStorage.getItem('currentUser') !== undefined
    ) {
      this.userValues = localStorage.getItem('currentUser');
        if (this.userValues) {
          this.is_subscribed = this.authService.decryption(this.userValues)['response'].is_subscribed;
        }
    }
    this.myDeviceLogout = interval(10000).subscribe((x => {
       this.deviceLogout();
    }));
  }

  deviceLogout() {
      if ( localStorage.getItem('random_key') )  {
        this.apiService
        .callPostAPI('v2/devicelimit',
        {
          acesstype: 'web',
          'random_key': localStorage.getItem('random_key')
        }, environment.userApiUrl)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'] === null) {
                localStorage.clear();
                this.router.navigate(['/landingpage']);
              }
            }
          },
          (error) => {
            // this.apiService.failureResponse(error, true);
          }
        );
    }
}

  ngOnInit() {
    this.bodyVar = document.getElementsByTagName('body')[0];
    if (this.firstTimeUser === undefined || this.firstTimeUser === null) {
      this.bodyVar.classList.add('firstuser-live');
    }
    this.selectedLang = this.languageList.map((_) => false);
    this.languageList.forEach((val, i) => {
      if (localStorage.getItem('currentLanguage') === val.lang) {
        this.selectedLang[i] = true;
      }
    });
    this.headerService.bannerData$.subscribe((data) => {
      this.banner = data;
      if (this.banner === null) {
        document.body.classList.add('no-banner');
      } else {
        if (this.banner['data'].length === 0) {
          document.body.classList.add('no-banner');
        } else {
          document.body.classList.remove('no-banner');
        }
      }
    });

    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'light') {
        this.translate.get('HEADER.DARK').subscribe((res) => {
          this.theme = res;
        });
        document
          .getElementsByClassName('dark-icon')[0]
          .classList.remove('activated');
        document.body.classList.add('main-light');
      }
      if (localStorage.getItem('theme') === 'dark') {
        this.translate.get('HEADER.LIGHT').subscribe((res) => {
          this.theme = res;
        });
        document
          .getElementsByClassName('dark-icon')[0]
          .classList.add('activated');
        document.body.classList.add('main-dark');
      }
    } else {
      this.translate.get('HEADER.DARK').subscribe((res) => {
        this.theme = res;
      });
      document.body.classList.add('main-dark');
      localStorage.setItem('theme', 'dark');
    }

    // if (
    //   localStorage.getItem('firstTimeUser') === undefined ||
    //   localStorage.getItem('firstTimeUser') === null
    // ) {
    //   document
    //     .getElementsByTagName('body')[0]
    //     .classList.add('first-user-activated');
    // }
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
        this.userValues = localStorage.getItem('currentUser');
        if (this.userValues) {
          const userData = this.authService.decryption(this.userValues);
          this.is_subscribed = userData['response'].is_subscribed;
          this.geoStatus = userData['response'].is_restricted;
        }
        if (!this.router.url.startsWith('/search')) {
          this.searchValue = '';
          this.routerUrlSlug = this.router.url.split('/');
          this.routerUrlSlug = this.routerUrlSlug[
            this.routerUrlSlug.length - 1
          ];
        } else {
          this.route.queryParams.subscribe((params) => {
            this.searchValue = params['q'];
          });
        }
      }
      this.searchFlag = 0;
      if (
        (window.location.pathname === '/home' ||
          window.location.pathname === '/') &&
        window.screen.width >= 800
      ) {
        document.getElementsByTagName('header')[0].classList.add('home-header');
        if (!localStorage.getItem('firstTimeUser')) {
          document
            .getElementsByTagName('body')[0]
            .classList.add('home-demo-content');
        }
      } else {
        document
          .getElementsByTagName('header')[0]
          .classList.remove('home-header');
        if (!localStorage.getItem('firstTimeUser')) {
          document
            .getElementsByTagName('body')[0]
            .classList.remove('home-demo-content');
        }
      }

      if ( window.location.pathname === '/landingpage' || this.router.url.includes('/reset-password/') || this.router.url.includes('/act/') || this.router.url.includes('/reset-parental/') || this.router.url.includes('pages/')  )  {
        document.getElementsByTagName('header')[0].classList.add('landingpage-header');
      } else {
        document.getElementsByTagName('header')[0].classList.remove('landingpage-header');
      }

      if ( this.router.url.includes('/radio/')  )  {
        document.getElementsByTagName('header')[0].classList.add('radio-header');
      } else {
        document.getElementsByTagName('header')[0].classList.remove('radio-header');
      }

      if ( this.router.url.includes('/live-watch/')  )  {
        document.getElementsByTagName('header')[0].classList.add('live-watch');
      } else {
        document.getElementsByTagName('header')[0].classList.remove('live-watch');
      }


      if ( window.location.pathname === '/subscription' )  {
        document.getElementsByTagName('header')[0].classList.add('subscription-header');
      } else {
        document.getElementsByTagName('header')[0].classList.remove('subscription-header');
      }
      if ( window.location.pathname === '/kids' )  {
        document
        .getElementsByTagName('body')[0]
        .classList.add('kids');
        document.getElementsByTagName('header')[0].classList.add('kids-header');
      } else {
        document.getElementsByTagName('header')[0].classList.remove('kids-header');
        document
        .getElementsByTagName('body')[0]
        .classList.remove('kids');
      }
    });
    this.playlistForm = new FormGroup({
      playlist_name: new FormControl('', [Validators.required])
    });
    // user details Observable
    this.userService.localUser.subscribe((data) => {
      const currentUser = this.authService.decryption(data);
      this.user = currentUser ? currentUser['response'] : '';
      if (this.user) {
        this.login_type =
          currentUser['response'].login_type === 'Web' ? true : false;
      }
    });
    // Popup details Observable
    this.userService.display.subscribe((data) => {
      this.onShowHide(data);
    });
    // popup for video share
    this.videoShareService.display.subscribe((data) => {
      this.showSharePopup(data);
      this.j++;
    });
    // popup for add playlist
    this.playlistAddSearvuce.display.subscribe((data) => {
      if (localStorage.getItem('currentUser')) {
        this.showPlaylistPopup(data);
      } else if (this.j > 1) {
        this.signIn = true;
      }
      this.j++;
    });
    this.shareFlag = false;
    //
    this.getCategoryList();

    // toggle dropdown
    const toggleMenu = document.querySelector('.toggle-menu');
    const dropMenu = document.querySelector('.dropdown-menu');

    // search bar
    if (screen.width <= 1024) {
      const searchBox = document.querySelector('.search-blk');
      const bodyClick = document.querySelector('body');

      // searchBox.addEventListener('click', function(e) {
      //   searchBox.classList.add('open');
      // });
      bodyClick.addEventListener('click', function (e) {
        if (e.target['parentNode'].className.indexOf('search-blk') === -1) {
          searchBox.classList.remove('open');
        }
      });
    }

    // mobile menu
    if (screen.width <= 800) {
      dropMenu.classList.add('mobile-menu');
      toggleMenu.addEventListener('click', function () {});
    }
    // fixed header
    window.addEventListener('scroll', function () {
      const scrollPosY = window.pageYOffset || document.body.scrollTop;
      const navBar = document.querySelector('.f-head');
      const wholeHeader = document.querySelector('.whole-header');
      const searchBox = document.querySelector('.search-results');
      if (window.pageYOffset > 40) {
        navBar.classList.add('fixed');
        wholeHeader.classList.add('header-fixed');
        if (searchBox) {
          searchBox.classList.add('shadow');
        }
      } else {
        navBar.classList.remove('fixed');
        wholeHeader.classList.remove('header-fixed');
        if (searchBox) {
          searchBox.classList.remove('shadow');
        }
      }
    });
    window.addEventListener(
      'keyup',
      function (event) {
        if (event.keyCode === 27) {
          this.closeSharePopup();
          this.hidePlaylistPopup();
          this.cancelToAddPlaylist();
          this.cancelToGenAddPlaylist();
          this.signIn = false;
          this.forget = false;
          this.signUp = false;
        }
      }.bind(this)
    );
    if (this.playlist.length > 0) {
      const elementOfPopup = document.getElementsByClassName(
        'create-playlist-list'
      );
      elementOfPopup[0].addEventListener(
        'scroll',
        function () {
          if (
            elementOfPopup[0].scrollHeight - elementOfPopup[0].scrollTop <
              250 &&
            this.final === false &&
            this.flag === false
          ) {
            this.page += 1;
            this.loading = true;
            this.flag = true;
            this.getPlaylists();
          }
        }.bind(this)
      );
    }
  }

  ngAfterViewInit() {
    
    if (localStorage.getItem('currentLanguage') === 'en') {
    const lantext = document.querySelector('body');
    lantext.classList.add('english_lan');
    document.getElementsByTagName('header')[0].classList.add('english_lan');
    } else {
      const lantext = document.querySelector('body');
      lantext.classList.add('arabic_lan');
      document.getElementsByTagName('header')[0].classList.add('arabic_lan');     
    }

  if ( window.location.pathname === '/landingpage' || this.router.url.includes('/reset-password/') || this.router.url.includes('/act/') || this.router.url.includes('/reset-parental/') || this.router.url.includes('pages/')  )  {
    const footer = document.querySelector('body');
    footer.classList.add('landingpage');
    document.getElementsByTagName('header')[0].classList.add('landingpage-header');
  } else {
    const footer = document.querySelector('body');
    footer.classList.remove('landingpage');
    document.getElementsByTagName('header')[0].classList.remove('landingpage-header');
  }

  if (this.router.url.includes('/radio/')  )  {
    const footer = document.querySelector('body');
    footer.classList.add('radioplayerpage');
    document.getElementsByTagName('header')[0].classList.add('radio-header');
  } else {
    const footer = document.querySelector('body');
    footer.classList.remove('radioplayerpage');
    document.getElementsByTagName('header')[0].classList.remove('radio-header');
  }

  if (this.router.url.includes('/live-watch/')  )  {
    const footer = document.querySelector('body');
    footer.classList.add('live-watch');
    document.getElementsByTagName('header')[0].classList.add('live-watch');
  } else {
    const footer = document.querySelector('body');
    footer.classList.remove('live-watch');
    document.getElementsByTagName('header')[0].classList.remove('live-watch');
  }



  if ( window.location.pathname === '/subscription' )  {
    const subfooter = document.querySelector('body');
    subfooter.classList.add('subscription');
    document.getElementsByTagName('header')[0].classList.add('subscription-header');
  } else {
    const subfooter = document.querySelector('body');
    subfooter.classList.remove('subscription');
    document.getElementsByTagName('header')[0].classList.remove('subscription-header');
  }

  if ( window.location.pathname === '/kids' )  {
    const subkids = document.querySelector('body');
    subkids.classList.add('kids');
    document.getElementsByTagName('header')[0].classList.add('kids-header');
  } else {
    const subkids = document.querySelector('body');
    subkids.classList.remove('kids');
    document.getElementsByTagName('header')[0].classList.remove('kids-header');
  }
  this.routeSub = this.router.events.subscribe((evt) => {
    if ( window.location.pathname === '/landingpage' || this.router.url.includes('/reset-password/') || this.router.url.includes('/act/') || this.router.url.includes('/reset-parental/') || this.router.url.includes('pages/')  )  {
      const footer = document.querySelector('body');
      footer.classList.add('landingpage');
      document.getElementsByTagName('header')[0].classList.add('landingpage-header');
    } else {
      const footer = document.querySelector('body');
      footer.classList.remove('landingpage');
      document.getElementsByTagName('header')[0].classList.remove('landingpage-header');
    }

    if (this.router.url.includes('/radio/')  )  {
      const footer = document.querySelector('body');
      footer.classList.add('radioplayerpage');
      document.getElementsByTagName('header')[0].classList.add('radio-header');
    } else {
      const footer = document.querySelector('body');
      footer.classList.remove('radioplayerpage');
      document.getElementsByTagName('header')[0].classList.remove('radio-header');
    }

    if (this.router.url.includes('/live-watch/')  )  {
      const footer = document.querySelector('body');
      footer.classList.add('live-watch');
      document.getElementsByTagName('header')[0].classList.add('live-watch');
    } else {
      const footer = document.querySelector('body');
      footer.classList.remove('live-watch');
      document.getElementsByTagName('header')[0].classList.remove('live-watch');
    }

  });

}

  toggleTheme(): void {
    if (document.body.classList.contains('main-dark')) {
      localStorage.setItem('theme', 'light');
      document.body.classList.remove('main-dark');
      document.body.classList.add('main-light');
      if (this.banner['data'].length === 0) {
        document.body.classList.add('no-banner');
      } else {
        document.body.classList.remove('no-banner');
      }
      document
        .getElementsByClassName('dark-icon')[0]
        .classList.remove('activated');
      this.translate.get('HEADER.DARK').subscribe((res) => {
        this.theme = res;
      });
    } else if (document.body.classList.contains('main-light')) {
      localStorage.setItem('theme', 'dark');
      document.body.classList.remove('main-light');
      document.body.classList.add('main-dark');
      document
        .getElementsByClassName('dark-icon')[0]
        .classList.add('activated');
      this.translate.get('HEADER.LIGHT').subscribe((res) => {
        this.theme = res;
      });
      document.body.classList.remove('no-banner');
    } else {
      if (localStorage.getItem('theme')) {
        if (localStorage.getItem('theme') === 'light') {
          this.translate.get('HEADER.DARK').subscribe((res) => {
            this.theme = res;
          });
          document.body.classList.add('main-light');
        }
        if (localStorage.getItem('theme') === 'dark') {
          this.translate.get('HEADER.LIGHT').subscribe((res) => {
            this.theme = res;
          });
          document.body.classList.add('main-dark');
        }
      } else {
        this.translate.get('HEADER.DARK').subscribe((res) => {
          this.theme = res;
        });
        document.body.classList.add('main-light');
      }
    }
  }
  serachChange() {
    if (!this.router.url.startsWith('/search')) {
      if (this.router.url) {
        localStorage.setItem('currentUrl', this.router.url);
      }
    }
    if (
      this.searchValue === undefined ||
      this.searchValue === null ||
      this.searchValue.trim().length < 1
    ) {
      if (localStorage.getItem('currentUrl')) {
        this.router.navigate([localStorage.getItem('currentUrl')]);
        localStorage.removeItem('currentUrl');
      } else {
        this.router.navigate(['home']);
      }
    } else {
      this.router.navigate(['/search'], {
        queryParams: { q: this.searchValue }
      });
    }
  }
  showNotifications(event) {
    this.notificationDropdown = event.target;
    this.notificationClick = !this.notificationClick;
    this.apiService
      .callGetAPI('v2/notification/clear', environment.notificationApiUrl)
      .subscribe(
        (data) => {
          this.user.notification_count = 0;
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }
  showLangDropMethod(e) {
    this.showLangDropdown = e;
    this.showLangDrop = !this.showLangDrop;
  }
  // open share popup
  showSharePopup(data: any) {
    if (data && this.j > 1) {
      disableScroll.on(undefined, {
        disableWheel: true,
        disableScroll: true,
        disableKeys: false
      });
      this.videoDetails = data;
      this.shareUrlSlug =
        this.videoDetails.hasOwnProperty('iswebseries') &&
        this.videoDetails.iswebseries === true
          ? 'webseries/'
          : 'video/';
      if (!this.videoDetails.thumbnail_image) {
        this.videoDetails.thumbnail_image = 'assets/images/no-image-min.jpg';
      }
      this.meta.updateTag({
        property: 'og:title',
        content: this.videoDetails.title
      });
      this.meta.updateTag({
        property: 'og:image',
        content: this.videoDetails.thumbnail_image
      });
      if (this.videoDetails.description) {
        this.meta.updateTag({
          property: 'og:description',
          content: this.videoDetails.description
        });
      }
      this.meta.updateTag({
        property: 'og:url',
        content: `${
          environment.domain + this.shareUrlSlug + this.videoDetails.slug
        }`
      });
      const elements = document.getElementsByClassName('share-popup');
      elements[0].classList.remove('close');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-video');
      this.shareFlag = true;
    }
  }

  // open add video to playlist popup
  showPlaylistPopup(data) {
    if (data && this.j > 1) {
      disableScroll.on(undefined, {
        disableWheel: true,
        disableScroll: true,
        disableKeys: false
      });
      this.loading = true;
      // get playlist lists
      this.playlist = [];
      this.page = 1;
      this.final = false;
      this.videoDetails = data;
      this.getPlaylists();
      const elements = document.getElementsByClassName('create-playlist-popup');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-video');
      this.shareFlag = true;
    }
  }
  hidePlaylistPopup() {
    disableScroll.off();
    this.playlist = [];
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    element[0].classList.remove('for-video');
  }
  // open add new playlist popup
  addNewPlaylistPopup() {
    disableScroll.off();
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false
    });
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.remove('open');
    const addPlaylistElement = document.getElementsByClassName(
      'create-playlist-name-popup'
    );
    addPlaylistElement[0].classList.add('open');
    setTimeout(() => {
      document.getElementById('playlist_names').focus();
    }, 500);
  }

  // cancel to add playlist and show playlist popup
  cancelToAddPlaylist() {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false
    });
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.add('open');
    const addPlaylistElement = document.getElementsByClassName(
      'create-playlist-name-popup'
    );
    addPlaylistElement[0].classList.remove('open');
  }
  cancelToGenAddPlaylist() {
    // disableScroll.off();
    this.playlist_name = '';
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.remove('open');
    const addPlaylistElement = document.getElementsByClassName(
      'create-playlist-name-popup'
    );
    addPlaylistElement[0].classList.remove('open');
  }
  toggleDropdown(_event) {
    this.dropdown = _event.target;
    this.menuClick = true;
  }

  toggleSearchDropdown(_event) {
    this.searchDropdown = _event.target;
    // if (!_event.target.value){
    //   this.searchClick = true;
    // }
  }

  toggleProfile(_event) {
    this.profileDropdown = _event.target;
    this.profileClick = true;
  }

  logout() {
    this.logoutUser();
    this.addOpenProfile = false;
    this.authService.logout(true);
    localStorage.removeItem('couponResponse');
    if (localStorage.getItem('currentLanguage') === 'ar') {
      setTimeout(function(){
        localStorage.setItem('currentLanguage', 'ar');
      },1000);
    } else {
      setTimeout(function(){
        localStorage.setItem('currentLanguage', 'en');
      },1000);
    }
  }

  logoutUser() {
    this.apiService
    .callPostAPI('v2/logoutapi',
    {
      acesstype: 'web',
      'random_key': localStorage.getItem('random_key')
    }, environment.userApiUrl)
    .subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          localStorage.clear();
        }
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }
  closeFirstUser() {
    this.bodyVar.classList.remove('firstuser-live');
    document
      .getElementsByTagName('body')[0]
      .classList.remove('home-demo-content');
    document
      .getElementsByTagName('body')[0]
      .classList.remove('first-user-activated');
    localStorage.setItem('firstTimeUser', 'close');
    this.firstTimeUser = localStorage.getItem('firstTimeUser');
  }
  loginRegister(event) {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: false,
      disableKeys: false
    });
    this.userService.redirectUrl(this.router.url);
    if (event.target.className === 'sign-up') {
      this.onShowHide('sign-up');
    } else {
      this.onShowHide('sign-in');
    }
  }
  go_to_home() {
    this.router.navigate(['home']);
  }
  // Popup show or hide
  onShowHide(value: string) {
    if (value === 'sign-in') {
      this.signIn = true;
      this.signUp = false;
      this.forget = false;
    } else if (value === 'sign-up') {
      this.signIn = false;
      this.signUp = true;
      this.forget = false;
    } else if (value === 'forget') {
      this.signIn = false;
      this.signUp = false;
      this.forget = true;
    } else {
      this.signIn = false;
      this.signUp = false;
      this.forget = false;
    }
  }

  // Close Share Popup
  closeSharePopup(action) {
    disableScroll.off();
    this.link = undefined;
    const elements = document.getElementsByClassName('share-popup');
    if (elements[0]) {
      elements[0].classList.add('close');
      elements[0].classList.remove('open');
    }
    if (action === 'closeshare') {
      const element = document.getElementsByTagName('body');
      if (element[0]) {
        element[0].classList.remove('modal-popup');
      }
    }
    this.shareFlag = false;
    // const elements2 = document.getElementsByClassName('addnew-card-popup');
    // if (elements2[0]) {
    //   elements2[0].classList.remove('open');
    // }
  }
  // Video search
  videoSearch(value: string) {
    const searchValue = document.getElementById('video-search');
    value = searchValue['value'];
    this.searchText = value.length;
    this.searchString = value;
    if (value.length > 0) {
      this.apiService
        .callGetAPI('search?q=' + value, environment.searchApiUrl)
        .subscribe(
          (data) => {
            this.searchFlag++;
            setTimeout(() => {
              this.searchData = data['response'].data;
              this.resultCount = data['response'].total;
              this.search = this.searchData.slice(0, 3);
            }, 1000);
          },
          (error) => {
            this.apiService.failureResponse(error, true);
          }
        );
    } else {
      this.search = [];
    }
  }
  eventHandler(event) {
    if (event.keyCode === 13) {
      if (this.searchValue !== undefined && this.searchValue.length > 1) {
        this.serachChange();
      }
      $('.searchboxEvent').blur();
    }
  }
  // Search video list click
  onVideoClick(video: string) {
    this.search = [];
    const searchValue = document.getElementById('video-search');
    searchValue['value'] = '';
    this.searchText = '';
    this.router.navigate(['video/' + video]);
  }

  // Search video more link click
  onVideoMoreClick() {
    this.search = [];
    const searchValue = document.getElementById('video-search');
    searchValue['value'] = '';
    this.searchText = '';
    this.router.navigate(['/search'], {
      queryParams: { q: this.searchString }
    });
  }

  // get catagory list
  getCategoryList() {
    const currentRoute = this.router.url;
    this.headerService.fetchMainmenuCategories().subscribe(
      (data) => {
        this.categoryLength = data['response'].data;
        this.apiService.categories = data['response'].data;
        this.category = this.categoryLength.slice(0, 4);
        this.liveroute = true;
      },
      (error) => {
        if (currentRoute === '/' || currentRoute === '/home') {
          this.headerService.fetchBanner();
        }
      },
      () => {
        if (currentRoute === '/' || currentRoute === '/home') {
          this.headerService.fetchBanner();
          setTimeout(() => {
            this.getFooterContent();
          }, 2000);
        } else {
          this.getFooterContent();
        }
      }
    );
  }
  getFooterContent() {
    this.headerService.fetchData().subscribe(
      (data) => {
        if (this.user) {
          this.user.notification_count = data['response'].notification_count;
        }
        data['category'] = this.categoryLength;
        // set data in service which is to be shared
        this.headerService.setData(data);
      },
      (error) => {
        this.apiService.failureResponse(error);
      }
    );
  }
  // get playlist list
  getPlaylists() {
    this.apiService
      .callGetAPI(
        'v2/create_playlist?video_id=' +
          this.videoDetails.slug +
          '&page=' +
          this.page,
        environment.userActionApiUrl
      )
      .subscribe(
        (data) => {
          for (const playlists of data['response'].my_playlist.data) {
            this.playlist.push(playlists);
          }
          if (this.playlist.length > 0) {
            document.getElementById('create_playlist').style.borderBottom =
              '1px solid #ccc';
          } else {
            document.getElementById('create_playlist').style.borderBottom =
              '0px solid #ccc';
          }
          this.loading = false;
          this.flag = false;
          if (data['response'].my_playlist.data.length < 10) {
            this.final = true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }
  // add video to playlist
  addVideoToPlaylist(playlist_name, playlist_id, i) {
    this.apiService
      .callPostAPI(
        'v2/create_playlist',
        {
          name: playlist_name,
          videos: this.videoDetails.slug,
          id: playlist_id
        },
        environment.userActionApiUrl
      )
      .subscribe(
        (data) => {
          this.playlist[i].is_added = 1;
          this.apiService.successResponse(data);
        },
        (error) => {
          this.submitted = false;
          this.result = false;
          this.errors = this.apiService.failureResponse(error);
          if (this.errors.commonError) {
            this.result = true;
            this.success = this.errors.commonError;
            this.utilityService.VplyToaster();
          }
        }
      );
  }

  // remove video from playlist
  removeVideoToPlaylist(playlist_id, i) {
    this.apiService
      .callDeleteAPI(
        'v2/create_playlist_videos?playlist_id=' +
          playlist_id +
          '&video_id=' +
          this.videoDetails.slug,
        environment.userActionApiUrl
      )
      .subscribe(
        (data) => {
          this.playlist[i].is_added = 0;
          this.apiService.successResponse(data);
        },
        (error) => {
          this.submitted = false;
          this.result = false;
          this.errors = this.apiService.failureResponse(error);
          if (this.errors.commonError) {
            this.result = true;
            this.success = this.errors.commonError;
            this.utilityService.VplyToaster();
          }
        }
      );
  }
  // add playlist
  addPlaylist(myForm) {
    if (
      this.playlist_name.trim() !== undefined &&
      this.playlist_name.trim() !== ''
    ) {
      this.playlistSubmitted = true;
      this.translate.get('HEADER.PLEASE_WAIT').subscribe((res) => {
        this.buttonText = res;
      });
      this.apiService
        .callPostAPI(
          'v2/create_playlist',
          { name: this.playlist_name },
          environment.userActionApiUrl
        )
        .subscribe(
          (data) => {
            this.playlistSubmitted = false;
            this.playlistForm.reset();
            myForm.resetForm();
            this.result = false;
            this.apiService.successResponse(data);
            const elements = document.getElementsByClassName(
              'create-playlist-popup'
            );
            elements[0].classList.add('open');
            const addPlaylistElement = document.getElementsByClassName(
              'create-playlist-name-popup'
            );
            addPlaylistElement[0].classList.remove('open');
            this.translate.get('HEADER.ADD_PLAYLIST').subscribe((res) => {
              this.buttonText = res;
            });
            this.playlist = [];
            disableScroll.on(undefined, {
              disableWheel: true,
              disableScroll: true,
              disableKeys: false
            });
            this.final = false;
            this.flag = true;
            this.page = 1;
            this.getPlaylists();
          },
          (error) => {
            this.playlistSubmitted = false;
            this.errors = this.apiService.failureResponse(error);
            this.submitted = false;
            this.result = false;
            if (this.errors.commonError) {
              this.result = true;
              this.success = this.errors.commonError;
              this.utilityService.VplyToaster();
              this.translate.get('HEADER.ADD_PLAYLIST').subscribe((res) => {
                this.buttonText = res;
              });
            }
          }
        );
    } else {
      this.result = true;
      this.success = 'Playlist name can not be empty';
      this.utilityService.VplyToaster();
    }
  }
  VplyToasterHide() {
    this.utilityService.VplyToasterHide();
  }
  mouseover() {
    const elements = document.getElementsByClassName('dropdown-menu');
    elements[0].classList.add('open');
    const element = document.getElementsByClassName('mobile-overlay');
    element[0].classList.add('open');
  }

  mouseout() {
    const elements = document.getElementsByClassName('dropdown-menu');
    elements[0].classList.remove('open');
    const element = document.getElementsByClassName('mobile-overlay');
    element[0].classList.remove('open');
  }

  mouseoverProfile() {
    this.addOpenProfile = true;
  }

  mouseoutProfile() {
    this.addOpenProfile = false;
  }

  OpenSearch(event) {
    this.searchBox = event.target;
    document.getElementById('video-search').focus();
    this.searchBoxClick = this.searchBoxClick ? false : true;
  }

  onLive() {
    this.router.navigate(['live']);
  }

  facebookShare(this) {
    FB.ui(
      {
        method: 'share',
        href: `${environment.apiUrl}v2/video-metadata/${this.videoDetails.slug}` // Media Service API
      },
      function (response) {
        // Action after response
        if (response && !response.error_message) {
          // then get post content
          this.apiService.successResponse(
            'successfully posted. Status id : ' + response.post_id
          );
          this.closeSharePopup();
        } else {
          this.apiService.failureResponse('Something went error.');
        }
      }
    );
  }
  twitterShare() {
    // tslint:disable-next-line:max-line-length
    const twitterWindow = window.open(
      'https://twitter.com/intent/tweet?text=' +
        this.videoDetails.title +
        '&url=' +
        window.location.protocol +
        '//' +
        window.location.host +
        '/' +
        this.shareUrlSlug +
        this.videoDetails.slug,
      'twitter-popup',
      'height=350,width=600'
    );
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  }
  googlePlus() {
    const sharelink =
      'https://plus.google.com/share?url=' +
      environment.domain +
      this.shareUrlSlug +
      this.videoDetails.slug;
    const newwindow = window.open(sharelink, 'name', 'height=400,width=600');
    if (window.focus) {
      newwindow.focus();
    }
    return false;
  }
  updateNotifyCount(event) {
    this.notificationClick = false;
  }
  shareEventTarget(event) {
    this.sharePopupTarget = event.target;
  }
  shareTeenEventTarget(event) {
    this.shareTeenPopupTarget = event.target;
  }
  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    if (this.videoDetails.is_live === 2) {
      selBox.value =
      environment.domain + 'radio/' + this.videoDetails.slug;
    } else {
      selBox.value =
      environment.domain + this.shareUrlSlug + this.videoDetails.slug;
    }

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let getMessage;
    this.translate.get('HEADER.SHARE_CLIP_MESSAGE').subscribe((res) => {
      getMessage = res;
    });
    const message = { error: false, message: getMessage, statusCode: 200 };
    this.apiService.successResponse(message);
  }
  embedUrlGenerator() {
    // tslint:disable-next-line:max-line-length
    this.link =
      '<iframe width="560" height="315" src="' +
      environment.domain +
      'embed/' +
      this.videoDetails.slug +
      '" frameborder="0" allowfullscreen></iframe>';
  }
  cancelEmbed() {
    this.link = undefined;
  }
  copyEmbed() {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false
    });
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value =
      '<iframe width="560" height="315" src="' +
      environment.domain +
      'embed/' +
      this.videoDetails.slug +
      '" frameborder="0" allowfullscreen></iframe>';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let getMessage;
    this.translate.get('HEADER.SHARE_CLIP_MESSAGE').subscribe((res) => {
      getMessage = res;
    });
    const message = { error: false, message: getMessage, statusCode: 200 };
    this.apiService.successResponse(message);
  }

  changeLanguage(i) {
    localStorage.setItem('currentLanguage', this.languageList[i].lang);
    this.translate.use(this.languageList[i].lang);
    this.selectedLang = this.languageList.map((_) => false);
    this.selectedLang[i] = true;
    window.location.reload();
  }
  /**
   * Category Navigation
   */
  categoryNavigation(cat) {
    // Navigation based on the web series
    if (cat.is_web_series) {
      this.router.navigate(['series/' + cat.slug]);
    } else {
      this.router.navigate(['category/' + cat.slug]);
    }
  }

  getSelectedlang(i) {
    return this.selectedLang[i];
  }
}
