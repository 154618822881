import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService } from '../../core/services/player.service';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss']
})
export class WatchVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  player: any;
  params: any;
  videos: any;
  live: boolean;
  currentVideos: any;
  fullParams: any;
  x: any;
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private location: PlatformLocation,
    private router: Router,
    private el: ElementRef
  ) {
    this.route.params.subscribe((params) => {
      this.fullParams = params;
      this.params = params['video_id'];
      this.getVideos();
    });
  }

  ngOnInit() {
    document.getElementsByTagName('body')[0].removeAttribute('style');
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'light') {
        document.body.classList.add('main-light');
      }
      if (localStorage.getItem('theme') === 'dark') {
        document.body.classList.add('main-dark');
      }
    } else {
      document.body.classList.add('main-dark');
    }
  }
  ngAfterViewInit() {
    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '#111418';
  }
  videoPlay(event) {
    this.player = event;
  }
  getVideos() {
    const url = 'v2/watchvideo/';
    this.apiService.callGetAPI(url + this.fullParams['video_id']).subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.videos = data['response'];
          this.currentVideos = this.videos;
          this.playerService.videoData(this.videos.videos);
          this.playerService.videoPaymentData(this.videos.payment_info);
          this.apiService
            .callPostAPI(
              'v2/videoanalytics',
              { video_slug: this.videos.videos.slug },
              environment.analyticsApiUrl
            )
            .subscribe();
        }
        if (
          this.videos.videos.scheduledStartTime !== '' &&
          this.videos.videos.scheduledStartTime > new Date().getTime()
        ) {
          if (this.fullParams['playlist_id'] !== undefined) {
            this.router.navigate([
              'video/' +
                this.fullParams['video_id'] +
                '/' +
                this.fullParams['playlist_id'] +
                '/' +
                this.fullParams['name']
            ]);
          } else {
            this.router.navigate(['video/' + this.fullParams['video_id']]);
          }
        }
        if (this.videos.videos.is_premium === 1) {
          if (this.videos.videos.is_subscribed === 0) {
            if (this.fullParams['playlist_id'] !== undefined) {
              this.router.navigate([
                'video/' +
                  this.fullParams['video_id'] +
                  '/' +
                  this.fullParams['playlist_id'] +
                  '/' +
                  this.fullParams['name']
              ]);
            } else if (this.videos.payment_info.is_bought === 1) {
              return this.videos;
            } else {
              this.router.navigate(['video/' + this.fullParams['video_id']]);
            }
          }
        }
      },
      (error) => {
        if (this.fullParams['playlist_id'] !== undefined) {
          this.router.navigate([
            'video/' +
              this.fullParams['video_id'] +
              '/' +
              this.fullParams['playlist_id'] +
              '/' +
              this.fullParams['name']
          ]);
        }
        if (this.fullParams['video_id'] !== undefined) {
          this.router.navigate(['video/' + this.fullParams['video_id']]);
        }
        if (error.status === 404) {
          this.router.navigate(['video-not-found']);
        } else {
          this.apiService.failureResponse(error);
        }
      }
    );
  }
  ngOnDestroy() {
    this.el.nativeElement.ownerDocument.body.style.backgroundColor = '';
  }
}
