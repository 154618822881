import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }
    // Observable User details
    private currentUser = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
    localUser = this.currentUser.asObservable();

    // Observable popup details
    private popup = new BehaviorSubject<any>('close');
    display = this.popup.asObservable();

    // Observable popup details
    private sharePopup = new BehaviorSubject<any>('close');
    displayShare = this.sharePopup.asObservable();


    // Observable popup details
    private url = new BehaviorSubject<any>('home');
    nextPage = this.url.asObservable();

    // user details
    userData(data: any) {
      this.currentUser.next(data);
    }

    // login, register and forget password popup
    displayPopup(data: any) {
      this.popup.next(data);
    }

    // login, register and forget password popup
    displaySharePopup(data: any) {
      this.sharePopup.next(data);
    }

    // redirect next page
    redirectUrl(data: any) {
      this.url.next(data);
    }

    //
}
