import { Component, OnInit, HostListener } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { VideoShareService } from '../../../core/services/video-share.service';
import { PlaylistAddService } from '../../../core/services/playlist-add.service';
import disableScroll from 'disable-scroll';
import { VideoModel } from '../../../modules/video-detail/video.model';
import { ToastrService } from 'ngx-toastr';
declare var $: any;


@Component({
  selector: 'app-webseriesvideos',
  templateUrl: './webseriesvideos.component.html',
  styleUrls: ['./webseriesvideos.component.scss']
})
export class WebseriesvideosComponent implements OnInit {
  tostar: any = {};
  loggedIn: any = {};
  allVideos = [];
  noRadio = false;
  page = 0;
  newandpopularpage = 1;
  radioPage = 1;
  loading = false;
  preloading = false;
  final = false;
  flag = false;
  notFound = false;
  noVideo = '';
  currentSlug: any = "";
  currentType: any = "";
  currentTitle: any = '';


  constructor(private apiService: ApiService, private authService: AuthService,  private router: Router, private videoShareService: VideoShareService, private toasterService: ToastrService, private playlistAddService: PlaylistAddService, private route: ActivatedRoute) { 
    window.scrollTo(0, 0);
    this.route.params.subscribe((params) => {
      this.currentType = params.type
      this.currentSlug = params.slug
    });
   }

  ngOnInit() {
 
    // if (this.currentType === 'home') {
      this.homewebseries();
    // }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if ($(window).scrollTop() + $(window).height() > $('footer').offset().top && this.final === false && this.flag === false) {
        this.loading = true;
        this.flag = true;

        // if (this.currentType === 'home') {
          this.homewebseries();
        // }
    }
  }

  homewebseries() {
    this.page = this.page + 1;
    // this.loading = true;
    this.preloading = true;
    this.apiService.callGetAPI('v2/webseriesListByName/'+this.currentSlug+'?page='+ this.page, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          // console.log(data);
          if (localStorage.getItem('currentLanguage') === 'ar') {
            this.noVideo = 'لم يتم العثور على المحتوى‎';
          } else {
            this.noVideo = 'Sorry! No Video are available';
          }
          this.currentTitle = data['response'].webseries_video_list.webseries_name;
          this.allVideos = this.allVideos.concat(data['response'].webseries_video_list.data);
          this.final = (data['response'].webseries_video_list.next_page_url === null) ? true : false;
          this.loading = false;
          this.preloading = false;
          this.flag = false;
          this.notFound = (this.allVideos.length > 0) ? false : true;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
        this.preloading = false;
      });
  }

  clickRadio(data) {
    const userValues = localStorage.getItem('currentUser');
          if (userValues) {
            const userData = this.authService.decryption(userValues);
            if(userData['response'].is_subscribed === 0) {
              this.router.navigate(['/subscription']);
            } else {
              if(data.is_live === 2){
                this.router.navigate(['/radio/'+data.slug]);
              } else {
                this.router.navigate(['/video/'+data.slug]);
              }
            }
          }
  }

  openShareVideo(data) {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    // this.videoShareService.displayPopup(this.video);
    this.videoShareService.displayPopup(data);

  }

  favourite(video: VideoModel, e) {
    if (sessionStorage.getItem('tostar')) {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': JSON.parse(sessionStorage.getItem('tostar')).tostarTime
      };
    } else {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': 0
      };
    }
    sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      const favourite = !video.is_favourite;
      if (favourite) {
        this.apiService.callPostAPI('v2/favourite', { video_slug: video.slug }, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success('', 'Radio added to favourite');
                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            });
      } else {
        this.apiService.callDeleteAPI('v2/favourite?video_slug=' + video.slug, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success('', 'Radio removed from favourite');

                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            });
      }

    } else {
      // this.userService.redirectUrl(this.router.url);
      // this.userService.displayPopup('sign-in');
    }
  }

  openPlalistPopup(data) {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    this.playlistAddService.displayPopup(data);
  }

}
