import { Component, OnInit } from '@angular/core';
import disableScroll from 'disable-scroll';
import {
  AuthService,
  SocialUser,
  GoogleLoginProvider,
  FacebookLoginProvider
 } from 'angularx-social-login';
import { AuthService as auth } from '../../core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {

  user: SocialUser;
  login = false;
  constructor(
    private authService: AuthService,
    private authLogin: auth,
    private toasterService: ToastrService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() { }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
    .then((response) => {
     this.socailLogin();
    }).catch((error) => {
    });

  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
    .then((response) => {
    this.socailLogin();
    }).catch((error) => {
    });

  }

  signOut(): void {
    this.authService.signOut();
  }

  socailLogin(): void {
    this.login = false;
    this.authService.authState.subscribe((user) => {
      this.user = JSON.parse(JSON.stringify(user));
      if (this.user) {
        if (this.user.id !== undefined && !this.login) {
            this.authLogin.login(this.user)
            .subscribe(
              data => {
                if (data['statusCode'] === 200 && data['error'] === false) {
                  this.login = true;
                  this.userService.displayPopup('close');
                  if (data['response'].name && !localStorage.getItem('currentUser')) {
                    const userData = this.authLogin.encryption(data);
                   // this.asyncLocalStorage.setItem('currentUser', userData).then(function () {
                      this.asyncLocalStorage.setItem('currentUser', userData).then(() => {
                      if (window.location.pathname.indexOf('video') !== -1) {
                        window.location.reload();
                      } else {
                        this.userService.nextPage.subscribe(
                        nextUrl => {
                         this.router.navigate([nextUrl]);
                        });
                      }
                    });
                    disableScroll.off();
                    // this.toasterService.success('Welcome ' + data['response'].name + ', You have logged in.');
                  }
                  this.authService.signOut();
                  }
            },
            error => {
              if (this.user.email) {
                 this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
              } else {
                this.toasterService.error('', 'Update your email id in social network');
              }
            }
          );

        }
      }
    });
  }
  // tslint:disable-next-line:member-ordering
  asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
}
