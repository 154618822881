import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ApiService } from "../../../core/services/api.service";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../core/auth/auth.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { VideoShareService } from "../../../core/services/video-share.service";
import { PlaylistAddService } from "../../../core/services/playlist-add.service";
import disableScroll from "disable-scroll";
import { VideoModel } from "../../../modules/video-detail/video.model";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: "app-seeall",
  templateUrl: "./seeall.component.html",
  styleUrls: ["./seeall.component.scss"],
})
export class SeeallComponent implements OnInit, OnDestroy {
  tostar: any = {};
  loggedIn: any = {};
  allVideos = [];
  noRadio = false;
  page = 0;
  newandpopularpage = 1;
  radioPage = 1;
  loading = false;
  preloading = false;
  final = false;
  flag = false;
  notFound = false;
  noVideo = "Sorry! No Video are available";
  currentSlug: any = "";
  currentType: any = "";
  currentTitle: any = "";
  isWebSeries = 0;
  isDefaultLoader = 0;
  seeallclick: any;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private videoShareService: VideoShareService,
    private toasterService: ToastrService,
    private playlistAddService: PlaylistAddService,
    private route: ActivatedRoute
  ) {
    window.scrollTo(0, 0);
    this.seeallclick = setInterval(() => {
      this.isDefaultLoader = 1;
    }, 10);
    this.route.params.subscribe((params) => {
      // console.log(params);
      this.currentType = params.type;
      this.currentSlug = params.slug;
    });
  }

  ngOnInit() {
    if (this.currentType === "home" && this.currentSlug === "new") {
      this.homepageNew();
    }

    if (this.currentType === "home" && this.currentSlug === "tvshows") {
      this.homepageWebseries();
    }

    if (
      (this.currentType === "kids" && this.currentSlug === "new") ||
      (this.currentType === "kids" && this.currentSlug === "tvshows")
    ) {
      this.kidspageNew();
    }

    if (
      (this.currentType === "home" && this.currentSlug === "playlist") ||
      (this.currentType === "home" && this.currentSlug === "recent")
    ) {
      this.playlistvideo();
    }
    if (this.currentType === "new" || this.currentType === "popular") {
      this.categoryNewPopular();
    }
    if (this.currentType === "kidspopular") {
      this.kidsPopular();
    }
    if (this.currentType === "radios") {
      this.radioCategory();
    }
    if (this.currentType === "related") {
      this.relatedVideo();
    }
    if (this.currentType === "home" && this.currentSlug === "radio") {
      this.getAllRadioRecord();
    }
    if (this.currentType === "home" && this.currentSlug === "live") {
      this.getAllLiveRecord();
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    if (
      $(window).scrollTop() + $(window).height() > $("footer").offset().top &&
      this.final === false &&
      this.flag === false &&
      this.isDefaultLoader === 1
    ) {
      this.loading = true;
      this.flag = true;
      if (this.currentType === "home" && this.currentSlug === "new") {
        this.homepageNew();
      }

      if (this.currentType === "home" && this.currentSlug === "tvshows") {
        this.homepageWebseries();
      }

      if (
        (this.currentType === "kids" && this.currentSlug === "new") ||
        (this.currentType === "kids" && this.currentSlug === "tvshows")
      ) {
        this.kidspageNew();
      }
      if (
        (this.currentType === "home" && this.currentSlug === "playlist") ||
        (this.currentType === "home" && this.currentSlug === "recent")
      ) {
        this.playlistvideo();
      }

      if (this.currentType === "new" || this.currentType === "popular") {
        this.categoryNewPopularLoader();
      }
      if (this.currentType === "kidspopular") {
        this.kidsPopular();
      }
      if (this.currentType === "radios") {
        this.radioCategory();
      }
      if (this.currentType === "related") {
        this.relatedVideo();
      }
      if (this.currentType === "home" && this.currentSlug === "radio") {
        this.radioNextObj();
      }
      if (this.currentType === "home" && this.currentSlug === "live") {
        this.liveNextObj();
      }
    }
  }

  homepageNew() {
    this.page = this.page + 1;
    this.preloading = true;
    this.apiService
      .callGetAPI("v2/home_page_banner?page=" + this.page, environment.apiUrl)
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            if (this.currentType === "home" && this.currentSlug === "new") {
              this.currentTitle = data["response"].new.category_name;
              this.allVideos = this.allVideos.concat(data["response"].new.data);
              this.final =
                data["response"].new.next_page_url === null ? true : false;
            } else if (
              this.currentType === "home" &&
              this.currentSlug === "tvshows"
            ) {
              this.currentTitle = data["response"].home_webseries.category_name;
              this.allVideos = this.allVideos.concat(
                data["response"].home_webseries.data
              );
              this.final =
                data["response"].home_webseries.next_page_url === null
                  ? true
                  : false;
            }

            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  homepageWebseries() {
    this.isWebSeries = 1;
    this.page = this.page + 1;
    this.preloading = true;
    this.apiService
      .callGetAPI(
        "v2/allWebseriesForHome?page=" + this.page,
        environment.apiUrl
      )
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            // if(this.currentType === 'home' && this.currentSlug === 'new') {
            this.currentTitle =
              data["response"].all_webseries_home.category_name;
            this.allVideos = this.allVideos.concat(
              data["response"].all_webseries_home.data
            );
            this.final =
              data["response"].all_webseries_home.next_page_url === null
                ? true
                : false;
            // }

            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  kidspageNew() {
    this.page = this.page + 1;
    this.preloading = true;
    this.apiService
      .callGetAPI(
        "v2/kids_page_banner?page=" +
          this.page +
          "&data_type=" +
          this.currentSlug,
        environment.apiUrl
      )
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            if (this.currentType === "kids" && this.currentSlug === "new") {
              this.currentTitle = data["response"].kidsnew.category_name;
              this.allVideos = this.allVideos.concat(
                data["response"].kidsnew.data
              );
              this.final =
                data["response"].kidsnew.next_page_url === null ? true : false;
            } else if (
              this.currentType === "kids" &&
              this.currentSlug === "tvshows"
            ) {
              this.isWebSeries = 1;
              this.currentTitle = data["response"].kids_webseries.category_name;
              this.allVideos = this.allVideos.concat(
                data["response"].kids_webseries.data
              );
              this.final =
                data["response"].kids_webseries.next_page_url === null
                  ? true
                  : false;
            }

            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  playlistvideo() {
    this.page = this.page + 1;
    this.preloading = true;
    this.apiService
      .callGetAPI("v2/home_page_playlist?page=" + this.page, environment.apiUrl)
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            if (this.currentType === "home" && this.currentSlug === "recent") {
              this.currentTitle = data["response"].recent.category_name;
              this.allVideos = this.allVideos.concat(
                data["response"].recent.data
              );
              this.final =
                data["response"].recent.next_page_url === null ? true : false;
            } else if (
              this.currentType === "home" &&
              this.currentSlug === "playlist"
            ) {
              this.currentTitle = data["response"].playlist.category_name;
              this.allVideos = this.allVideos.concat(
                data["response"].playlist.data
              );
              this.final =
                data["response"].playlist.next_page_url === null ? true : false;
            }

            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  kidsPopular() {
    this.page = this.page + 1;
    // this.loading = true;
    this.preloading = true;
    this.apiService
      .callGetAPI("v2/kids_page?page=" + this.page, environment.apiUrl)
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            var i;
            for (i = 0; i < data["response"].home_content.length; i++) {
              if (
                data["response"].home_content[i].category_slug ===
                this.currentSlug
              ) {
                this.currentTitle =
                  data["response"].home_content[i].category_name;
                this.allVideos = this.allVideos.concat(
                  data["response"].home_content[i].data
                );
                this.final =
                  data["response"].home_content[i].next_page_url === null
                    ? true
                    : false;
                this.loading = false;
                this.preloading = false;
                this.flag = false;
                this.notFound = this.allVideos.length > 0 ? false : true;
              }
            }
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  categoryNewPopular() {
    this.preloading = true;
    this.loading = false;
    this.apiService
      .callGetAPI(
        "v2/home_category_videos?is_web_series=" +
          0 +
          "&category=" +
          this.currentSlug,
        environment.apiUrl
      )
      .subscribe(
        (data) => {
          if (this.currentType === "new") {
            this.allVideos = data["response"].main[0].video_list.data;
            this.currentTitle = data["response"].main[0].title;
          } else if (this.currentType === "popular") {
            this.allVideos = data["response"].main[1].video_list.data;
            this.currentTitle = data["response"].main[1].title;
          }
          this.loading = false;
          this.preloading = false;
          this.flag = false;
          this.notFound = this.allVideos.length > 0 ? false : true;
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  categoryNewPopularLoader() {
    this.newandpopularpage = this.newandpopularpage + 1;
    this.preloading = true;
    this.apiService
      .callGetAPI(
        "v2/more_category_videos?is_web_series=0&type=" +
          this.currentType +
          "&category=" +
          this.currentSlug +
          "&page=" +
          this.newandpopularpage,
        environment.apiUrl
      )
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.allVideos = this.allVideos.concat(
              data["response"].more_category_videos.video_list.data
            );
            this.final =
              data["response"].more_category_videos.video_list.next_page_url ===
              null
                ? true
                : false;
            this.loading = false;
            this.preloading = false;
            this.flag = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  radioCategory() {
    this.page = this.page + 1;
    // this.loading = true;
    this.preloading = true;
    this.apiService
      .callGetAPI(
        "v2/radio_category_videos?page=" + this.page,
        environment.apiUrl
      )
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            var i;
            for (
              i = 0;
              i < data["response"].radio_category_videos.length;
              i++
            ) {
              if (
                data["response"].radio_category_videos[i].slug ===
                this.currentSlug
              ) {
                this.currentTitle =
                  data["response"].radio_category_videos[i].name;
                this.allVideos = this.allVideos.concat(
                  data["response"].radio_category_videos[i].data
                );
                this.final =
                  data["response"].radio_category_videos[i].next_page_url ===
                  null
                    ? true
                    : false;
                this.loading = false;
                this.preloading = false;
                this.flag = false;
                this.notFound = this.allVideos.length > 0 ? false : true;
              }
            }
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  relatedVideo() {
    this.page = this.page + 1;
    // this.loading = true;
    this.preloading = true;
    this.apiService
      .callPostAPI(
        "v2/videos/" + this.currentSlug + "?page=" + this.page,
        environment.apiUrl
      )
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.allVideos = this.allVideos.concat(
              data["response"].related.data
            );
            this.currentTitle = "Related Videos";
            this.final =
              data["response"].related.next_page_url === null ? true : false;
            this.loading = false;
            this.flag = false;
            this.preloading = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
          this.preloading = false;
        }
      );
  }

  clickRadio(data) {
    const userValues = localStorage.getItem("currentUser");
    if (userValues) {
      const userData = this.authService.decryption(userValues);
      if (userData["response"].is_subscribed === 0) {
        this.router.navigate(["/subscription"]);
      } else {
        if (data.is_live === 2) {
          this.router.navigate(["/radio/" + data.slug]);
        } else {
          this.router.navigate(["/video/" + data.slug]);
        }
      }
    }
  }

  clickWebseries(video) {
    // this.router.navigate(['/web-series/' + video.slug]);
    this.router.navigate(["/webseries/" + video.slug]);
  }

  getAllRadioRecord() {
    this.preloading = true;
    this.apiService
      .callGetAPI("v2/allradiorecord", environment.apiUrl)
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.allVideos = data && data["response"].data;
            this.currentTitle = "New On radio";
            this.preloading = false;
            this.loading = false;
            this.flag = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  radioNextObj() {
    this.radioPage++;
    this.apiService
      .callGetAPI("v2/allradiorecord?type=radioslider&page=" + this.radioPage)
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.allVideos = this.allVideos.concat(data["response"].data);
            this.final = data["response"].next_page_url === null ? true : false;
            this.loading = false;
            this.flag = false;
            this.preloading = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }

  getAllLiveRecord() {
    this.preloading = true;
    this.apiService
      .callGetAPI("v2/allliverecord", environment.apiUrl)
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.allVideos = data && data["response"].data;
            this.currentTitle = "New On Live";
            this.preloading = false;
            this.loading = false;
            this.flag = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  liveNextObj() {
    this.radioPage++;
    this.apiService
      .callGetAPI("v2/allliverecord?type=liveslider&page=" + this.radioPage)
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.allVideos = this.allVideos.concat(data["response"].data);
            this.final = data["response"].next_page_url === null ? true : false;
            this.loading = false;
            this.flag = false;
            this.preloading = false;
            this.notFound = this.allVideos.length > 0 ? false : true;
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }

  openShareVideo(data) {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false,
    });
    // this.videoShareService.displayPopup(this.video);
    this.videoShareService.displayPopup(data);
  }

  deleteWatchHistroy(data, e) {
    e.stopPropagation();
    let currentElement = e.target;
    var alreadyFound = false;
    while (currentElement) {
      if (currentElement.tagName == "LI") {
        if (alreadyFound) {
          currentElement.remove();
          break;
        }
        alreadyFound = true;
      }
      currentElement = currentElement.parentElement;
    }

    this.apiService
      .callPostAPI("v2/delete_from_countinue_watching/" + data.id, {})
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.apiService.successResponse(data, "");
          }
        },
        (error) => {
          this.apiService.failureResponse(error, false);
        }
      );
  }

  favourite(video: VideoModel, e) {
    if (sessionStorage.getItem("tostar")) {
      this.tostar = {
        tostarActive: true,
        tostarTime: JSON.parse(sessionStorage.getItem("tostar")).tostarTime,
      };
    } else {
      this.tostar = {
        tostarActive: true,
        tostarTime: 0,
      };
    }
    sessionStorage.setItem("tostar", JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem("currentUser");
    if (this.loggedIn) {
      const favourite = !video.is_favourite;
      if (favourite) {
        this.apiService
          .callPostAPI(
            "v2/favourite",
            { video_slug: video.slug },
            environment.userActionApiUrl
          )
          .subscribe(
            (data) => {
              if (data["statusCode"] === 200 && data["error"] === false) {
                if (
                  this.tostar.tostarTime === 0 ||
                  (this.tostar.tostarActive &&
                    (Date.now() - this.tostar.tostarTime) / 1000 >= 5)
                ) {
                  this.tostar = {
                    tostarActive: false,
                    tostarTime: Date.now(),
                  };
                  sessionStorage.setItem("tostar", JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success("", "Radio added to favourite");
                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            (error) => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            }
          );
      } else {
        this.apiService
          .callDeleteAPI(
            "v2/favourite?video_slug=" + video.slug,
            environment.userActionApiUrl
          )
          .subscribe(
            (data) => {
              if (data["statusCode"] === 200 && data["error"] === false) {
                if (
                  this.tostar.tostarTime === 0 ||
                  (this.tostar.tostarActive &&
                    (Date.now() - this.tostar.tostarTime) / 1000 >= 5)
                ) {
                  this.tostar = {
                    tostarActive: false,
                    tostarTime: Date.now(),
                  };
                  sessionStorage.setItem("tostar", JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success(
                    "",
                    "Radio removed from favourite"
                  );
                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            (error) => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            }
          );
      }
    } else {
      // this.userService.redirectUrl(this.router.url);
      // this.userService.displayPopup('sign-in');
    }
  }

  openPlalistPopup(data) {
    disableScroll.on(undefined, {
      disableWheel: true,
      disableScroll: true,
      disableKeys: false,
    });
    this.playlistAddService.displayPopup(data);
  }

  ngOnDestroy() {
    if (this.seeallclick) {
      clearInterval(this.seeallclick);
    }
  }
}
