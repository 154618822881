import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, Input} from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { Router } from '@angular/router';
import disableScroll from 'disable-scroll';
import { environment } from '../../../../environments/environment';
import { VideoModel } from '../../../modules/video-detail/video.model';
import { ToastrService } from 'ngx-toastr';
import {DomSanitizer} from '@angular/platform-browser';
import radiolistplay from '../../../../assets/js/radiolistplay.es.js';
import { BrowserService } from '../../../core/services/browser.service';

declare var $: any;

@Component({
  selector: 'app-radio-detail',
  templateUrl: './radio-detail.component.html',
  styleUrls: ['./radio-detail.component.scss']
})
export class RadioDetailComponent implements OnInit, OnDestroy {
  @Input() public src: string;
  @Input() public autoplay: boolean = true;
  @Input() public showStateLabel: boolean = true;
  public audioStateLabel = 'Audio sample';
  @Input() public volume: number = 1.0; /* 1.0 is loudest */
  @ViewChild('audioElement') public _audioRef:  ElementRef;
  private audio: HTMLMediaElement;
  muted: boolean = false;

  liveNotFound: Boolean;
  comingsoon = 1;
  live: any = [];
  todaysLive: any;
  upcomingLive: any = [];
  noVideo = 'Sorry! no videos';
  videoCategory: any = 'live';
  bannerData: any = [];
  bannerImage: boolean;
  home: any;
  new: any;
  bannerLive: any = [];
  loading: Boolean;
  thumbnailLoading: Boolean = true;
  load:any;
  loadingSlider: any = [];
  liveVideoList = [];
  player: any;
  showTitle = true;
  isPlayingVar = [];
  videoTitle: string;
  headerVar: any;
  leftBanner: any;
  rightBanner: any;
  pageDestroyed = false;
  moveInOutCards = false;
  moveOutCards = true;
  mouseOverHeader = false;
  playerActiveInactive = false;
  isVideoPlaying = false;
  isRTL: Boolean;
  loadtype: Boolean;
  notFound: Boolean = false;
  liveCategory = [];
  allRadioFavourite = [];
  liveRadioChannel = [];
  RadioFavouriteChannel = [];
  nextFovouriteVideoPage = 1;
  nextaaFovouriteVideoPage = 1;
  nextCategoryVideoPage = 1;
  categoryId = 0;
  categorySlug = '';
  categoryNextPage: Boolean = true;
  fovouritesNextPage: Boolean = true;
  tostar: any = {};
  loggedIn: any = {};
  currentVideo: any;
  random: any;
  random1: any;
  selectedIndex: number;
  categoryTitle: any;
  livecategoriesVideos: any = [];
  sectionPage: any = [];
  sectionNextPage: any = [];
  dataCount: any = [];
  isAll: Boolean = true;
  isFavourite: Boolean = false;
  isCategory: Boolean = false;
  final = false;
  flag = false;
  nextPage = 0;
  playlistpage = 0;
  nextPageScroll = 4;
  countdown: any;
  x: any;
  timer = false;
  slidreOpen: boolean = false;
  allDatas: any = {};
  page = 1;
  newNextPage: Boolean = true;
  channelActive = 'allChannel';
  seeallclick: any;
  defaultCategoryId: any;
  defaultActive = 0;
  currentUrl: any;
  liveURL: any;
  isColor: boolean = false;
  allFavouriteLength = 0;
  notFoundAllFavouriteRadios = 0;
  lastCategoryLength: any;

  constructor(private apiService: ApiService, private router: Router, private toasterService: ToastrService, private sanitizer: DomSanitizer, private browserService: BrowserService ) {
    this.getFavouriteCount();
    this.loadingSlider = [];
    for (let i = 0; i <= 6; i++) {
      this.loadingSlider.push({ thumbnail_image: environment.defaultImage });
    }
  }

  public pause(): void {
    if (this.audio) {
      this.audio.pause();
      this.audioStateLabel = 'Paused';
      console.log("pause");
      this.isColor = false;
    }
  }

  public get paused(): boolean {
    if (this.audio) {
      return this.audio.paused;
    } else {
      return true;
    }
  }

  public play(): void {
    if (this.audio) {
      console.log("play");
      this.isColor = true;
      this.audio.play();
    } else {
      console.log('error');
    }

  }

  mute() {
    if (this.audio) {
      this.audio.volume = 0;
      this.muted = true;
      this.audio.autoplay = true;
    }
  }

  unmute() {
    if (this.audio) {
      this.audio.volume = this.volume;
      this.muted = false;

    }
  }

  setVolume(value){
    if (this.audio) {
      this.volume = value
      this.audio.volume = value;
      this.muted = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosY = window.pageYOffset || document.body.scrollTop;
    this.moveInOutCards = false;

    if (scrollPosY > 80) {
      this.headerVar.classList.remove('live-header');
      this.headerVar.classList.add('show-header');
      this.headerVar.classList.remove('hide-header');
    } else {
      if (this.isVideoPlaying) {
        this.headerVar.classList.remove('show-header');
        this.headerVar.classList.add('hide-header');
      }
      this.headerVar.classList.add('live-header');
    }
    this.loading = false;
    if ($(window).scrollTop() + $(window).height() > $('footer').offset().top && this.final === false && this.flag === false) {
      this.loading = true;
      this.flag = true;
      // this.scrollCategoryVideos();
    }
  }

  ngOnInit() {
    this.browserService.scrollToTop();
    // this.loading = true;
    this.getLiveVideos();
    this.headerVar = document.getElementsByTagName('header')[0];
    this.headerVar.addEventListener('mouseover', () => {
      this.mouseOverHeader = true;
    });
    this.headerVar.addEventListener('mouseleave', () => {
      this.mouseOverHeader = false;
    });
    this.headerVar.classList.add('home-header');
    this.headerVar.classList.add('live-header');

  }

  ngAfterViewInit() {
 
    this.audio = this._audioRef.nativeElement;
    if (this.audio) {
      this.audio.volume = this.volume;
      this.audio.autoplay = false;
      this.muted = false;
    }
    this.allLiveCategory();
}

  getFavouriteCount() {
    this.apiService.callGetAPI('v2/allRadioFavouriteCount', environment.apiUrl).subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {  
          // console.log(typeof data['response'].allRadioFavouriteCount);
          // this.allFavouriteLength = data['response'].allRadioFavouriteCount;
          this.allFavouriteLength = 1;
          // console.log(this.allFavouriteLength);
        }
      });
  }

  allLiveCategory() {
    this.apiService.callGetAPI('v2/allRadioCategoryWeb', environment.apiUrl).subscribe(
    data => {
      if (data['statusCode'] === 200 && data['error'] === false) {  
        this.liveCategory = data['response'].radio_category;
        this.lastCategoryLength = data && data['response'].radio_category.length-1;
        this.defaultCategoryId = this.liveCategory[this.lastCategoryLength].id;
        // console.log(data['response'].radio_category.length-1); 
        this.categoryId = this.defaultCategoryId;
        this.firstCategoryVideos(this.defaultCategoryId);
      }
    });
  }
  

  allliVideo() {
    this.isAll = true;
    this.isCategory = false;
    this.isFavourite = false;
    this.channelActive = 'allChannel';
  }

  clickCategory(category, index: number) {
    this.channelActive = 'categoryChannel';
    this.notFoundAllFavouriteRadios = 0;
    this.loading = true;
    this.defaultActive = 1;
    this.isAll = false;
    this.isFavourite = false;
    this.isCategory = true;
    this.selectedIndex = index;
    this.liveRadioChannel = [];
    this.RadioFavouriteChannel = [];
    this.categoryId = category.id;
    this.categorySlug = category.slug;
    this.nextCategoryVideoPage = 1;
    this.nextFovouriteVideoPage = 1;
    this.apiService.callGetAPI('v2/getRadioCategory/'+category.id, environment.apiUrl).subscribe(
    (data) => {
      if (data['statusCode'] === 200 && data['error'] === false) { 
        this.random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
        this.liveRadioChannel = data['response'].liveRadioChannel;
        // this.RadioFavouriteChannel = data['response'].RadioFavouriteChannel;
        this.loading = false;
      }
    },
    (error) => {
      this.apiService.failureResponse(error);
      this.loading = false;
    });
  }

  firstCategoryVideos(categoryId) {
    this.channelActive = 'categoryChannel';
    this.loading = true;
    this.isAll = false;
    this.isFavourite = false;
    this.isCategory = true;
    this.liveRadioChannel = [];
    this.RadioFavouriteChannel = [];
    this.apiService.callGetAPI('v2/getRadioCategory/'+categoryId, environment.apiUrl).subscribe(
    (data) => {
      if (data['statusCode'] === 200 && data['error'] === false) { 
        this.random = Math.floor(Math.random() * (999999 - 100000)) + 100000;
        this.liveRadioChannel = data['response'].liveRadioChannel;
        // this.RadioFavouriteChannel = data['response'].RadioFavouriteChannel;
        this.loading = false;
      }
    },
    (error) => {
      this.apiService.failureResponse(error);
      this.loading = false;
    });
  }

  next(url) {
    if (url === 'radiocategory') {
      this.nextCategoryVideo();
    } else if (url === 'radiofavourite') { 
      this.nextFovouriteVideo();
    }  else if (url === 'allradiofavourite') { 
      this.nextallFovouriteVideo();
    }
  }

  nextCategoryVideo() {
    // this.nextCategoryVideoPage = 1;
    this.nextCategoryVideoPage++;
    if (!(this.liveRadioChannel['data'].length === this.liveRadioChannel['total'])) {
      this.apiService
        .callGetAPI('v2/getRadioCategory/'+this.categoryId+'?page=' + this.nextCategoryVideoPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].liveRadioChannel.total === this.liveRadioChannel['data'].length) {
                this.categoryNextPage = false;
              }
              data['response'].liveRadioChannel.data.forEach((value) => {
                this.liveRadioChannel['data'].push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }


  nextFovouriteVideo() {
    // this.nextFovouriteVideoPage = 1;
    this.nextFovouriteVideoPage++;
    if (!(this.RadioFavouriteChannel['data'].length === this.RadioFavouriteChannel['total'])) {
      this.apiService
        .callGetAPI('v2/getRadioCategory/'+this.categoryId+'?page=' + this.nextFovouriteVideoPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].RadioFavouriteChannel.total === this.RadioFavouriteChannel['data'].length) {
                this.fovouritesNextPage = false;
              }
              data['response'].RadioFavouriteChannel.data.forEach((value) => {
                this.RadioFavouriteChannel['data'].push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  nextallFovouriteVideo() {
    // this.nextaaFovouriteVideoPage = 1;
    this.nextaaFovouriteVideoPage++;
    if (!(this.allRadioFavourite['data'].length === this.allRadioFavourite['total'])) {
      this.apiService
        .callGetAPI('v2/allRadioFavouriteForWeb?page=' + this.nextaaFovouriteVideoPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].allRadioFavourite.total === this.allRadioFavourite['data'].length) {
                this.fovouritesNextPage = false;
              }
              data['response'].allRadioFavourite.data.forEach((value) => {
                this.allRadioFavourite['data'].push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }


  // changeCat(type) {
  //   this.videoCategory = type;
  // }


  getLiveVideos() {
    this.apiService.callGetAPI('v2/liveRadiosForWeb', environment.apiUrl).subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.liveVideoList = data['response'].current_radio_channel;
          this.live = data['response'].current_radio_channel;
          this.currentVideo = data['response'].current_radio_channel[0];
          this.loading = false;
          this.thumbnailLoading = false;
          if (this.live.length) {

            // this.liveURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.currentVideo.hls_playlist_url);

            this.liveURL = this.currentVideo.hls_playlist_url;
          
            // var lastUrl = this.liveURL.changingThisBreaksApplicationSecurity.substr(this.liveURL.changingThisBreaksApplicationSecurity.length - 3); // => "mp3"

            var lastUrl = this.liveURL.substr(this.liveURL.length - 3); // => "mp3"


           if( lastUrl === '3u8') {
            this.currentUrl = 'application/x-mpegURL'
          } else if(lastUrl === 'm3u') {
            this.currentUrl = 'audio/x-mpegURL';
          } else if(lastUrl === 'mp3') {
            this.currentUrl = 'audio/mpeg';
          } else if(lastUrl === 'aac') {
            this.currentUrl = 'audio/aac';
          } else if(lastUrl === 'oga' || lastUrl === 'ogg') {
            this.currentUrl = 'audio/ogg';
          } else {
            this.currentUrl = 'audio/mpeg';
          }
          const getVideo = document.getElementById('videos');
          this.player = radiolistplay(getVideo, {
            controls: false,
            autoplay: false,
            preload: 'auto',
            fluid: false,
            bigPlayButton: false,
            // LoadingSpinner:false,
            srcType: 'audio',
            // inactivityTimeout: 50,
            'sources': [{
                'src': this.liveURL,
                'type': this.currentUrl,
            }]
          });
            this.liveNotFound = false;
          } else {
            this.liveNotFound = true;
          }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      }
    );
  }
  
  videoCardClickedLive(video) {
    // console.log(video);
    this.isColor = true;
    this.currentVideo = video;
    this.thumbnailLoading = false;
    this.browserService.scrollToTop();

    if(video.is_subscribed === 1) {

      this.audio.pause();
      this.audio.volume = this.volume;
      this.muted = false;
      

      // this.liveURL = this.sanitizer.bypassSecurityTrustResourceUrl(video.hls_playlist_url);

      this.liveURL = this.currentVideo.hls_playlist_url;
          
      // var lastUrl = this.liveURL.changingThisBreaksApplicationSecurity.substr(this.liveURL.changingThisBreaksApplicationSecurity.length - 3); // => "mp3"

      var lastUrl = this.liveURL.substr(this.liveURL.length - 3); // => "mp3"


        if( lastUrl === '3u8') {
          this.currentUrl = 'application/x-mpegURL'
        } else if(lastUrl === 'm3u') {
          this.currentUrl = 'audio/x-mpegURL';
        } else if(lastUrl === 'mp3') {
          this.currentUrl = 'audio/mpeg';
        } else if(lastUrl === 'aac') {
          this.currentUrl = 'audio/aac';
        } else if(lastUrl === 'oga' || lastUrl === 'ogg') {
          this.currentUrl = 'audio/ogg';
        } else {
          this.currentUrl = 'audio/mpeg';
        }
      this.player.src({
        // src: data['response'][0].hls_playlist_url
        src: this.liveURL,
        type: this.currentUrl,
      });
      this.player.play();

    } else {
      this.router.navigate(['subscription']);
    }
  }

  myfavourite(video: VideoModel, e, categoryId) {
    if (sessionStorage.getItem('tostar')) {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': JSON.parse(sessionStorage.getItem('tostar')).tostarTime
      };
    } else {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': 0
      };
    }
    sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      const favourite = !video.is_favourite;
      if (favourite) {
        this.apiService.callPostAPI('v2/favourite', { video_slug: video.slug }, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.toasterService.success('', 'Video added to favourite');
                }
                video.is_favourite = favourite;

                // update myfavourites
                if(categoryId != 0) {
                  this.updateMyFavourite(categoryId);
                  this.clickFavouriteChannel();
                }

              }
            },
            error => {
              this.apiService.failureResponse(error, false);
            });
      } else {
        this.apiService.callDeleteAPI('v2/favourite?video_slug=' + video.slug, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  this.toasterService.success('', 'Video removed from favourite');

                }
                video.is_favourite = favourite;
                // update myfavourites
                if(categoryId != 0) {
                  this.updateMyFavourite(categoryId);
                  this.clickFavouriteChannel();
                }
                
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
            });
      }

    } else {
    }
  }

  updateMyFavourite(categoryId) {
    this.apiService.callGetAPI('v2/getRadioCategory/'+categoryId, environment.apiUrl).subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {  
          this.liveRadioChannel = data['response'].liveRadioChannel;
          this.RadioFavouriteChannel = data['response'].RadioFavouriteChannel;
        }
      });
  }

  getlivecategoryVideos() {
    this.nextPage++;
    this.load = false;
    this.loading = true;
    this.isAll = true;
    this.isCategory = false;
    this.isFavourite = false;
      this.apiService.callGetAPI('v2/live_category_video?category_page=' + this.nextPage)
        .subscribe(
          data => {
            this.getLiveAfterData(data);
            this.loading = false;
          },
          error => {
            this.apiService.failureResponse(error, true);
            this.loading = false;
          });
   }

    getLiveAfterData(data) {
      if (data['statusCode'] === 200 && data['error'] === false) {
        // console.log(data); 
          let index = 0;
       for (const datas of data.response.live_category_videos) {
         this.livecategoriesVideos.push(datas);
         this.sectionPage.push(1);
         if (datas.total === this.livecategoriesVideos[index].data.length) {
           this.sectionNextPage[index] = false;
         } else {
           this.sectionNextPage[index] = true;
         }
         index++;
       }
       this.load = true;
      }
    }
 


    sectionNext(section, index, slug) {
      // if (this.sectionNextPage[index] === true) {
        this.sectionPage[index] += 1;
        this.apiService
          .callGetAPI(
            'v2/home_more?type=' + section + '&slug=' + slug + '&page=' + this.sectionPage[index]
          )
          .subscribe(
            (data) => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                data['response'].data.forEach((value) => {
                  this.livecategoriesVideos[index].data.push(value);
                });
                if (
                  data['response'].total ===
                  this.livecategoriesVideos[index].data.length
                ) {
                  this.sectionNextPage[index] = false;
                }
              }
            },
            (error) => {
              this.apiService.failureResponse(error);
            }
          );
      // }
    }
  
    scrollCategoryVideos() {
      // console.log('scroll');
      this.load = false;
      this.loading = true;
      this.nextPage++;
      this.apiService.callGetAPI('v2/live_category_video?category_page=' + this.nextPage)
        .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                  let index = 0;
                  for (const datas of data['response'].live_category_videos) {
                    this.livecategoriesVideos.push(datas);
                    this.sectionPage.push(1);
                    if (datas.total === this.livecategoriesVideos[index].data.length) {
                      this.sectionNextPage[index] = false;
                    } else {
                      this.sectionNextPage[index] = true;
                    }
                    index++;
                  }
                  this.final = (this.nextPage === data['response'].total_category_count) ? true : false;
                  this.flag = false;
                  this.loading = false;
            }
            },
            error => {
              this.apiService.failureResponse(error, true);
              this.loading = false;
            });
    }


    clickFavouriteChannel() {
      this.channelActive = 'allFavouriteChannel';
      this.loading = true;
      this.isAll = false;
      this.isCategory = false;
      this.isFavourite = true;
      this.nextCategoryVideoPage = 1;
      this.nextFovouriteVideoPage = 1;
        this.apiService
          .callGetAPI('v2/allRadioFavouriteForWeb', environment.apiUrl)
          .subscribe(
            (data) => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                // console.log(data['response'].allRadioFavourite.data.length);
                if (data['response'].allRadioFavourite.data.length === 0) {
                  this.notFoundAllFavouriteRadios = 1;
                } else {
                  this.notFoundAllFavouriteRadios = 0;
                }
                this.allRadioFavourite = data['response'].allRadioFavourite;
                this.loading = false;
              }
            },
            (error) => {
              this.apiService.failureResponse(error);
              this.loading = false;
            }
          );
    }


  seeAll(live, category, data) {
    window.scrollTo(0, 0);

    // this.seeallclick = setInterval(() => {
      if(category === 'listcategory'){
        this.router.navigate(['/seeall/'+live +'/'+'livecategory'+'/'+data.category_id]);
      } else if (category === 'allradiofavourite') {
        this.router.navigate(['/seeall/'+live +'/'+category+'/allvideo']);
      } else {
        this.router.navigate(['/seeall/'+live +'/'+category+'/'+this.categoryId]);
      }
    // }, 10);

  }
 
  ngOnDestroy() {
    this.pageDestroyed = true;
    this.headerVar.style.display = 'block';
    this.headerVar.classList.remove('hide-header');
    this.headerVar.classList.remove('show-header');
    this.headerVar.classList.remove('live-header');
    disableScroll.off();
    if (this.seeallclick) {
      clearInterval(this.seeallclick);
    }
  }

}
