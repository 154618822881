import {
  Component,
  OnInit,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild
} from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import disableScroll from 'disable-scroll';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthService } from '../../core/auth/auth.service';
import { VideoShareService } from '../../core/services/video-share.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user.service';
import { BrowserService } from '../../core/services/browser.service';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentInit {
  trending: any = [];
  new: any = [];
  recent: any = [];
  playlist: any = [];
  webseries: any = [];
  banner: any = [];
  section: any = [];
  genre: any = [];
  home: any = [];
  routeSub: any;
  load: Boolean;
  defaultOwlCarousel: any = [];
  scrollEvent = true;
  viewPortEnable: Boolean = false;
  moved: any = 7;
  page = 1;
  playlistpage = 1;
  recentpage = 1;
  radioPage = 1;
  livePage = 1;
  newNextPage: Boolean = true;
  trendingNextPage: Boolean = true;
  webseriesNextPage: Boolean = true;
  previousPage: Boolean = false;
  sliderBox: any;
  nextMove: any;
  activeItem: any;
  ItemNumber = 7;
  trendingPage = 1;
  webseriesPage = 1;
  loadingSlider: any = [];
  sectionPage: any = [];
  sectionNextPage: any = [];
  categoriesVideos: any = [];
  search: any = false;
  homeSection: Boolean = true;
  site_link: any = [];
  getHomeCategory;
  getRecommendedVideos;
  currentDecryptUser: any;
  sliderMovedData: any;
  sliderRowItem: any;
  error: any = {};
  footerBanner: any;
  isShow: Boolean = false;
  allRadio: any = {};
  allLive: any = {};
  scrollLoading = 0;
  seeallclick: any;

  final = false;
  flag = false;
  nextPage = 0;
  loading: Boolean;
  livecategoriesVideos: any = [];
  accessRestricted = false;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.homeSection === false) {
      this.homeSection = true;
      if (!this.getHomeCategory) {
        // this.getHome();
        this.getRecent();
        this.getNewOnWebSeries();
      }
    }
    if ($(window).scrollTop() + $(window).height() > $('footer').offset().top && this.final === false && this.flag === false) {
      this.loading = true;
      this.flag = true;
      this.scrollCategoryVideos(); 
    }
  }

  constructor(
    private apiService: ApiService,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthService,
    private videoShareService: VideoShareService,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private userService: UserService,
    private browserService: BrowserService
  ) {
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }
  ngOnInit() {
    this.browserService.scrollToTop();
    this.route.params.subscribe((params) => {
      if (params && params['token']) {
        this.activateUser(params['token']);
      }
    });
    this.currentDecryptUser = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    if(this.currentDecryptUser['response'] && this.currentDecryptUser['response'].is_restricted == 1) {
      this.accessRestricted = true;
    }
    // this.getRecommendedVideosMethod();
    this.getHomeCategory = this.headerService.getHomeCategory();
    this.genre.data = [];
    this.new.data = [];
    this.recent.data = [];
    this.webseries.data = [];
    this.categoriesVideos = [];
    this.sectionPage = [];
    this.sectionNextPage = [];
    this.trending.data = [];
    this.loadingSlider.data = [];
    for (let i = 0; i <= 6; i++) {
      this.loadingSlider.data.push({
        thumbnail_image: environment.defaultImage
      });
    }
    this.getSiteLinks();

    this.headerService.bannerData$.subscribe((data) => {
      this.banner = data;
      if (this.banner === null) {
        this.headerService.fetchBanner();
      }
    });
    this.headerService.newData$.subscribe((data) => {
      this.new = data;
    });

    this.homeSection = false;
    if (this.getHomeCategory) {
      this.getHomeAfterData(this.getHomeCategory);
    }
  }
  ngAfterContentInit() {
    this.videoShareService.sliderMovedItems.subscribe((data) => {
      this.sliderMovedData = data;
    });
    this.videoShareService.sliderRowItemsCount.subscribe((data) => {
      this.sliderRowItem = data;
    });
    this.getBanner();
  }
  getHome() {
    this.load = false;
    this.apiService.callGetAPI('v2/home_page').subscribe(
      (data) => {
        this.headerService.setHomeCategory(data);
        this.getHomeAfterData(data);
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }


  getRecent() {
    this.scrollLoading = 1;
    this.apiService.callGetAPI('v2/home_page_playlist').subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.scrollLoading = 0;
            this.recent = data['response'].recent;
            this.playlist = data['response'].playlist;
        }
      },
      (error) => {
        this.scrollLoading = 0;
        this.apiService.failureResponse(error, true);
      }
    );
  }

  getNewOnWebSeries() {
    this.scrollLoading = 1;
    this.apiService.callGetAPI('v2/allWebseriesForHome').subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.scrollLoading = 0;
            this.webseries = data['response'].all_webseries_home;
        }
      },
      (error) => {
        this.scrollLoading = 0;
        this.apiService.failureResponse(error, true);
      }
    );
  }

  getBanner() {
    this.apiService.callGetAPI('v2/footerbanner').subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          if(data && data['response'].length !== 0) {
            this.isShow = true;
            this.footerBanner = data && data['response'][0].home_url;
          }
        }
      },
      (error) => {
        this.apiService.failureResponse(error, true);
      }
    );
  }

  getHomeAfterData(data) {
    if (data['statusCode'] === 200 && data['error'] === false) {
      let index = 0;
      for (const datas of data['response'].home_content) {
        this.categoriesVideos.push(datas);
        this.sectionPage.push(1);
        if (datas.total === this.categoriesVideos[index].data.length) {
          this.sectionNextPage[index] = false;
        } else {
          this.sectionNextPage[index] = true;
        }
        index++;
      }
      this.load = true;
    }
  }

  next(url) {
    // console.log(url);
    if (url === 'new') {
      this.nextObj();
    } else if (url === 'home_webseries') { 
      this.nextWebseries();
    } else if (url === 'trending') {
      this.nextObjTrending();
    } else if (url === 'radioslider') {
      this.radioNextObj();
    } else if (url === 'liveslider') {
      this.liveNextObj();
    } else if(url === 'recent') {
      this.recentNextObj();
    }  else if(url === 'playlist') {
      this.playListNextObj();
    } else if(url === 'special_category_slider') {

    }
  }

  specialCategoryNext(section, index, slug) {
    // if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_more?type=' + section + '&slug=' + slug + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.livecategoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.livecategoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    // }
  }


  playListNextObj() {
    this.playlistpage++;
    if (!(this.playlist.data.length === this.playlist.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=playlist&page=' + this.playlistpage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.playlist.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.playlist.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  recentNextObj() {
    this.recentpage++;
    if (!(this.recent.data.length === this.recent.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=recent&page=' + this.recentpage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.recent.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.recent.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }
  

  nextObj() {
    this.page++;
    if (!(this.new.data.length === this.new.total)) {
      this.apiService
        .callGetAPI('v2/home_more?type=new&page=' + this.page)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.new.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.new.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    } else {
    }
  }

  nextWebseries() {
    this.webseriesPage++;
    // if (!(this.webseries.data.length === this.webseries.total)) {
      this.apiService
        .callGetAPI('v2/allWebseriesForHome?page=' + this.webseriesPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].all_webseries_home.total === this.webseries.data.length) {
                this.webseriesNextPage = false;
              }
              data['response'].all_webseries_home.data.forEach((value) => {
                this.webseries.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    // } else {
    // }
  }

  nextObjTrending() {
    this.trendingPage++;
    if (this.trendingNextPage) {
      this.apiService
        .callGetAPI('v2/home_more?type=trending&page=' + this.trendingPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.trending.data.length) {
                this.trendingNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.trending.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }
  sectionNext(section, index) {
    if (this.sectionNextPage[index] === true) {
      this.sectionPage[index] += 1;
      this.apiService
        .callGetAPI(
          'v2/home_more?type=' + section + '&page=' + this.sectionPage[index]
        )
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              data['response'].data.forEach((value) => {
                this.categoriesVideos[index].data.push(value);
              });
              if (
                data['response'].total ===
                this.categoriesVideos[index].data.length
              ) {
                this.sectionNextPage[index] = false;
              }
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
    }
  }

  getSiteLinks() {
    this.headerService.apiData$.subscribe((data) => {
      if (data !== null) {
        this.site_link = data['response'].site_link;
      }
    });
    if (this.site_link === null) {
      setTimeout(() => {
        this.getSiteLinks();
      }, 1000);
    }
  }


  google_play_clicked() {
    if (this.site_link.android_app_link) {
      const a = document.createElement('a');
      a.href = this.site_link.android_app_link
        ? this.site_link.android_app_link
        : undefined;
      a.click();
    }
  }
  activateUser(key) {
    this.apiService
      .callPostAPI(
        'v2/auth/check_activation_code',
        {
          login_type: 'normal',
          acesstype: 'web',
          key: key
        },
        environment.userApiUrl
      )
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            this.userService.displayPopup('sign-in');
            this.toasterService.success('', data['message']);
            disableScroll.off();
            this.router.navigate(['/home']);
          }
        },
        (error) => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error('', this.error);
            this.router.navigate(['/home']);
          } else {
            this.error = {};
            this.toasterService.error(
              '',
              error.error.message ? error.error.message : error.message
            );
            this.router.navigate(['/home']);
          }
        }
      );
  }

  getAllRadioRecord() {
    this.apiService.callGetAPI('v2/allradiorecord', environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.allRadio = data && data['response'];
          console.log(this.allRadio); 
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });
  }

  radioNextObj() {
    this.radioPage++;
      this.apiService
        .callGetAPI('v2/allradiorecord?type=radioslider&page=' + this.radioPage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.allRadio.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.allRadio.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
  }

  getAllLiveRecord() {
    this.apiService.callGetAPI('v2/allliverecord', environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.allLive = data && data['response'];
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });
  }

  liveNextObj() {
    this.livePage++;
      this.apiService
        .callGetAPI('v2/allliverecord?type=liveslider&page=' + this.livePage)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.allLive.data.length) {
                this.newNextPage = false;
              }
              data['response'].data.forEach((value) => {
                this.allLive.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
  }

  scrollCategoryVideos() {
    // console.log('scroll');
    this.load = false;
    this.loading = true;
    this.nextPage++;
    this.apiService.callGetAPI('v2/special_category_video?category_page=' + this.nextPage)
      .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
                let index = 0;
                for (const datas of data['response'].special_category_videos) {
                  this.livecategoriesVideos.push(datas);
                  this.sectionPage.push(1);
                  if (datas.total === this.livecategoriesVideos[index].data.length) {
                    this.sectionNextPage[index] = false;
                  } else {
                    this.sectionNextPage[index] = true;
                  }
                  index++;
                }
                this.final = (this.nextPage === data['response'].special_category_count) ? true : false;
                this.flag = false;
                this.loading = false;
          }
          },
          error => {
            this.apiService.failureResponse(error, true);
            this.loading = false;
          });
  }



  seeAll(type, slug) {
    window.scrollTo(0, 0);
    // this.seeallclick = setInterval(() => {
      this.router.navigate(['/seeall/'+ type + '/' + slug]);
    // }, 10);
  }

  seeAllPopular(popular) {
    window.scrollTo(0, 0);
    // this.seeallclick = setInterval(() => {
      if (popular.type === 'radio_slider' || popular.type === 'live_slider') {
        this.router.navigate(['/seeall/home/' + popular.category_slug]);
      } else {
        this.router.navigate(['/seeall/popular/' + popular.category_slug]);
      }
    // }, 10);
    
  }

  seeAllSpecialCategory(live, category, data) {
    window.scrollTo(0, 0);
    // this.seeallclick = setInterval(() => {
      this.router.navigate(['/seeall/'+live +'/'+'specialcategory'+'/'+data.category_id]);
    // }, 10);

  }

  ngOnDestroy() {
    disableScroll.off();
    this.routeSub.unsubscribe();
    if (this.seeallclick) {
      clearInterval(this.seeallclick);
    }
  }

}
