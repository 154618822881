import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { HeaderService } from "src/app/core/services/header.service";
import { BrowserService } from "../../../core/services/browser.service";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  @Input() banner;
  @Input() kidsbanner;
  @Input() iskids;
  bannerData = [];
  kidsbannerData = [];
  premium: any;
  enabledPlayButton: boolean;
  constructor(
    private router: Router,
    private headerService: HeaderService,
    private browserService: BrowserService
  ) {}

  ngOnInit() {
    this.browserService.scrollToTop();
    this.bannerData = this.banner;
    this.kidsbannerData = this.kidsbanner;
    this.enabledPlayButton = false;
  }

  ngAfterViewInit() {
    this.getPremiumName();
  }

  getPremiumName() {
    this.headerService.apiData$.subscribe((data) => {
      if (data !== null) {
        this.premium = data["response"].address.premium_name;
      }
    });
  }

  // Banner click
  onBannerClick(slug, status) {
    if ((slug != null || slug != undefined) && status === 1) {
      this.router.navigate(["video/" + slug]);
    }
  }

  onKidsBannerClick(slug, status) {
    if ((slug != null || slug != undefined) && status === 1) {
      this.router.navigate(["video/" + slug]);
    }
  }
}
