import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, Scroll, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Location, ViewportScroller } from '@angular/common';
import { ConnectionService } from 'ng-connection-service';
import { HeaderService } from 'src/app/core/services/header.service';
import { filter } from 'rxjs/operators';
import { AuthService } from '../app/core/auth/auth.service';
import { interval, Subscription} from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../app/core/services/api.service';

declare var $: any;
declare var ga: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'MAXPLAY';
  routes: any;
  headerFlag: Boolean;
  footerFlag: Boolean;
  isConnected: any;
  status: any = true;
  currentUser: any;
  isSubscription: Subscription;
  routeSub: any;
 
   

  constructor(
    private router: Router,
    private connectionService: ConnectionService,
    private headerService: HeaderService,
    private loc: Location,
    private viewportScroller: ViewportScroller,
    private authService: AuthService,
    private apiService: ApiService
  ) {
    if (!localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', 'en');
    }
    this.router.events.subscribe((res) => {});
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = true;
      } else {
        this.status = false;
      }
    });
    router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
          this.routes = this.router.url;
          if (
            this.routes.startsWith('/watch/') ||
            this.routes.startsWith('/embed/')
          ) {
            this.headerFlag = false;
          } else {
            this.headerFlag = true;
          }
          if (
            this.routes.startsWith('/watch/') ||
            this.routes.startsWith('/embed/')
          ) {
            this.footerFlag = false;
          } else {
            this.footerFlag = true;
          }
        }
      });

    this.router.events
      .pipe(filter((e) => e instanceof Scroll))
      .subscribe((e: any) => {
        setTimeout(() => {
          if (e.position) {
            this.viewportScroller.scrollToPosition(e.position);
          } else if (e.anchor) {
            this.viewportScroller.scrollToAnchor(e.anchor);
          }
        });
      });
      this.isSubscription = interval(1000).subscribe((x => {
        this.checkSubscription();
     }));
  }
  ngOnInit() {
    if (localStorage.getItem('currentUser') !== null || localStorage.getItem('currentUser') !== undefined ) {
      if ( window.location.pathname === '/landingpage' )  {
        this.router.navigate(['/']);
      }
    }
  }

  checkSubscription() {
    // this.apiService.callGetAPI(
    //   'v2/checkSubscription', environment.apiUrl)
    //   .subscribe(
    //     data => {
    //       // console.log('data', data);
    //       if (data['statusCode'] === 200 && data['error'] === false) {
    //         console.log(data);
    //       }
    //     },
    //     error => {
    //       this.apiService.failureResponse(error);
    //     });
  
    if (localStorage.getItem('currentUser')) {
    const userValues = localStorage.getItem('currentUser');
    const userData = this.authService.decryption(userValues);
    // console.log(userData['response'].is_subscribed);
      if (userData && userData['response'].is_subscribed === 0) {
        if (!this.router.url.includes('/card-payment/') && !this.router.url.includes('/subscription') && !this.router.url.includes('/subscription/') ) {
          this.router.navigate(['/subscription']);
        }
      } else {
        // console.log('one');
      }
    }
  }

}
