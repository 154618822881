import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/core/services/header.service';


@Component({
  selector: 'app-landingpagefooter',
  templateUrl: './landingpagefooter.component.html',
  styleUrls: ['./landingpagefooter.component.scss']
})
export class LandingpagefooterComponent implements OnInit {
  date = new Date();
  year = this.date.getFullYear();
  cmsLink: any = [];
  checked: boolean = false;



  constructor(private headerService: HeaderService) { }

  ngOnInit() {
    this.getLink();

    if(localStorage.getItem('currentLanguage') === 'en') {
      this.checked = false;
    } else {
      this.checked = true;
    }
  }
  getLink() {
    this.headerService.apiData$.subscribe(data => {
      if (data !== null) {
        this.cmsLink = data['response'].static_contents;
      }
    });
  }

  change(e) {
    if (this.checked) {
        this.checked = !this.checked;
        localStorage.setItem('currentLanguage', 'en');
        location.reload();
    } else {
      this.checked = !this.checked;
      localStorage.setItem('currentLanguage', 'ar');
      location.reload();
    }
  }

}
