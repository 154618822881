import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../../../../core/services/api.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  name: any;
  email: any;
  phone: any;
  message: any;
  url: any = [];
  touched = false;
  staticContent: any = [];
  recaptcha: boolean;
  googleRecaptcha: string;
  lat = environment.latitude;
  lng = environment.longitude;
  constructor(
    private apiService: ApiService
) {
    // this.getAddress();
  }

  ngOnInit() {
    this.googleRecaptcha = environment.googleRecaptcha;
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
}
  getAddress() {
    this.apiService.callGetAPI('v2/staticcontent/contact-us', environment.commonApiUrl)
      .subscribe(
        data => {
          this.staticContent = data['response'];
        },
        error => {
          this.apiService.failureResponse(error);
        });
  }

  onContactSubmit(contactForm: NgForm) {
    this.touched = true;
    if (contactForm.valid) {
      this.url.redirect = '/pages/feedback';
      this.apiService.callPostAPI(
        'v2/staticContent/contactus',
        {
          name: contactForm.value.name,
          email: contactForm.value.email,
          phone: contactForm.value.phone,
          message: contactForm.value.message,
        }, environment.commonApiUrl)
        .subscribe(
          data => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              this.apiService.successResponse(data, this.url);
              contactForm.reset();
              this.touched = false;
            }
          },
          error => {
            this.apiService.failureResponse(error);
          });
    }
  }

}
