import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import videoJs from '../../../../assets/js/play.es.js';
import { PlayerService } from '../../../core/services/player.service';
import { ConnectionService } from 'ng-connection-service';
import { environment } from '../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../core/services/api.service';
import { AuthService } from '../../../core/auth/auth.service';
import * as firebase from 'firebase';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { ScrollToBottomDirective } from '../../../core/directives/scroll-to-bottom.directive';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';


declare var $: any;
declare var WebRTCAdaptor;

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoPlayer') videoooPlayer: ElementRef;
  personsCounter: Subscription;
  intervalId: any;
  viewersCount: any;
  enableSubscribe = false;
  isPlaying = false;
  player: any;
  time = 0;
  user: any;
  overlayContent: any;
  status = 'ONLINE';
  geoStatus = true;
  isConnected = true;
  newtworkStatus: any;
  setVideo: Boolean = true;
  params: any;
  @Output() playing = new EventEmitter();
  @Output() livchatStatus = new EventEmitter(); // Live Chat emitter
  liveChatCurrentVideo: any;
  video: any;
  interVal: any;
  subTitle: any = [];
  key: any;
  iv: any;
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  userAgent: any;
  showXrayList = false;
  showXrayViewAll = false;
  showXrayCastDetail = false;
  showXrayScenestab = false;
  xrayCastList = [];
  xrayCastViewAllList = [];
  xrayScenesList = [];
  xrayCastDetail: any;
  currentDecryptUser;
  videoPaymentInfo;
  sendProgressEvent = false;
  showXray = false;
  showEpisode = false;
  mouseOverXray = false;
  mouseOverEpisode = false;
  viewCountStatus: string;
  qualityText: any;
  speedText: any;
  currentTime: any;
  liveChatText: any;
  liveMessages: any[];
  livechatInputHeight: any;
  commentErrors: any[];
  firebaseKey: any;
  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;
  commentMaxlengthError: Boolean = false;
  commentShortError: Boolean = false;
  next_video: any = false;
  sprite_image: any;
  liveURL: any;
  isLive: Boolean = false;
  timediff: any;
  ///////////// webrtc
  public showPlayerOverlay = true;
  public playerOverlayInfo = '';
  public streamingSrc = '';
  public videoPlayerEl: any;
  todaysLive: any;
  upcomingLive: any = [];
  noVideo = 'Sorry! no videos';
  videoCategory: any = 'live';
  bannerData: any = [];
  home: any;
  new: any;
  bannerLive: any = [];
  loading: Boolean;
  loadingSlider: any = [];
  liveVideoList = [];
  showTitle = true;
  isPlayingVar = [];
  videoTitle: string;
  headerVar: any;
  pageDestroyed = false;
  moveInOutCards = false;
  moveOutCards = true;
  mouseOverHeader = false;
  playerActiveInactive = false;
  isVideoPlaying = false;
  webRTCAdaptor: any;
  related_videos: any;
  recommendLogo: any;
  livestreamStarted = false;
  start_time: any = 0;
  end_time: any = 0;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosY = window.pageYOffset || document.body.scrollTop;
    this.moveInOutCards = false;
    if (scrollPosY > 80) {
      this.headerVar.classList.remove('live-header');
      this.headerVar.classList.add('show-header');
      this.headerVar.classList.remove('hide-header');
    } else {
      if (this.isVideoPlaying) {
        this.headerVar.classList.remove('show-header');
        this.headerVar.classList.add('hide-header');
      }
      this.headerVar.classList.add('live-header');
    }
  }
  @HostListener ('window:beforeunload', ['$event'])
  callPlayerApi($event) {
    if (this.video.currentTime && !this.video.is_live) {
    // this.storeWatchedDuration();
    this.apiService.callPostAPI('v2/videoanalytics', {'video_slug': this.video.slug, 'seconds': this.player.currentTime()}, environment.analyticsApiUrl)
    .subscribe();
    return false;
    }
  }
  /////// webrtc
  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private connectionService: ConnectionService,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private db: AngularFireDatabase,
    private toaster: ToastrService,
    private sanitizer: DomSanitizer,
    location: PlatformLocation, private backlocation: Location
  ) {
    location.onPopState(() => {
      this.webRTCAdaptor.leave(this.video.stream_id);
    });
    this.key = CryptoJS.enc.Utf8.parse(environment.crypto_key);
    this.iv = CryptoJS.enc.Utf8.parse(environment.crypto_iv);
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = 'ONLINE';
      } else {
        this.status = 'OFFLINE';
        this.newtworkStatus = document.getElementById('online-toast');
        document.getElementById('videos').appendChild(this.newtworkStatus);
      }
    });
    this.formatAMPM(new Date());
    this.user = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
  }

  // Get Current time
  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    this.currentTime = strTime;
  }
  ngOnInit() {
    this.currentDecryptUser = this.authService.decryption(
      localStorage.getItem('currentUser')
    );
    this.route.params.subscribe((params) => {
      this.params = params;
      this.initialFunction();
    });
    if (this.video.is_live === 1) {
      const source = interval(60000);

      this.personsCounter = source.subscribe((val) => this.openSnack());
    }
  }

  ngAfterViewInit() {
    this.headerVar = document.getElementsByTagName('header')[0];
    this.videoPlayerEl = document.getElementById('remoteVideo');
    if (this.videoPlayerEl) {
      this.videoPlayerEl.addEventListener('play', () => {
        this.isVideoPlaying = true;
        this.moveInOutCards = false;
      });
      this.videoPlayerEl.addEventListener('pause', () => {
        this.isVideoPlaying = false;
      });
    }
    this.initWebRtc();
  }

  xrayListClicked() {
    this.showXrayList = !this.showXrayList;
  }

  openSnack() {
    this.viewersCount = 0;
    this.apiService
      .callExternalAPI(
        `${environment.webRTCBroadcastDomain}WebRTCAppEE/rest/v2/broadcasts/${this.video.stream_id}/broadcast-statistics`
      )
      .subscribe(
        (response) => {
          if (
            response.totalWebRTCWatchersCount === -1 ||
            response.totalWebRTCWatchersCount === -1
          ) {
            this.viewersCount = 0;
          } else {
            this.viewersCount = response.totalWebRTCWatchersCount;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  showXrayCastDetailMethod(cast) {
    const a = document.createElement('a');
    if (cast.external_url !== null && cast.external_url !== undefined) {
      a.href = cast.external_url;
      a.setAttribute('target', '_blank');
      this.player.pause();
      a.click();
      this.showXrayList = false;
    }
  }

  xrayCastDetailBackClicked() {
    this.showXrayViewAll = true;
    this.showXrayCastDetail = false;
  }

  hexToBinary(num) {
    const hex = num,
      bytes = [];
    for (let i = 0; i < hex.length - 1; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    return String.fromCharCode.apply(String, bytes);
  }
  binaryToHex(num) {
    let str = '',
      i = 0;
    const tmp_len = num.length;
    let c;
    for (; i < tmp_len; i += 1) {
      c = num.charCodeAt(i);
      str += this.d2h(c);
    }
    return str;
    // return parseInt(num, 2).toString(16);
  }
  d2h(d) {
    return d.toString(16);
  }
  decrypt() {
    if (typeof this.video.passphrase !== 'undefined') {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(this.video.passphrase)
      });
      this.decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        CryptoJS.enc.Hex.parse(environment.crypto_key),
        {
          keySize: 256,
          iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
          mode: CryptoJS.mode.CBC
        }
      );
      const change = this.decrypted.toString(CryptoJS.enc.Utf8);
      this.plainText = parseInt(this.hexToBinary(change), 10);
      this.incrementInterVal();
    }
  }
  encrypt() {
    const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
    const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
    const hex = this.binaryToHex(hex_utf);
    this.encrypted = CryptoJS.AES.encrypt(
      hex,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
    return this.cipherText;
  }
  incrementInterVal() {
    this.interVal = setInterval(
      function () {
        this.plainText += 1;
      }.bind(this),
      1000
    );
    this.setPlayerConfig();
  }
  setPlayerConfig() {
    const getVideo = document.getElementById('videos');
    videoJs.Hls.xhr.beforeRequest = (options) => {
      options.headers = [];
      if (this.video.is_live === 0) {
        options.headers['Title'] = this.encrypt();
      }
    };
    this.userAgent = window.navigator.userAgent;
    if (this.userAgent.match(/iPad/i) || this.userAgent.match(/iPhone/i)) {
      this.player = videoJs(getVideo, {
        playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
        controls: true,
        preload: 'auto',
        fluid: true,
        autoplay: false,
        seekEventPercentage: this.video.seekEventPercentage || 30,
        tracks: this.setTracks(),
        plugins: {
          hlsQualitySelector: {},
          keyboardShortCuts: {},
          spriteThumbnails: {}
        }
      });
    } else {
      this.player = videoJs(getVideo, {
        playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
        controls: true,
        preload: 'auto',
        fluid: true,
        autoplay: false,
        seekEventPercentage: this.video.seekEventPercentage || 30,
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
          nativeTextTracks: false,
          hls: {
            overrideNative: true,
            customDrm: {
              // tslint:disable-next-line:max-line-length
              keys:
                // tslint:disable-next-line: max-line-length
                'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
              // 'uri': 'http://d1xxzl3j6dvosn.cloudfront.net/costarica/encrypted.key',
            }
          }
        },
        tracks: this.setTracks(),
        plugins: {
          hlsQualitySelector: {},
          seekButtons: {
            forward: 10,
            back: 10
          },
          keyboardShortCuts: {},
          spriteThumbnails: {}
        }
      });
    }
    // Need to comment below to deactivate advertisement
    const options = {
      id: 'player',
      adTagUrl: this.video.ads_url,
      debug: false,
      adLabel: 'Video resumes in'
    };

    if (this.video.ads_url) {
      this.player.ima(options);
    }
    this.player.on('adsready', () => {
      this.next_video = false;
      this.player.play();
    });

    this.player.on('adend', () => {
      this.next_video =
        this.video.next_episode_slug !== '' &&
        this.video.next_episode_slug !== null
          ? true
          : false;
    });

    this.player.on('adserror', () => {
      this.player.play();
    });
    this.setPlayer(this.video);
  }
  setTracks() {
    // console.log(this.video.subtitle.subtitle_list);
    const  subtitleReverse = this.video.subtitle.subtitle_list;
    subtitleReverse.reverse();
    // console.log(subtitleReverse);
    if (this.video.subtitle) {
      if (this.video.subtitle.subtitle_list.length > 0) {
        this.video.subtitle.subtitle_list.forEach((element) => {
          this.subTitle.push({
            title: 'subtitles',
            kind: 'subtitles',
            language: element.language,
            label: element.label,
            src: this.video.subtitle.base_url + element.url,
            // default: element.default
            default: true
          });
        });
        this.subTitle.push({});
      }
      return this.subTitle;
    } else {
      return [];
    }
  }
  setPlayer(video) {
    this.video = video;
    this.newtworkStatus = document.getElementById('online-toast');
    document.getElementsByClassName('play-text-track-display')[0].innerHTML =
      '<div>' + video.title + '</div>';
    document.getElementById('videos').appendChild(this.newtworkStatus);
    $('#ogImage').attr('content', video.poster_image);
    $('.video-cs-blk').appendTo($('#videos'));
    $('.next-video').appendTo($('#videos'));
    this.player.on('play', () => {
      //  const socket = new WebSocket(environment.socketUrl);
      //   socket.onopen = (event) => {
      //     socket.send('1');
      //   };
    });
    if (!video.ads_url) {
      this.player.play();
    }
    // this.player.autoplay(true);
    this.player.src({
      src: video.hls_playlist_url,
      type: 'application/x-mpegURL'
    });

    /** Check last video session */
    const session = JSON.parse(localStorage.getItem('videos'));
    if (
      session &&
      typeof session['hls_playlist_url'] !== 'undefined' &&
      typeof video.hls_playlist_url !== 'undefined' &&
      session['hls_playlist_url'] === video.hls_playlist_url
    ) {
      this.player.currentTime(session['currentTime']);
    } else {
      this.player.currentTime(0);
    }
    const startOver = localStorage.getItem("start_over");
    if (startOver) {
      this.player.currentTime(0);
      this.start_time = 0;
    } else {
      this.player.currentTime(this.video.seconds ? this.video.seconds : 0);
      this.start_time = this.video.seconds ? this.video.seconds : 0;
    }

    this.player.on('pause', () => {
      // const socket = new WebSocket(environment.socketUrl);
      // socket.onopen = (event) => {
      //   socket.send('0');
      // };
    });
    /** Video playing */
    this.player.on('playing', () => {
      if (!this.isPlaying) {
        // if (this.currentDecryptUser) {
        //   this.apiService.personalizePutEvent(this.params.video_id, `${this.currentDecryptUser.response.id}`);
        // }
        this.isPlaying = true;
        this.playing.emit(false);
        this.player.spriteThumbnails({
          url: this.sprite_image,
          width: 192,
          height: 113,
          stepTime: 20
        });
      }
    });
    setInterval(
      function () {
        if ($('.play-control-bar').css('opacity') === '1') {
          $('.back_btn').css('display', 'block');
          $('.movie-title').css('display', 'block');
          this.showXray = true;
          this.showEpisode = true;
        } else {
          $('.back_btn').css('display', 'none');
          $('.movie-title').css('display', 'none');
          this.showXray = this.mouseOverXray ? true : false;
          this.showEpisode = this.mouseOverEpisode ? true : false;
        }
      }.bind(this),
      1
    );
    /** Video current time update */
    this.player.on('timeupdate', async() => {
      if (this.player.currentTime() === this.player.duration()) {
        if (
          this.video.next_episode_slug !== '' &&
          this.video.next_episode_slug !== null
        ) {
          this.getCompleteVideoDetail();
        }

        if (this.video.is_live === 0 && this.video.is_webseries === 0) {
          this.getRelatedVideos();
          $('.grid_box').show();
          $('.subscribe_box').show();
        }
      } else {
        if (this.video.is_live === 0 && this.video.is_webseries === 0) {
          $('.grid_box').hide();
          $('.subscribe_box').hide();
        }
      }
      // continue watching start
      if( (this.end_time > this.player.currentTime()) || ((this.end_time + 5) < this.player.currentTime())) {
        // await this.storeWatchedDuration();
        this.start_time = this.end_time = this.player.currentTime();
        } else {
        this.end_time = this.player.currentTime();
        }
      // continue watching end
      this.time = this.player.currentTime();
      video.currentTime = this.time;
      localStorage.setItem('videos', JSON.stringify(video));
    });

    this.player.on('modelClose', () => {
      $('.video-player-center').removeClass('rem-name');
      $('.video-cs-blk').show();
    });
    this.player.on('modelOpen', () => {
      $('.video-player-center').addClass('rem-name');
      $('.video-cs-blk').hide();
    });
    /** Video Error */
    this.player.on('error', (event) => {
      if (
        this.player.error().status === 403 ||
        this.player.error().status === 404 ||
        this.player.error().code === 4
      ) {
        if (video.is_live === 1) {
          $('.play-error-display').removeClass('play-hidden');
          let getMessage;
          this.translate.get('PLAYER.LIVE_NOT_STARTED').subscribe((res) => {
            getMessage = res;
          });
          $('.play-modal-dialog-content').html(getMessage);
          this.player.reset();
        } else {
          $('.play-error-display').removeClass('play-hidden');
          let getMessage;
          this.translate.get('PLAYER.VIDEO_NOT_FOUND').subscribe((res) => {
            getMessage = res;
          });
          $('.play-modal-dialog-content').html(getMessage);
          this.player.reset();
        }
      }
      if (this.player.error().code === 2) {
        if (video.is_live === 1) {
          $('.play-error-display').removeClass('play-hidden');
          let getMessage;
          this.translate.get('PLAYER.LIVE_NOT_STARTED').subscribe((res) => {
            getMessage = res;
          });
          $('.play-modal-dialog-content').html(getMessage);
          this.player.reset();
        } else {
          $('.play-error-display').removeClass('play-hidden');
          let getMessage;
          this.translate.get('PLAYER.VIDEO_NOT_FOUND').subscribe((res) => {
            getMessage = res;
          });
          $('.play-modal-dialog-content').html(getMessage);
          this.player.reset();
        }
      }
      if (this.player.error().code === 3) {
        $('.play-error-display').removeClass('play-hidden');
        let getMessage;
        this.translate.get('PLAYER.STREAM_NOT_SUPPORTED').subscribe((res) => {
          getMessage = res;
        });
        $('.play-modal-dialog-content').html(getMessage);
        this.player.reset();
      }
      if (this.player.error().code === 5) {
        $('.play-error-display').removeClass('play-hidden');
        let getMessage;
        this.translate.get('PLAYER.WENT_WRONG').subscribe((res) => {
          getMessage = res;
        });
        $('.play-modal-dialog-content').html(getMessage);
        this.player.reset();
      }
    });

    /** Video End */
    // this.player.on('ended', () => {
    //   this.isPlaying = false;
    //   video.currentTime = 0;
    // });

    this.player.on('ended', () => {
      this.isPlaying = false;
      video.currentTime = 0;
      // this.storeWatchedDuration();
      this.apiService.callPostAPI('v2/videoanalytics', {'video_slug': this.video.slug, 'seconds': 0}, environment.analyticsApiUrl)
      .subscribe();
      });

    this.player.on('timeupdate', (e) => {
      const videoProgressPercentage = Math.ceil(
        (this.player.currentTime() / this.player.duration()) * 100
      );
      if (this.viewCountStatus === 'Expired') {
        if (videoProgressPercentage > 99.9) {
          this.router.navigate([`/video/${this.video.slug}`]);
        }
      } else {
        if (videoProgressPercentage >= 10 && videoProgressPercentage <= 30) {
          if (!this.sendProgressEvent) {
            this.sendProgressEvent = true;
            this.callTvodViewCount(50);
          }
        } else if (
          videoProgressPercentage >= 60 &&
          videoProgressPercentage <= 80
        ) {
          if (!this.sendProgressEvent) {
            this.sendProgressEvent = true;
            this.callTvodViewCount(100);
          }
        } else {
          this.sendProgressEvent = false;
        }
      }
    });

    // this.player.on('progressEvent', () => {
    //   if (!this.sendProgressEvent) {
    //     this.sendProgressEvent = true;
    //     this.apiService.callGetAPI(`tvod_view_count/${this.video.transaction_id}`).subscribe(data => {
    //     });
    //   }
    // })
  }

  callTvodViewCount(completePercent) {
    if (this.video && this.video.price > 0) {
      this.apiService
        .callPostAPI('v2/tvod_view_count', {
          transaction_id: this.videoPaymentInfo.transaction_id,
          complete_percentage: completePercent
        })
        .subscribe((data) => {
          if (data.hasOwnProperty('response')) {
            this.viewCountStatus = data['response'].status;
          }
        });
    }
  }

  goToSubscription() {
    localStorage.setItem('subscribeUrl', window.location.pathname);
    this.router.navigate(['/subscription']);
  }

  // ngOnDestroy() {
  //   if (this.player) {
  //     this.player.dispose();
  //   }
  //   document
  //     .getElementsByTagName('body')[0]
  //     .classList.remove('body-video-page');
  //   clearInterval(this.intervalId);
  // }

  ngOnDestroy() {
    const startOver = localStorage.getItem("start_over");
    if (startOver) {
      localStorage.removeItem("start_over");
    }

      if (this.video.currentTime && !this.video.is_live) {
      // this.storeWatchedDuration();
      this.apiService.callPostAPI('v2/videoanalytics', {'video_slug': this.video.slug, 'seconds': this.player.currentTime()}, environment.analyticsApiUrl)
      .subscribe();
      if (this.player) {
      this.player.dispose();
      }
      document
      .getElementsByTagName('body')[0]
      .classList.remove('body-video-page');
      }
      document
      .getElementsByTagName('body')[0]
      .classList.remove('body-video-page');
    }

  PageBack() { 
    if (this.video.is_live === 1) {
      this.webRTCAdaptor.leave(this.video.stream_id);
    } else if (this.video.is_live === 3) {
      this.router.navigate(['liveevents']);
    } else {
      this.router.navigate(['video/' + this.params.video_id]);
    }
  }

  PageBackLive(): void {
    this.backlocation.back();
  }

  // Live chat configuration
  /**
   * live chat tab open
   */
  toggleLiveChat(status) {
    this.livchatStatus.emit(status);
    this.getVideoId();
  }
  /**
   * Get Video id based on the video slug
   */
  getVideoId() {
    const url = 'v2/getVideoId/';
    this.apiService.callGetAPI(url + this.video.slug).subscribe(
      (response) => {
        this.liveChatCurrentVideo = response['response'];
        this.getLiveChatMessages(this.liveChatCurrentVideo);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onKeydown(event) {
    event.preventDefault();
  }
  trackByFn(index, item) {
    return index; // or item.id
  }
  changeEvent(change) {
    if (change && !(change.trim() === '')) {
      this.commentShortError = false;
    }
    if (change && change.length > 200) {
      this.commentMaxlengthError = true;
    } else {
      this.commentMaxlengthError = false;
    }
    this.livechatInputHeight = document.getElementById(
      'live-chat-input'
    ).offsetHeight;
    this.livechatInputHeight = this.livechatInputHeight + 70;
    this.livechatInputHeight =
      'calc(100% - ' + this.livechatInputHeight + 'px)';
  }

  getCompleteVideoDetail() {
    this.router.navigate(['watch/' + this.video.next_episode_slug.slug]);
  }

  initialFunction() {
    this.playerService.videoPaymentInfo.subscribe((data) => {
      this.videoPaymentInfo = data;
    });
    this.playerService.currVideo.subscribe((data) => {
      if (data !== '' && this.setVideo && data.hls_playlist_url) {
        this.setVideo = true;
        this.video = data;
        this.next_video =
          this.video.next_episode_slug !== '' &&
          this.video.next_episode_slug !== null &&
          this.video.ads_url === ''
            ? true
            : false;
        this.sprite_image =
          this.video.next_episode_slug !== '' &&
          this.video.next_episode_slug !== null
            ? null
            : data.sprite_image;
        if (this.video) {
          // Get video id based on vide slug for live chat configuration
          this.getVideoId();
        }
        if (!this.video.is_restricted) {
          this.geoStatus = false;
          this.decrypt();
        }

        if (!this.geoStatus) {
          this.apiService
            .callGetAPI(`v2/video/cast/${this.video.slug}`)
            .subscribe((data) => {
              this.xrayCastList = data['response'].cast_info;
              if (this.xrayCastList.length > 0) {
                this.showXray = true;
              }
            });
        }
      }

      if (
        data !== '' &&
        this.setVideo &&
        data.is_live === 1 &&
        !data.hls_playlist_url
      ) {
        if (!data.is_restricted) {
          this.geoStatus = false;
        }
        $('#videos').hide();
        this.video = data;
        this.isLive = this.video.is_live === 1 ? true : false;
      }
    });
    document.getElementsByTagName('body')[0].classList.add('body-video-page');
  }

  nextVideo() {
    this.getCompleteVideoDetail();
  }

  initWebRtc() {
    const pc_config = null;
    const sdpConstraints = {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true
    };
    const mediaConstraints = {
      video: true,
      audio: true
    };
    this.webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: environment.webRTCSocketUrl,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pc_config,
      sdp_constraints: sdpConstraints,
      remoteVideoId: 'remoteVideo',
      isPlayMode: true,
      callback: (info) => {
        this.playerOverlayInfo = 'loading';
        if (info === 'initialized') {
          this.openSnack();
          this.playerOverlayInfo = 'loading';
          this.webRTCAdaptor.play(this.video.stream_id);
        } else if (info === 'play_started') {
          this.livestreamStarted = true;
          this.openSnack();
          this.showPlayerOverlay = false;
          this.videoPlayerEl.play();
          this.isVideoPlaying = true;
        } else if (info === 'play_finished') {
          this.playerOverlayInfo = 'finished';
          this.showPlayerOverlay = true;
          this.isVideoPlaying = false;
        }
      },
      callbackError: (error) => {
        this.showPlayerOverlay = true;
        this.isVideoPlaying = false;
        console.log(error);
        this.playerOverlayInfo = '';
        if (error === 'no_stream_exist') {
          this.playerOverlayInfo = 'unavailable';
        } else {
          this.playerOverlayInfo =
            'We are facing some issues in this live streaming!';
        }
      }
    });
  }
  /**
   * Get Live chat messages based on video_id
   */
  getLiveChatMessages(liveChatCurrentVideo) {
    this.db
      .list('messages/' + 'video_id_' + liveChatCurrentVideo.id)
      .valueChanges()
      .subscribe((response) => {
        return (this.liveMessages = response);
      });
  }
  /**
   * Send live messages
   */
  sendMessageInLive(message): void {
    this.commentShortError = false;
    const liveMessage = message ? message.trim() : '';
    if (!(liveMessage === '')) {
      if (liveMessage.length <= 200) {
        firebase
          .database()
          .ref('messages/' + 'video_id_' + this.liveChatCurrentVideo.id)
          .push({
            name:
              this.user && this.user.response && this.user.response.name
                ? this.user.response.name
                : null,
            photoUrl:
              this.user &&
              this.user.response &&
              this.user.response.profile_picture
                ? this.user.response.profile_picture
                : '',
            text: liveMessage,
            time: this.currentTime
          })
          .then((snap) => {
            this.firebaseKey = snap.key;
            // Send liv messages to the comments table
            if (localStorage.getItem('currentUser') && this.firebaseKey) {
              this.apiService
                .callPostAPI('v2/videoComments', {
                  video_id: this.video.slug,
                  comment: liveMessage,
                  parent_id: '',
                  firebase_key: this.firebaseKey
                })
                .subscribe(
                  (data) => {
                    // this.apiService.successResponse(data);
                  },
                  (error) => {
                    // this.apiService.failureResponse(error);
                  }
                );
            }
          });
        this.liveChatText = '';
      }
    } else {
      this.commentShortError = true;
    }
  }
  getRelatedVideos() {
    const url = 'v2/related_videos/';
    this.apiService.callGetAPI(url + this.video.slug).subscribe(
      (response) => {
        this.related_videos = response['response']['endscreen'];
        this.recommendLogo = response['response']['logo'];
      },
      (error) => {
        console.log(error);
      }
    );
  }
  storeWatchedDuration() {
    if(this.start_time < this.end_time){
    this.apiService.callPostAPI('v2/watched-duration', {'start_time': this.start_time, 'end_time': this.end_time}, environment.analyticsApiUrl)
    .subscribe();
    }
  }
}
