import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpModule } from './modules/sign-up/sign-up.module';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { environment } from '../environments/environment';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { SignInModule } from './modules/sign-in/sign-in.module';
import { ForgetPasswordModule } from './modules/forget-password/forget-password.module';
import { HomeComponent } from './modules/home/home.component';
import { BannerComponent } from './modules/home/banner/banner.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SharedModule } from './shared/shared.module';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { PlayerComponent } from './modules/video-detail/player/player.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {UrlSerializer} from '@angular/router';
import {CustomUrlSerializer} from './CustomUrlSerializer';
import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ContentEditableFormDirective } from './core/directives/content-editable-form.directive';
import { LandingpageComponent } from './modules/landingpage/landingpage.component';
import {AuthGuard} from './core/guards/auth.guard';
import { KidsDetailComponent } from './modules/kids/kids-detail/kids-detail.component';
import { RadioDetailComponent } from './modules/radio/radio-detail/radio-detail.component';
import { RadioPlayComponent } from './modules/radio/radio-play/radio-play.component';
import { LandingpagefooterComponent } from './modules/landingpage/pages/landingpagefooter/landingpagefooter.component';
import { PrivacyComponent } from './modules/landingpage/pages/privacy/privacy.component';
import { HelpComponent } from './modules/landingpage/pages/help/help.component';
import { FeedbackComponent } from './modules/landingpage/pages/feedback/feedback.component';
import { NoticeComponent } from './modules/landingpage/pages/notice/notice.component';
import { TermsofuseComponent } from './modules/landingpage/pages/termsofuse/termsofuse.component';
import { SeeallComponent } from './modules/seeall/seeall/seeall.component';
import { SeeallgenreComponent } from './modules/seeall/seeallgenre/seeallgenre.component';
import { LiveeventComponent } from './modules/liveevent/liveevent.component';
import { WebseriesvideosComponent } from './modules/webseries/webseriesvideos/webseriesvideos.component';
import { LiveplayerComponent } from './modules/liveplayer/liveplayer/liveplayer.component';

  
export const firebaseConfig = {
  apiKey: 'AIzaSyAl3mr4PdEF2Wr8xgs6FqPAnkq-5rRughY',
  authDomain: 'vplay-product.firebaseapp.com',
  databaseURL: 'https://vplay-product.firebaseio.com',
  projectId: 'vplay-product',
  storageBucket: 'vplay-product.appspot.com',
  messagingSenderId: '622002878280',
  appId: '1:622002878280:web:8faeabf186d25f881ca57d'
};
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.GoogleOAuthClientId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FacebookAppId)
  }
]);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BannerComponent,
    NotificationComponent,
    WatchVideoComponent,
    WebseriesDetailComponent,
    PlayerComponent,
    EmbedComponent,
    ContentEditableFormDirective,
    LandingpageComponent,
    KidsDetailComponent,
    RadioDetailComponent,
    RadioPlayComponent,
    LandingpagefooterComponent,
    PrivacyComponent,
    HelpComponent,
    FeedbackComponent,
    NoticeComponent,
    TermsofuseComponent,
    SeeallComponent,
    SeeallgenreComponent,
    LiveeventComponent,
    WebseriesvideosComponent,
    LiveplayerComponent
    // ContusSliderComponent
  ],
  imports: [
    BrowserModule,
    SignUpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      closeButton: true,
      timeOut: 5000,
      autoDismiss: true,
      positionClass: 'toast-bottom-left',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    SignInModule,
    ForgetPasswordModule,
    SocialLoginModule,
    OwlModule,
    SharedModule,
    AppRoutingModule,
    LazyLoadImagesModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthGuard,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              @Inject(APP_ID) private appId: string,
              public translate: TranslateService) {
              translate.setDefaultLang('en');
              translate.use('en');
  }
 }
