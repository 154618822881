import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  staticContent: any = [];
  slug: string;
  loading = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getContent();
  }
  getContent() {
    this.apiService.callGetAPI('v2/staticcontent/privacy-policy', environment.commonApiUrl)
      .subscribe(
        data => {
          this.loading = false;
          this.staticContent = JSON.parse(JSON.stringify(data['response']));
        },
        error => {
          this.apiService.failureResponse(error);
        });
  }

}
