import { Component, OnInit, Input, OnChanges, SimpleChange, AfterViewInit, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../core/auth/auth.service';
import { PlayerService } from '../../../core/services/player.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VideoModel } from '../../../modules/video-detail/video.model';
import { VideoShareService } from '../../../core/services/video-share.service';
import {DomSanitizer} from '@angular/platform-browser';
import radioplay from '../../../../assets/js/radioplay.es.js';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { from, fromEvent, Observable, Subscription } from "rxjs";
import { Location } from '@angular/common';

@Component({
  selector: 'app-radio-play',
  templateUrl: './radio-play.component.html',
  styleUrls: ['./radio-play.component.scss']
})

export class RadioPlayComponent implements OnInit, AfterViewInit {
  @Input() public src: string;
  @Input() public autoplay: boolean = true;
  @Input() public showStateLabel: boolean = true;
  public audioStateLabel = 'Audio sample';
  @Input() public volume: number = 1.0; /* 1.0 is loudest */
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  @ViewChild('audioElement') public _audioRef:  ElementRef;
  private audio: HTMLMediaElement;
  radioUrl: any;
  loading = false;
  radioTitle: any;
  radioDescription: any;
  radioThumbnail: any;
  radioPosterImage: any;
  muted: boolean = false;
  isPlay: boolean = false;
  currentData: any = {};
  currentId: any;
  current = 0;
  allDatas: any = {};
  loggedIn: any = {};
  tostar: any = {};
  page = 1;
  nextpage = 1;
  newNextPage: Boolean = true;
  slidreOpen: boolean = false;
  radioOpen: boolean = false;
  sliderMovedData: any;
  sliderRowItem: any;
  previousPage: Boolean = false;
  nextMove: any;
  liveURL: any;
  currentCategoryId: any;
  // url = localStorage.getItem("radioUrl");
  private _elementRef: ElementRef
  seekbarTracker: any = {
    duration: 0,
    time: 0,
    seekPercent: 0,
    hasDVR: false,
  };
  nextClick: Boolean = true;
  preClick: Boolean = true;
  currentUrl: any;



  seekTime: number;
  // // video asset url
  // @Input() url: any;

  // declare player var
  private player: any;
  constructor(private apiService: ApiService, private authService: AuthService, private playerService: PlayerService, private toasterService: ToastrService, private router: Router, private route: ActivatedRoute, private videoShareService: VideoShareService, elementRef: ElementRef,private sanitizer: DomSanitizer, private http: HttpClient, private location: Location ) {
    this.player = false;
    this.route.params.subscribe((params) => {
      this.getRadio(params.slug);
      this.updateRadioSlider(params.slug);
      this.initVideo(params.slug);
      this.getVideos(params.slug);
    });
    var scrollToTop = window.setInterval(function() {
      var pos = window.pageYOffset;
      if ( pos > 0 ) {
          window.scrollTo( 0, pos - 20 ); // how far to scroll on each step
      } else {
          window.clearInterval( scrollToTop );
      }
  }, 16); // how fast to scroll (this equals roughly 60 fps)
  }

  public pause(): void {
    if (this.audio) {
      this.audio.pause();
      this.audioStateLabel = 'Paused';
    }
  }

  public get paused(): boolean {
    if (this.audio) {
      return this.audio.paused;
    } else {
      return true;
    }
  }

  public play(): void {
    if (this.audio) {
      this.audio.play();
      // if (this.audio.readyState >= 2) {
      //   this.audio.play();
      //   this.audioStateLabel = 'Playing...'
      // }
    }

  }

  mute() {
    if (this.audio) {
      this.audio.volume = 0;
      this.muted = true;
      this.audio.autoplay = true;
    }
  }

  unmute() {
    if (this.audio) {
      this.audio.volume = this.volume;
      this.muted = false;

    }
  }

  setVolume(value){
    if (this.audio) {
      this.volume = value
      this.audio.volume = value;
      this.muted = false;
    }
  }

  ngOnInit() {}
  
  ngAfterViewInit() {
 
      this.audio = this._audioRef.nativeElement;
      if (this.audio) {
        this.audio.volume = this.volume;
        this.audio.autoplay = this.autoplay;
        this.muted = false;
      }
  }

  getRadio(currentSlug) {
    this.loading = true;
    this.apiService.callGetAPI('v2/radio/'+ currentSlug, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          if(data['response'][0] === undefined || data['response'][0] === null) {
            this.router.navigate(['/video-not-found']);
          } else {
            this.currentData = data['response'][0];
            this.loading = false;
          }
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });

  }

  updateRadioSlider(currentSlug) {
    this.loading = true;
    this.apiService.callGetAPI('v2/radio/'+ currentSlug, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.audio.pause();
          this.audio.volume = this.volume;
          this.muted = false;
          this.liveURL = this.sanitizer.bypassSecurityTrustResourceUrl(data['response'][0].hls_playlist_url);

          var lastUrl = this.liveURL.changingThisBreaksApplicationSecurity.substr(this.liveURL.changingThisBreaksApplicationSecurity.length - 3); // => "mp3"


          if( lastUrl === '3u8') {
            this.currentUrl = 'application/x-mpegURL'
          } else if(lastUrl === 'm3u') {
            this.currentUrl = 'audio/x-mpegURL';
          } else if(lastUrl === 'mp3') {
            this.currentUrl = 'audio/mpeg';
          } else if(lastUrl === 'aac') {
            this.currentUrl = 'audio/aac';
          } else if(lastUrl === 'oga' || lastUrl === 'ogg') {
            this.currentUrl = 'audio/ogg';
          } else {
            this.currentUrl = 'audio/mpeg';
          }
          this.player.src({
            // src: data['response'][0].hls_playlist_url
            src: this.liveURL.changingThisBreaksApplicationSecurity,
            type: this.currentUrl,
          });
          this.player.play();
          this.loading = false;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });
  }


  initVideo(currentSlug) {
    this.apiService.callGetAPI('v2/radio/'+ currentSlug, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          const url = data['response'][0].hls_playlist_url;
          this.liveURL = this.sanitizer.bypassSecurityTrustResourceUrl(data['response'][0].hls_playlist_url);
          this.currentCategoryId = data['response'][0].video_category_name[0].id;
          this.getAllRadioRecord();
          var lastUrl = this.liveURL.changingThisBreaksApplicationSecurity.substr(this.liveURL.changingThisBreaksApplicationSecurity.length - 3); // => "mp3"

           if( lastUrl === '3u8') {
            this.currentUrl = 'application/x-mpegURL'
          } else if(lastUrl === 'm3u') {
            this.currentUrl = 'audio/x-mpegURL';
          } else if(lastUrl === 'mp3') {
            this.currentUrl = 'audio/mpeg';
          } else if(lastUrl === 'aac') {
            this.currentUrl = 'audio/aac';
          } else if(lastUrl === 'oga' || lastUrl === 'ogg') {
            this.currentUrl = 'audio/ogg';
          } else {
            this.currentUrl = 'audio/mpeg';
          }

          const getVideo = document.getElementById('videos');
          this.player = radioplay(getVideo, {
            controls: true,
            autoplay: false,
            preload: 'auto',
            fluid: false,
            bigPlayButton: false,
            // LoadingSpinner:false,
            srcType: 'audio',
            // inactivityTimeout: 50,
            'sources': [{
                'src': this.liveURL.changingThisBreaksApplicationSecurity,
                'type': this.currentUrl,
            }]
          });
        //   this.player.on('error', function (e) {
        //     // no log
        //     console.log(e.type);
        //     alert(e.error);
        // })

        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });
    
  }

  getAllRadioRecord() {
    this.apiService.callGetAPI('v2/getRadioCategory/'+this.currentCategoryId, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.allDatas = data && data['response'].liveRadioChannel;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });
  }

  next(url) {
      this.nextObj();
  }
  nextObj() {
    this.page++;
      this.apiService
        .callGetAPI('v2/getRadioCategory/'+this.currentCategoryId+'?type=radioslider&page=' + this.page)
        .subscribe(
          (data) => {
            if (data['statusCode'] === 200 && data['error'] === false) {
              if (data['response'].total === this.allDatas.data.length) {
                this.newNextPage = false;
              }
              data['response'].liveRadioChannel.data.forEach((value) => {
                this.allDatas.data.push(value);
              });
            }
          },
          (error) => {
            this.apiService.failureResponse(error);
          }
        );
  }

  favourite(video: VideoModel, e) {
    if (sessionStorage.getItem('tostar')) {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': JSON.parse(sessionStorage.getItem('tostar')).tostarTime
      };
    } else {
      this.tostar = {
        'tostarActive': true,
        'tostarTime': 0
      };
    }
    sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
    e.stopPropagation();
    this.loggedIn = localStorage.getItem('currentUser');
    if (this.loggedIn) {
      const favourite = !video.is_favourite;
      if (favourite) {
        this.apiService.callPostAPI('v2/favourite', { video_slug: video.slug }, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success('', 'Radio added to favourite');
                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            });
      } else {
        this.apiService.callDeleteAPI('v2/favourite?video_slug=' + video.slug, environment.userActionApiUrl)
          .subscribe(
            data => {
              if (data['statusCode'] === 200 && data['error'] === false) {
                if (this.tostar.tostarTime === 0 || this.tostar.tostarActive && ((Date.now() - this.tostar.tostarTime) / 1000) >= 5) {
                  this.tostar = {
                    'tostarActive': false,
                    'tostarTime': Date.now()
                  };
                  sessionStorage.setItem('tostar', JSON.stringify(this.tostar));
                  // this.apiService.successResponse(data, '');
                  this.toasterService.success('', 'Radio removed from favourite');

                }
                video.is_favourite = favourite;
                // this.favouriteChange.emit(this.video);
              }
            },
            error => {
              this.apiService.failureResponse(error, false);
              // this.favouriteChange.emit(this.video);
            });
      }

    } else {
      // this.userService.redirectUrl(this.router.url);
      // this.userService.displayPopup('sign-in');
    }
  }

  backTo(): void {
    this.router.navigate(['radio']);
    // this.location.back();
  }

  slidreModel() {
    this.slidreOpen = !this.slidreOpen;
  }

  RadioInfo() {
    this.radioOpen = !this.radioOpen;
  }

  RadioInfoClose() {
    this.radioOpen = false;
  }

  nextbutton() {
    const currentDataId = this.currentData.id;
        this.apiService
        .callGetAPI('v2/allradiodata')
    .subscribe(
      (data) => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          const alldatas= data['response'];
          for (var i=0; i<alldatas.length; i++){
            if(currentDataId === alldatas[i].id) {
              const key = i+1;
              this.preClick = true;
                if ((data && data['response'].length === key+1) || (alldatas[key] === undefined || alldatas[key] === null)) {
                  this.nextClick = false;
                }
                this.router.navigate(['/radio/'+ alldatas[key].slug]);
                this.nextclick(alldatas[key].slug);
            }
          }
        }
      },
      (error) => {
        this.apiService.failureResponse(error);
      }
    );
  }

  prebutton() {
    const currentDataId = this.currentData.id;
    this.apiService
    .callGetAPI('v2/allradiodata')
      .subscribe(
        (data) => {
          if (data['statusCode'] === 200 && data['error'] === false) {
            const alldatas= data['response'];
            for (var i=0; i<alldatas.length; i++){
              if(currentDataId === alldatas[i].id) {
                const key = i-1;
                this.nextClick = true;
                  if (key === 0 || (alldatas[key] === undefined || alldatas[key] === null)) {
                    this.preClick = false;
                  }
                  this.router.navigate(['/radio/'+ alldatas[key].slug]);
                  this.nextclick(alldatas[key].slug);
              }
            }
          }
        },
        (error) => {
          this.apiService.failureResponse(error);
        }
      );
  }

  nextclick(currentSlug) {
    this.apiService.callGetAPI('v2/radio/'+ currentSlug, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
          this.currentData = data['response'][0];
          this.audio.pause();
          this.muted = false;
          this.liveURL = this.sanitizer.bypassSecurityTrustResourceUrl(data['response'][0].hls_playlist_url);
          this.player.src({
            src: this.liveURL.changingThisBreaksApplicationSecurity
          });
          this.player.play();
          this.loading = false;
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });
  }

  getVideos(currentSlug) {
    this.apiService.callGetAPI('v2/watchvideo/'+ currentSlug, environment.apiUrl)
    .subscribe(
      data => {
        if (data['statusCode'] === 200 && data['error'] === false) {
         this.apiService
         .callPostAPI(
           'v2/videoanalytics',
           { video_slug: currentSlug },
           environment.analyticsApiUrl
         )
         .subscribe();
        }
      },
      error => {
        this.apiService.failureResponse(error, true);
      });
  }

  ngOnDestroy(): void {
    if (this.player != null) {
      this.player.dispose();
    }
  }

}