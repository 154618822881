import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChange,
  AfterViewInit,
  ViewChild,
  ElementRef,
  AfterContentInit,
} from "@angular/core";
import { ApiService } from "../../../core/services/api.service";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../core/auth/auth.service";
import { PlayerService } from "../../../core/services/player.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { VideoModel } from "../../../modules/video-detail/video.model";
import { VideoShareService } from "../../../core/services/video-share.service";
import { DomSanitizer } from "@angular/platform-browser";
import radioplay from "../../../../assets/js/liveandeventplay.js";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { from, fromEvent, Observable, Subscription } from "rxjs";
import { Location } from "@angular/common";
import mpegts from "mpegts.js";
@Component({
  selector: "app-liveplayer",
  templateUrl: "./liveplayer.component.html",
  styleUrls: ["./liveplayer.component.scss"],
})
export class LiveplayerComponent implements OnInit, AfterViewInit {
  @Input() public src: string;
  @Input() public autoplay: boolean = true;
  @Input() public showStateLabel: boolean = true;
  public audioStateLabel = "Audio sample";
  @Input() public volume: number = 1.0; /* 1.0 is loudest */
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  @ViewChild("audioElement") public _audioRef: ElementRef;
  private audio: HTMLMediaElement;
  radioUrl: any;
  loading = false;
  radioTitle: any;
  radioDescription: any;
  radioThumbnail: any;
  radioPosterImage: any;
  muted: boolean = false;
  isPlay: boolean = false;
  currentData: any = {};
  currentId: any;
  current = 0;
  allDatas: any = {};
  loggedIn: any = {};
  tostar: any = {};
  page = 1;
  nextpage = 1;
  newNextPage: Boolean = true;
  slidreOpen: boolean = false;
  radioOpen: boolean = false;
  sliderMovedData: any;
  sliderRowItem: any;
  previousPage: Boolean = false;
  nextMove: any;
  liveURL: any;
  // url = localStorage.getItem("radioUrl");
  private _elementRef: ElementRef;
  seekbarTracker: any = {
    duration: 0,
    time: 0,
    seekPercent: 0,
    hasDVR: false,
  };
  nextClick: Boolean = true;
  preClick: Boolean = true;
  currentUrl: any;
  headerVar: any;
  footerVar: any;
  currenTitle: any;
  is_live: any;

  seekTime: number;
  is_mpegts: Boolean = false;
  // // video asset url
  // @Input() url: any;

  // declare player var
  private player: any;
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private playerService: PlayerService,
    private toasterService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private videoShareService: VideoShareService,
    elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private location: Location
  ) {
    this.player = false;
    this.route.params.subscribe((params) => {
      this.initVideo(params.slug);
    });
  }

  ngOnInit() {
    this.headerVar = document.getElementsByTagName("body")[0];
    this.headerVar.classList.add("body-video-page");
    // this.footerVar = document.getElementsByTagName('footer')[0];
    // this.footerVar.classList.add('liveevent-footer');
  }

  ngAfterViewInit() {}

  initVideo(currentSlug) {
    this.apiService
      .callGetAPI("v2/currentVideo/" + currentSlug, environment.apiUrl)
      .subscribe(
        (data) => {
          // console.log(data);
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.currenTitle = data["response"][0].title;
            this.is_live = data["response"][0].is_live;
            const video_url = data["response"][0].hls_playlist_url;
            this.liveURL = this.sanitizer.bypassSecurityTrustResourceUrl(
              data["response"][0].hls_playlist_url
            );

            const getVideo = document.getElementById("videos");
            var filename = video_url.substring(video_url.lastIndexOf("/") + 1);
            var fileExtension = filename
              .split(/[#?]/)[0]
              .split(".")
              .pop()
              .trim();
            if (
              filename.indexOf(".") == -1 ||
              fileExtension == "" ||
              fileExtension == "ts"
            ) {
              this.is_mpegts = true;
              this.player = mpegts.createPlayer(
                {
                  isLive: true,
                  // cors: false,
                  withCredentials: false,
                  type: "mse",
                  url: video_url,
                },
                {
                  enableWorker: true,
                  lazyLoadMaxDuration: 3 * 60,
                  seekType: "range",
                  // liveBufferLatencyChasing: ,
                }
              );
              this.player.attachMediaElement(getVideo);
              this.player.load();
              this.player.play();
              getVideo.setAttribute("controls", "true");
              getVideo.setAttribute("style", "width:100%;height:100%;");
            } else {
              this.is_mpegts = false;
              this.player = radioplay(getVideo, {
                controls: true,
                autoplay: true,
                preload: "auto",
                fluid: false,
                // fullscreen : true,
                srcType: "video",
                inactivityTimeout: 700,
                sources: [
                  {
                    src: video_url,
                    type: "application/x-mpegURL",
                  },
                ],
                playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
                html5: {
                  nativeAudioTracks: false,
                  nativeVideoTracks: false,
                  nativeTextTracks: false,
                  hls: {
                    overrideNative: true,
                  },
                },
                plugins: {
                  hlsQualitySelector: {},
                  seekButtons: {
                    forward: 10,
                    back: 10,
                  },
                },
              });
            }
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  PageBackLive() {
    if (this.is_live === 1) {
      // this.router.navigate(['/live']);
      this.location.back();
    } else {
      this.router.navigate(["/liveevents"]);
    }
  }

  ngOnDestroy(): void {
    if (this.player != null) {
      if (this.is_mpegts == true) {
        this.player.destroy();
      } else {
        this.player.dispose();
      }
    }
    this.headerVar = document.getElementsByTagName("body")[0];
    this.headerVar.classList.remove("body-video-page");
  }
}
