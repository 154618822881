import { Component, OnInit } from "@angular/core";
import { UserService } from "../../core/services/user.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../core/services/api.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-landingpage",
  templateUrl: "./landingpage.component.html",
  styleUrls: ["./landingpage.component.scss"],
})
export class LandingpageComponent implements OnInit {
  error: any = {};
  banners: any;
  geoStatus = false;
  bannerLoaded = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToastrService,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params && params["token"]) {
        this.activateUser(params["token"]);
      }
    });
    this.getBanner();
  }

  fetchCards() {
    if (!this.geoStatus && this.bannerLoaded) {
      this.userService.displayPopup("sign-in");
    }
  }

  ngAfterViewInit(): void {
    document.onclick = (args: any): void => {
      // console.log(args.target.className);
      if (
        args.target.className === "modal-body zoomIn animated wow" ||
        args.target.className === "login-signup-modal modal sign__in" ||
        args.target.className === "header" ||
        args.target.className === "login-modal"
      ) {
        this.userService.displayPopup("close");
      }
    };
  }

  activateUser(key) {
    this.apiService
      .callPostAPI(
        "v2/auth/check_activation_code",
        {
          login_type: "normal",
          acesstype: "web",
          key: key,
        },
        environment.userApiUrl
      )
      .subscribe(
        (data) => {
          if (data["statusCode"] === 200 && data["error"] === false) {
            this.router.navigate(["/"]);
            this.toasterService.success("", data["message"]);
            this.userService.displayPopup("sign-in");
          }
        },
        (error) => {
          if (error.error.statusCode === 422) {
            this.error = error.error.message;
            this.toasterService.error("", this.error);
            this.router.navigate(["/"]);
          } else {
            this.error = {};
            this.toasterService.error(
              "",
              error.error.message ? error.error.message : error.message
            );
            this.router.navigate(["/"]);
          }
        }
      );
  }

  getBanner() {
    this.apiService.callGetAPI("v2/landingpagebanner").subscribe(
      (data) => {
        if (data["statusCode"] === 200 && data["error"] === false) {
          this.banners = data && data["response"].data;
          this.geoStatus = data["is_restricted"];
          if (this.geoStatus) {
            this.userService.displayPopup("close");
          }
        }
        this.bannerLoaded = true;
      },
      (error) => {
        this.bannerLoaded = true;
        this.apiService.failureResponse(error);
      }
    );
  }
}
