import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor() { }
  // Observable User details
  private currentVideo = new BehaviorSubject<any>('');
  currVideo = this.currentVideo.asObservable();
  private videoPaymentDataVar = new BehaviorSubject<any>('');
  videoPaymentInfo = this.videoPaymentDataVar.asObservable();

  // user details
  videoData(data: any) {
    this.currentVideo.next(data);
  }

  videoPaymentData(data: any) {
    this.videoPaymentDataVar.next(data);
  }
}
