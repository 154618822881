import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { NgForm, EmailValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import disableScroll from 'disable-scroll';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  firstName: string;
  lastName: string;
  email: EmailValidator;
  password: string;
  confirmPassword: string;
  phone: string;
  mobile: number;
  date: Date;
  touched = false;
  taken: boolean;
  error: any = {};
  title = 'SIGN UP';
  show: Boolean = false;
  submitted: any = false;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToastrService,
    private translate: TranslateService
  ) {
   }

  ngOnInit() {
    this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
      this.title = res;
    });
  }

  onSignUp(form: NgForm) {
    console.log('onSignUp');
    this.touched = true;
    if (form.valid) {
      this.submitted = true;
      this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
        this.title = res + '...';
      });
      console.log('onSignUp.....');
      this.apiService.callPostAPI(
        'v2/auth/register',
        {
          login_type: 'normal',
          acesstype: 'web',
          email: form.value.email,
          password: form.value.password,
          password_confirmation: form.value.password,
          name: form.value.firstName,
          phone: form.value.phone
        }, environment.userApiUrl)
        .subscribe(
          data => {
            console.log(data);
            this.submitted = false;
            if (data['statusCode'] === 200 && data['error'] === false) {
              this.toasterService.success('', data['message'],{disableTimeOut: true,positionClass:'toast-center-center',closeButton: false});
              this.userService.displayPopup('colse');
              disableScroll.off();
              this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
                this.title = res;
              });
              // next page Observable
              this.userService.nextPage.subscribe(
                nextUrl => {
                  if (!localStorage.getItem('myUrl')) {
                    if (nextUrl.indexOf('video') !== -1) {
                      window.location.reload();
                    } else {
                      this.router.navigate([nextUrl]);
                    }
                  } else if (localStorage.getItem('myUrl')) {
                    if (data['response'].is_subscribed === 0) {
                      localStorage.setItem('subscribeUrl', window.location.pathname);
                      this.router.navigate(['subscription']);
                      localStorage.removeItem('myUrl');
                    } else if (data['response'].is_subscribed === 1) {
                      this.router.navigate([window.location.pathname.replace('video/', 'watch/')]);
                      localStorage.removeItem('myUrl');
                    }
                  }
                });
            }
          },
          error => {
            console.log(error);
            this.submitted = false;
            this.translate.get('SIGN_UP.CREATE_ACCOUNT').subscribe(res => {
              this.title = res;
            });
            if (error.error.statusCode === 422) {
              if (typeof error.error.message === 'string' || error.error.message instanceof String) {
                this.toasterService.error('', error.error.message);
              } else {
                this.error = error.error.message;
              }
            } else {
              this.error = {};
              this.toasterService.error('', (error.error.message) ? error.error.message : error.message);
            }
          });
    }
  }

  onClose() {
    disableScroll.off();
    if (this.route.snapshot['_routerState'].url === '/sign-up') {
      this.router.navigate(['home']);
    }
    this.userService.displayPopup('colse');
  }

  onClick(value: string) {
    if (value === 'sign-in') {
      this.userService.displayPopup('sign-in');
    }
  }
  onChange(e) {
    this.error = {};
  }
  toggleShowPassword() {
    this.show = !this.show;
  }
}
